import { useEffect, useContext, useLayoutEffect, useState } from "react";

// ======================= STATE =======================
import {
    DataPrepContext,
    ExplorerContext,
} from "../../../state/explorer/explorerProvider";

// ======================= DZYNE COMPONENTS =======================
import SelectionTable from "../../dzyne_components/ui/tables/SelectionTable/SelectionTable";
import NavArrowGroup from "../../dzyne_components/ui/buttons/NavArrowGroup/NavArrowGroup";
// ======================= HELPERS =======================
import { usePage } from "../../../js/hooks";
import SelectedTaskDisplay from "./SelectedTaskDisplay";

const DetectionSelection = ({ className }) => {
    // STATE
    const page = usePage();
    const { state, setters, dataPrepAPI } = useContext(DataPrepContext);
    const [navArrows, setNavArrows] = useState(["Jobs"]);
    const [detectionResults, setDetectionResults] = useState(null);
    const [detectorType, setDetectorType] = useState(null);
    const [loadingJob, setLoadingJob] = useState(false);

    // EVENTS
    const handleNavClick = (label) => {
        switch (label) {
            case "Jobs":
                setters.setSelectedTask(null);
                setNavArrows(["Jobs"]);
                setDetectionResults(null);
                break;
            case "Tasks":
                setNavArrows(["Jobs", "Tasks"]);
                setDetectionResults(null);
                break;
        }
    };

    const handleTaskClick = async (data, e) => {
        e.stopPropagation();
        setLoadingJob(true);
        const taskID = data.id;
        await dataPrepAPI.getTaskByID(taskID);
        setDetectorType(data?.details?.detector);
        setLoadingJob(false);
        setNavArrows(["Jobs", "Tasks"]);
    };

    // EFFECTS
    useEffect(() => {
        if (!!state.selectedSession) {
            const sessionID = state.selectedSession.id;
            dataPrepAPI.getTasksBySessionID(sessionID);
        }
    }, [state.selectedSession]);

    useEffect(() => {
        !!detectionResults && setNavArrows(["Jobs", "Tasks", "Results"]);
    }, [detectionResults]);

    return (
        <div className={`ribbon-subsection ${className} flex flex-col`}>
            {!state.sessionTasks?.length
                ? "No Jobs Found"
                : !state.selectedTask
                ? "Session Jobs"
                : state.selectedTask.name}
            <NavArrowGroup labels={navArrows} onClick={handleNavClick} activeArrowStyle={{fontWeight: "bold", textDecoration: "underline"}} />
            <div className="flex-1">
                {!!state.sessionTasks?.length &&
                    !state.selectedTask &&
                    !loadingJob && (
                        <SelectionTable
                            data={state.sessionTasks}
                            columns={[
                                { key: "name", name: "Name" },
                                { key: "details,detector", name: "Detector" },
                            ]}
                            id="detection-selection-table"
                            containerClassName="h-3/4"
                            containerStyle={{ maxHeight: "35vh" }}
                            tdStyle={{ color: "var(--neutral0)" }}
                            onRowClick={handleTaskClick}
                        />
                    )}
                {
                    loadingJob && <div className="font-bold">LOADING...</div>
                }
                {!!state.selectedTask && (
                    <SelectedTaskDisplay
                        task={state.selectedTask}
                        detectionResults={detectionResults}
                        setDetectionResults={setDetectionResults}
                        detectorType={detectorType}
                    />
                )}
            </div>
        </div>
    );
};

export default DetectionSelection;
