import { v4 as uuidv4 } from 'uuid';
import { normalizeColor } from './helpers';
const Cesium = require("cesium");

// HELPER UTILITIES

const GEOJSON_FORMATS = {
    point: "Point",
    multipoint: "MultiPoint",
    polyline: "LineString",
    polygon: "Polygon",
    rectangle: "Polygon",
    circle: "Circle"
}

export const rectToPolygon = (rectPoints, format = "lnglat") => {
    let lat1, lat2, lng1, lng2;

    [lng1, lat1, lng2, lat2] = [...rectPoints[0], ...rectPoints[1]]
    if (format === "latlng") {
        [lng1, lat1] = [lat1, lng1]
        [lng2, lat2] = [lat2, lng2]
    }

    let polygonPoints;
    if (lat1 > lat2 && lng1 < lng2) {// top left => bottom right
        polygonPoints = [
            [lng1, lat1],
            [lng2, lat1],
            [lng2, lat2],
            [lng1, lat2]
        ]
    }
    if (lat1 > lat2 && lng1 > lng2) { // top right => bottom left
        polygonPoints = [
            [lng1, lat1],
            [lng1, lat2],
            [lng2, lat2],
            [lng2, lat1]
        ]
    }
    if (lat1 < lat2 && lng1 > lng2) { // bottom right => top left
        polygonPoints = [
            [lng1, lat1],
            [lng2, lat1],
            [lng2, lat2],
            [lng1, lat2]
        ]
    }
    if (lat1 < lat2 && lng1 < lng2) { // bottom left => top right
        polygonPoints = [
            [lng1, lat1],
            [lng1, lat2],
            [lng2, lat2],
            [lng2, lat1]
        ]
    }

    if (format === "latlng") polygonPoints.forEach(point => point.reverse())

    return polygonPoints;

}

export const geojsonFromPoints = (geometryType, points, properties = {}, format = "lnglat") => {

    const geojsonBody = {
        type: "FeatureCollection",
        features: [
            {
                type: "Feature",
                properties: properties ?? {},
                geometry: {}
            }
        ]
    }
    const geometry = geojsonBody.features[0].geometry


    switch (geometryType) {
        case "point":
            geometry.coordinates = points
            geometry.type = "Point"
            break;
        case "multipoint":
            geometry.type = "MultiPoint";
            geometry.coordinates = [points]
            break;
        case "polyline":
            geometry.type = "LineString"
            geometry.coordinates = [points]
            break;
        case "polygon":
            points.push(points[0]);
            geometry.type = "Polygon"
            geometry.coordinates = [points];
            break;
        case "circle":
            /* 
            since geojson doesn't handle circles, we only need the center point. 
            The radius of the circle from that center point is stored on the annotation metadata (converted to geojson properties)
            */
            geometry.type = "Point";
            geometry.coordinates = points[0]
            break;
        case "rectangle":
            const adjustedPoints = rectToPolygon(points, format)
            geometry.type = "Polygon";
            adjustedPoints.push(adjustedPoints[0])
            geometry.coordinates = [adjustedPoints]
    }

    return geojsonBody;
}

const WKT_FORMATS = {
    point: "POINT",
    multipoint: "MULTIPOINT",
    polyline: "LINESTRING",
    polygon: "POLYGON",
    rectangle: "POLYGON",
    circle: "POLYGON"
}

export const wktFromPoints = (geometryType, points, properties, format) => {

    let geometry = {properties}
    switch (geometryType) {
        case "point":
            geometry.geometry = `POINT(${points.map(point => point.join(" ")).join(", ")})`
            break;
        case "multipoint":
            geometry.geometry = `MULTIPOINT(${points.map(point => `(${point.join(" ")})`).join(", ")})`
            break;
        case "polyline":
            geometry.geometry = `LINESTRING(${points.map(point => point.join(" ")).join(", ")})`
            break;
        case "polygon":
            points.push(points[0])
            geometry.geometry = `POLYGON((${points.map(point => point.join(" ")).join(", ")}))`
            break;
        case "rectangle":
            const adjustedPoints = rectToPolygon(points, format)
            adjustedPoints.push(adjustedPoints[0])
            geometry.geometry = `POLYGON((${adjustedPoints.map(point => point.join(" ")).join(", ")}))`
            break;
        case "circle":
            geometry.geometry = `POINT(${points[0].join(" ")})`
            break;            
        }
    
        return geometry
}


// RENDERERS
const createPoint = (annotation, points) => {
    return annotation.viewer.entities.add({
        id: annotation._id,
        position: Cesium.Cartesian3.fromDegrees(...points[0][0]),
        point: {
            pixelSize: 16,
            color: Cesium.Color.RED,
            outlineColor: Cesium.Color.WHITE,
            outlineWidth: 2
        }
    })
}

const createPolyline = (annotation, points) => {
    return annotation.viewer.entities.add({
        id: annotation._id,
        polyline: {
            positions: Cesium.Cartesian3.fromDegreesArray(points[0].flat()),
            width: 4,
            material: Cesium.Color.RED,
            clampToGround: true,
            ...annotation.metadata
        }
    })
}

const createPolygon = (annotation, points) => {
    // const color = normalizeColor(0, 255, 0)
    return annotation.viewer.entities.add({
        id: annotation._id,
        polygon: {
            hierarchy: Cesium.Cartesian3.fromDegreesArray(points[0].flat()),
            fill: true,
            outline: true,
            material: new Cesium.Color(...normalizeColor(0, 0, 0, 0.3)),
            outlineWidth: 4,
            outlineColor: Cesium.Color.RED,
            ...annotation.metadata
        }
    })
}

const createCircle = (annotation, points) => {
    // calculate radius distance    
    const startPoint = Cesium.Cartographic.fromCartesian(Cesium.Cartesian3.fromDegrees(...points[0][0]))
    const endPoint = Cesium.Cartographic.fromCartesian(Cesium.Cartesian3.fromDegrees(...points[0][1]))
    const ellipsoidGeodesic = new Cesium.EllipsoidGeodesic(startPoint, endPoint)
    const radius = ellipsoidGeodesic.surfaceDistance;

    // record the radius in the metadata
    annotation.metadata.radius = radius

    return annotation.viewer.entities.add({
        position: Cesium.Cartesian3.fromDegrees(...points[0][0]),
        id: annotation._id,
        ellipse: {
            semiMinorAxis: radius,
            semiMajorAxis: radius,
            fill: true,
            outline: true,
            material: new Cesium.Color(0, 1., 0).withAlpha(0.3),
            outlineWidth: 4,
            outlineColor: Cesium.Color.RED,
            ...annotation.metadata
        }
    })
}

const createRectangle = (annotation, points) => {
    // determine rectangle outline
    let west, south, east, north;
    const [lng1, lat1] = [points[0][0][0], points[0][0][1]]
    const [lng2, lat2] = [points[0][1][0], points[0][1][1]]

    if (lat1 > lat2 && lng1 < lng2) {// top left => bottom right
        [west, south, east, north] = [lng1, lat2, lng2, lat1]
    }
    if (lat1 > lat2 && lng1 > lng2) { // top right => bottom left
        [west, south, east, north] = [lng2, lat2, lng1, lat1]
    }
    if (lat1 < lat2 && lng1 > lng2) { // bottom right => top left
        [west, south, east, north] = [lng2, lat1, lng1, lat2]
    }
    if (lat1 < lat2 && lng1 < lng2) { // bottom left => top right
        [west, south, east, north] = [lng1, lat1, lng2, lat2]
    }
    
    return points[0].length === 2
        ? annotation.viewer.entities.add({
            id: annotation._id,
            rectangle: {
                coordinates: Cesium.Rectangle.fromDegrees(west, south, east, north),
                fill: true,
                outline: true,
                material: Cesium.Color.RED.withAlpha(0.3),
                outlineWidth: 4,
                outlineColor: Cesium.Color.RED,
                ...annotation.metadata
            }
        })
        : null
}

// DEFAULT OPTIONS
const DEFAULT_OPTIONS = {
    handles: true
}

export default function createAnnotationRegistry(id){

    // shortcut here to return a predefined annotation registry 
    if(!!id && !!window._annotationRegistries?.[id]) return window._annotationRegistries[id];

    class Annotation3D {
    
        constructor({
            viewer, // required
            type, // required
            metadata = {},
            initializationPoints = null,
            options = {},
            id = null,
            createCallback = (payload) => {},
        }) {
            this.viewer = viewer;
            this.type = type;
            this.metadata = metadata;
            this.options = { ...DEFAULT_OPTIONS, ...options }
            
            this._id = id ?? uuidv4()
            this._entity = null;
            this._history = initializationPoints ? [initializationPoints] : [];
            this._redoHistory = [];
            this._events = {}
            this.current = [];

            this._pointerDownStart = {x: 0, y: 0};
            this._pointerLastKnownPosition = {x: 0, y: 0};
            
            // BINDINGS
            this._emitEvent = this._emitEvent.bind(this)
            this._pointerDown = this._pointerDown.bind(this)
            this._pointerMove = this._pointerMove.bind(this)
            this._pointerUp = this._pointerUp.bind(this)
            
            // CLASS REGISTRY
            
            this.constructor._annotations = this.constructor._annotations ?? []
            this.constructor._annotations.push(this)
    
            this.constructor.selectByID(this._id)

            // execute the creation callback here because events are only appended to the callback after the constructor has completed
            createCallback({annotation: this})

        }
    
        /* 
        :::::::::::::::::::::::::::::::::::::::::::
        :::::::::::::: CLASS METHODS ::::::::::::::
        :::::::::::::::::::::::::::::::::::::::::::
        */
        static getAnnotations() {
            return this._annotations
        }
    
        static getAnnotation(id) {
            return this._annotations?.find(a => a._id === id);
        }
        
        static getSelectedAnnotation() {
            return this._annotations?.find(a => !!a._selected)
        }
        
        static selectByID(id) {
            let annotation = null;
            this._annotations?.forEach(a => {
                if (a._id === id) {
                    a._selectInstance()
                    annotation = a
                } else {
                    a._deselectInstance()
                }
            })
    
            return annotation
        }
    
        static deselect() {
            this._annotations?.find(annotation => annotation._selected)?._deselectInstance()
        }
    
        static delete(id) {
            this._annotations = this._annotations.filter(a => {
                if (a._id === id) {
                    a._deleteInstance()
                    return false
                }
                return true;
            })
        }

        static deleteAll(){
            this._annotations?.forEach(a => {
                a._deleteInstance()
            })
        }

        static restore(){
            this._annotations?.forEach(annotation => {
                annotation._update()
            })
        }
    
        /* 
        ::::::::::::::::::::::::::::::::::::::::::::::
        :::::::::::::: INSTANCE METHODS ::::::::::::::
        ::::::::::::::::::::::::::::::::::::::::::::::
        */
    
        // Events
        on(eventName, callback) {
            this._events[eventName] = callback;
        }
    
        _emitEvent(eventName, payload) {
            this._events[eventName]?.(payload)
        }
    
        _update() {
            // remove any previously rendered entity or handle
            this._entity && this.viewer.entities.remove(this._entity)
            if (!!this._handles) {
                for (let handle of this._handles) {
                    this.viewer.entities.remove(handle)
                }
            }
    
            const currentPoints = this._history.slice(-1)
            this.current = currentPoints[0]
            this._renderEntity(currentPoints)
            this._emitEvent("update", {annotation: this, registry: this.constructor._annotations})
    
        }
    
        _renderEntity(points) {
            let entity;
            try {
                switch (this.type) {
                    case "point":
                        entity = null; // handle will account for the pointer entity
                        break;
                    case "multipoint":
                        entity = null; // handles will account for the pointer entities
                        break;
                    case "polyline":
                        entity = createPolyline(this, points);
                        break;
                    case "polygon":
                        entity = createPolygon(this, points);
                        break;
                    case "circle":
                        entity = createCircle(this, points);
                        break;
                    case "rectangle":
                        entity = createRectangle(this, points)
                        break;
                }
    
            } catch (err) {
                // console.log(err)
            }
            this._entity = entity;
    
            if (this.options.handles && this._selected) {
                this._renderHandles(points)
            }
        }
    
        _renderHandles(points) {
            if (!!points[0]) {
                this._handles = [];
                let currentEntity;
                for (let [index, point] of Object.entries(points[0])) {
                    this._handles.push(
                        currentEntity = this.viewer.entities.add({
                            id: `handle-${index}-${this._id}`,
                            position: Cesium.Cartesian3.fromDegrees(...point),
                            point: {
                                pixelSize: 16,
                                color: Cesium.Color.RED,
                                outlineColor: Cesium.Color.WHITE,
                                outlineWidth: 2
                            },
                            properties: { index, role: "handle" }
                        })
                    )
                    
                }
            }
        }
    
        _deletePoint(index) {
            let currentPoints = this._history.slice(-1)[0]
            currentPoints = JSON.parse(JSON.stringify(currentPoints))
    
            currentPoints.splice(index, 1)
            this._history.push(currentPoints)
    
        }
    
        // POINTER EVENTS
        _pointerUp(event) {
            this._pointerIsDown = false
            const position = { x: event.offsetX, y: event.offsetY }
    
            // 1. Get the most recent points array from history so it can be updated
            let currentPoints = !!this._history.length ? this._history.slice(-1)[0] : [];
            currentPoints = JSON.parse(JSON.stringify(currentPoints)) // make a deep copy of the points so nothing is mutated
    
            // 2. Determine latlng location of mouse and store in `point` variable
            let point;
            const ellipsoid = this.viewer.scene.globe.ellipsoid;
    
            const cartesian = this.viewer.camera.pickEllipsoid(new Cesium.Cartesian3(position.x, position.y), ellipsoid)
            if (cartesian) {
                const cartographic = ellipsoid.cartesianToCartographic(cartesian)
                const lng = Cesium.Math.toDegrees(cartographic.longitude)
                const lat = Cesium.Math.toDegrees(cartographic.latitude)
                
                point = [lng, lat]
            }

            // 3. Determine if the update is the change of a previous point, or a new point
            // if the user was dragging an existing point
            if (this._draggingHandle) {
                // if the pointer moved, then we are assuming the user wanted to change the location of the point
                if (this._pointerDidMove()) {
                    const index = this._draggingHandle?.properties?.index?._value
    
                    currentPoints[index] = point
                    this._draggingHandle = null;
                    // re-enable rotation in case the pointer down event disabled it
                    this.viewer.scene.screenSpaceCameraController.enableRotate = true;
                    this.viewer.scene.screenSpaceCameraController.enableTranslate = true;
    
                    // reset redo history
                    this._redoHistory = [];
                } else {
                    this._draggingHandle = null;
                }
    
            } else { // if the user was creating a new point
                // if pointer moved (dragging on map) then don't register as a user press
                if (this._pointerDidMove()) return
    
                if (this.type === "point") {
                    currentPoints = [point]
                }
    
                if (["multipoint", "polyline", "polygon"].includes(this.type)) {
                    currentPoints.push(point)
                }
    
                if (["circle", "rectangle"].includes(this.type)) {
                    if (currentPoints.length === 2) {
                        currentPoints[1] = point
                    }
                    if (currentPoints.length <= 1) {
                        currentPoints.push(point)
                    }
                }
                // reset redo history
                this._redoHistory = [];
    
            }
            this._history.push(currentPoints.filter(p => p))
            this._update()
    
        }
    
        _pointerDown(event) {
            this._pointerIsDown = true
            const position = { x: event.offsetX, y: event.offsetY }
            // check to see if there is an entity where the user clicked
            const entity = this.viewer.scene.pick(position)?.id
            
            // if the entity is a handle...
            if (entity?.properties?.role?._value === "handle") {
    
                // ALT KEY = DELETE ASSOCIATED POINT
                if (event.altKey) {
                    this._deletePoint(entity.properties.index._value)
                    this._update()
                    return // bail out here
                }
    
                // press and hold will also delete a point. Useful for mobile devices without keyboards
                setTimeout(() => {
                    if (this._pointerHeldDown() && !this._pointerDidMove()) {
                        this._deletePoint(entity.properties.index._value)
                        this._update()
                        // this._draggingHandle = null
                        this.viewer.scene.screenSpaceCameraController.enableRotate = true;
                        this.viewer.scene.screenSpaceCameraController.enableTranslate = true;
                    }
                }, 500)
    
                this._draggingHandle = entity;
                this.viewer.scene.screenSpaceCameraController.enableRotate = false;
                this.viewer.scene.screenSpaceCameraController.enableTranslate = false;
    
            }
    
            this._pointerDownStart = position;
            this._pointerLastKnownPosition = position;
        }
    
        _pointerMove(event) {
            this._pointerLastKnownPosition = { x: event.offsetX, y: event.offsetY }
        }
    
        _pointerDidMove() {
            if(!this._pointerDownStart || !this._pointerLastKnownPosition || !this._pointerLastKnownPosition || !this._pointerDownStart) return true
            
            return (
                this._pointerDownStart.x !== this._pointerLastKnownPosition.x
                && this._pointerDownStart.y !== this._pointerLastKnownPosition.y
            )
        }
    
        _pointerHeldDown() {
            return !!this._pointerIsDown
        }
    
        // SELECTION
        _selectInstance() {
            this._selected = true
    
            // add event listeners to canvas and window as necessary
            this.viewer.canvas.addEventListener("pointerdown", this._pointerDown);
            this.viewer.canvas.addEventListener("pointermove", this._pointerMove);
            this.viewer.canvas.addEventListener("pointerup", this._pointerUp);
    
            // redraw everything when selected
            this._emitEvent("select", {annotation: this})
            this._update()
        }
    
        _deselectInstance() {
            this._selected = false
    
            // hide handles when not selected
            if (!!this._handles) {
                for (let handle of this._handles) {
                    this.viewer.entities.remove(handle)
                }
            }
    
            // remove event listeners
            this.viewer.canvas.removeEventListener("pointerdown", this._pointerDown);
            this.viewer.canvas.removeEventListener("pointermove", this._pointerMove);
            this.viewer.canvas.removeEventListener("pointerup", this._pointerUp);

            this._emitEvent("deselect", {annotation: this})
        }
    
        _deleteInstance() {
            // remove the entity and handles from the map
            this._entity && this.viewer.entities.remove(this._entity)
            if (!!this._handles) {
                for (let handle of this._handles) {
                    this.viewer.entities.remove(handle)
                }
            }
    
            // remove any lingering event listeners
            this.viewer.canvas.removeEventListener("pointerdown", this._pointerDown);
            this.viewer.canvas.removeEventListener("pointermove", this._pointerMove);
            this.viewer.canvas.removeEventListener("pointerup", this._pointerUp);
    
            // emit the delete event
            this._emitEvent("delete", {annotation: this})
    
        }

        _saveInstance() {
            this._emitEvent("save", {annotation: this})
        }
    
        revert(historyIndex) {
            this._history = this._history.slice(0, historyIndex + 1)
            this._update()
        }
    
        // HISTORY MANIPULATION
        undo() {
            const prev = this._history.pop()
            prev && this._redoHistory.push(prev)
            this._update()
        }
    
        redo() {
            const prevUndone = this._redoHistory.pop()
            prevUndone && this._history.push(prevUndone)
            this._update()
        }
    
        exportGeometry({ output = "geojson", format = "lnglat" }) {
            let currentPoints = JSON.parse(JSON.stringify(this.current))
    
            //flip coordinates if latlng is requested
            if (format === "latlng") currentPoints.forEach(point => point.reverse())
    
            let geometry;
            switch (output) {
                case "geojson":
                    geometry = geojsonFromPoints(this.type, currentPoints, this.metadata, format);
                    break;
                case 'wkt':
                    geometry = wktFromPoints(this.type, currentPoints, this.metadata, format);
                    break;
                case "raw":
                    geometry = currentPoints
                    break;
            }
    
            this._emitEvent("export", { annotation: this, geometry })
            return geometry;
        }
    }

    if(id){
        // If an id is specified, we track an annotation registry across component mounts/unmounts
        window._annotationRegistries = window._annotationRegistries ?? {}
        if(!window._annotationRegistries[id]){
            window._annotationRegistries[id] = Annotation3D;
        } 
        return window._annotationRegistries[id]
    }

    // if no id is specified, we won't track this particular registry in the window object
    return Annotation3D
}