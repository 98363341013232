import React, { useContext, useState } from 'react'

// ============================= STATE =============================
import { ChangeDetectionContext } from '../../state/changeDetection/changeDetectionProvider'

// ============================= DZYNE COMPONENTS =============================
import Grid from '../dzyne_components/layout/Grid/Grid';
import InlineButton from '../dzyne_components/ui/buttons/InlineButton/InlineButton';
import TabGroup from '../dzyne_components/ui/buttons/TabGroup/TabGroup';

// ============================= CHILDREN =============================
import GlobalChart from './GlobalChart'
import SelectedSceneChart from './SelectedSceneChart'

// ============================= MEDIA =============================
import ExportIcon from '../../assets/icons/export.svg'
import AreaMap from './AreaMap';

export default function ChartContainer() {

    // STATE
    const { state, methods, setters } = useContext(ChangeDetectionContext)
    const [chartType, setChartType] = useState("Stability Validation")
    const [globalView, setGlobalView] = useState("graph")

    // EVENTS
    const handleExportClick = () => {
        // This code will change when updated API is available
        methods.generateExplorerLink()

    }

    return (
        <Grid className="dashboard-panel" cols={1} rows={2} rowSpacing={"1fr 11fr"} style={{ gridArea: "chart", height: "100%" }}>
            <div>
                {
                    !!state.selectedScene
                    &&
                    <>
                        <InlineButton onClick={() => setters.setSelectedScene(null)}>{"< Back"}</InlineButton>
                        <InlineButton onClick={handleExportClick}>VIEW IN EXPLORER</InlineButton>
                        <h3 className='inline-block ml-4 text-2xl font-bold'>{state.selectedScene.name}</h3>
                    </>
                }
                {
                    !state.selectedScene
                    &&
                    <>
                        <div className='absolute'>
                            <InlineButton
                                style={globalView === "graph" ? { color: "var(--primaryAccent2)", textShadow: "1px 1px 4px var(--primaryAccent4)", boxShadow: "2px 2px 5px black" } : {}}
                                onClick={() => setGlobalView("graph")}
                            >
                                Histogram
                            </InlineButton>
                            <InlineButton
                                style={globalView === "map" ? { color: "var(--primaryAccent2)", textShadow: "1px 1px 4px var(--primaryAccent4)", boxShadow: "2px 2px 5px black" } : {}}
                                onClick={() => setGlobalView("map")}
                            >
                                Geospatial Holding
                            </InlineButton>
                        </div>
                        {
                            globalView === "graph"
                                ? <h3 className='text-3xl text-center font-bold text-white' style={{ textShadow: "3px 3px 5px black" }}>Scenes by Stability</h3>
                                : <h3 className='text-3xl text-center font-bold text-white' style={{ textShadow: "3px 3px 5px black" }}>Scene Map</h3>

                        }

                    </>
                }
            </div>
            <div>
                {
                    !!state.selectedScene
                        ? <SelectedSceneChart scene={state.selectedScene} chartType={chartType} />
                        : globalView === "graph" ? <GlobalChart /> : <AreaMap />
                }
            </div>
        </Grid>
    )
}
