import React, { useState } from 'react'

// CHILDREN
import IconButton from '../../ui/buttons/Iconbutton/IconButton'

// ASSETS
import DropDown from '../../assets/media/keyboard_arrow_down.svg'
import FoldUp from '../../assets/media/keyboard_arrow_up.svg'

// STYLES
import './Drawer.css';

export default function Drawer({
    children,
    title="",
    defaultOpen = true,
    disabled=false,

    id = "",
    className = "",

    containerStyle = {},
    style = {}
}) {

    const [open, setOpen] = useState(defaultOpen)

    // EVENTS
    const handleClick = e => {
        !disabled && setOpen(!open)
    }
    
    return (
        <div id={id} className={`drawer-container ${className}`} style={containerStyle}>
            <div className="drawer-banner" onClick={handleClick}>
                {
                    open
                        ? <IconButton icon={FoldUp} alt="close drawer" />
                        : <IconButton icon={DropDown} alt="open drawer" />
                }
                {title}
            </div>
            <div style={{
                display: open ? "block" : "none", 
                ...style
            }}>
                {children}
            </div>

        </div>
    )
}
