export default function copyToClipboard(data){
    const copyElement = document.createElement("textarea");
    
    document.body.appendChild(copyElement);
    
    copyElement.value = "" + data;
    
    copyElement.select();
    document.execCommand("copy");
    document.body.removeChild(copyElement);
}