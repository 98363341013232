import React, { useContext, useEffect, useState } from 'react'
import { DateTime } from 'luxon'

// ================================== STATE==================================
import { ChangeDetectionContext } from '../../state/changeDetection/changeDetectionProvider';

// ================================== DZYNE COMPONENTS ==================================
import Grid from '../dzyne_components/layout/Grid/Grid'
import Center from '../dzyne_components/layout/Center/Center'

// ================================== HELPERS ==================================
import { percent2Color } from './helpers/dashboardHelpers';


export default function SceneListItem({ scene }) {

    // STATE
    const { state, setters } = useContext(ChangeDetectionContext)
    const [dateRange, setDateRange] = useState([])

    // EVENTS
    const handleClick = () => {
        setters.setSelectedScene(state.selectedScene === scene ? null : scene)
    }
    
    // EFFECTS
    useEffect(() => {
        const dates = scene.images
            .map(scene => new Date(scene.timestamp).getTime())
            .sort()
            .map(time => DateTime.fromMillis(time).toFormat("yyyy/MM/dd"))
        setDateRange({start: dates[0], end: dates.at(-1)})
    }, [])

    return (

        <div onClick={handleClick}>

            <Grid
                className={`scene-list-item font-bold ${state.selectedScene?.nobID === scene.nobID && "selected"}`}
                cols={2}
                rows={2}
                colSpacing={"9fr 3fr"}
                rowSpacing={"auto auto"}
                areas={[["name", "name"], ["stats", "score"]]}

            >
                <div className="scene-list-item-name mb-1 text-xl" style={{gridArea: "name"}}>
                    {scene.name}    
                    <hr/>
                </div>

                <div className="scene-stability-swatch" style={{ backgroundColor: percent2Color(scene.score), gridArea: "score", height: "100%" }}>
                    <Center>{(scene.score * 100).toFixed(2)}%</Center>
                </div>

                <Grid
                    cols={2}
                    rows={3}
                    colSpacing={"auto auto"}
                    style={{gridArea: "stats"}}
                >
                    <h3 className='font-bold'>IMAGES:</h3> <p>{scene.images.length}</p>
                    <h3 className='font-bold'>START:</h3> <p>{dateRange.start}</p>
                    <h3 className='font-bold'>END:</h3> <p>{dateRange.end}</p>
                </Grid>
                

            </Grid>
        </div>

    )
}
