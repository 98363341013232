import React from 'react'

const HORIZONTAL = {
    display: "flex",
    justifyContent: "center",
    
}
const VERTICAL = {
    display: "flex",
    alignItems: "center",
    height: "100%"
}

export default function Center({
    children,
    center = "both",
    height = "100%",
    width = "100%",

    className = "",
    id="",

    style={}
}) {

    switch (center) {
        case "vertical":
            style = {...VERTICAL, width, height, ...style}
            break;
        case "horizontal":
            style = {...HORIZONTAL, width, height, ...style}
            break;
        default:
            style = {...VERTICAL, ...HORIZONTAL, justifyContent: "center", width, height, ...style}

    }

    return (

        <div id={id || null} className={`center-container ${className}`} style={style}>
            {children}
        </div>

    )
}
