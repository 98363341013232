import React, { useState } from 'react'

// ============================= CHILDREN =============================
import TabGroup from '../dzyne_components/ui/buttons/TabGroup/TabGroup'
import Modal from '../dzyne_components/ui/modals/Modal/Modal'
import ActionButton from '../dzyne_components/ui/buttons/ActionButton/ActionButton';
import ShareLayersForm from './ShareLayersForm'
import ShareSessionForm from './ShareSessionForm'


export default function ShareModal({ viewer, setShowShareModal }) {

    // STATE
    const [activeTab, setActiveTab] = useState("share session")
    const [link, setLink] = useState("");
    const [currentView, setCurrentView] = useState(viewer.camera.position.clone())
    
    // EVENTS
    const handleTabChange = tab => {
        setActiveTab(tab)
    }

    const handleCopyClick = () => {

        const textElement = document.querySelector(".sharing-link-textarea")
        const text = textElement.value;

        textElement.select();
        document.execCommand("copy");

    }

    return (
        <Modal closeButton onClose={() => setShowShareModal(false)}>
            <TabGroup 
                tabs={["share session", "share selected layers"]}
                defaultTab={activeTab}
                onClick={handleTabChange}
            />

            {
                activeTab === "share session"
                &&
                <ShareSessionForm setShowShareModal={setShowShareModal} setLink={setLink} />
            }

            {
                activeTab === "share selected layers"
                &&
                <ShareLayersForm setShowShareModal={setShowShareModal} setLink={setLink} />
            }

            {
                link
                &&
                <>
                    <textarea className="sharing-link-textarea" value={link}></textarea>
                    <ActionButton 
                        primaryColor={"var(--neutral2)"}
                        backgroundColor={"var(--primaryAccent5)"}
                        
                        style={{fontSize: "1.1rem"}}

                        onClick={handleCopyClick}
                    >
                        Copy
                    </ActionButton>
                </>
            }
        </Modal>
    )
}
