
import config from '../../config.json'

const processConfig = config => {

    const hosts = ["mapTileHost", "controllerHost", "geoserverHost"]
    window._env_ = window._env_ || {}

    for (let host of hosts){
        if(!config[host]){
            config[host] = config.baseHost || window._env_[host] || window.location.hostname
        }
    }

    config.mapTileBase = `${config.mapTileProtocol || config.defaultProtocol}://${config.mapTileHost}:${config.mapTilePort}`
    // EXAMPLE FULL URL http://192.168.35.168:4014/styles/osm-bright/{z}/{x}/{y}.png

    return config;
}

const processedConfig = processConfig(config);

const state = {

    ...processedConfig

}

export default state;
