
import CantusFirmus from 'cantus-firmus';

import state from './state'
import setters from './setters'
import methods from './methods'


const config = new CantusFirmus(state)

config.addCustomSetters(setters)
config.addMethods(methods)



export const ConfigContext = config.context;
export const ConfigProvider = config.createProvider();

