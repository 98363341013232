import React, { useContext, useEffect, useState } from 'react'
import L from 'leaflet'

// ============================== STATE ==============================
import { ChangeDetectionContext } from '../../state/changeDetection/changeDetectionProvider'

// ============================== DZYNE COMPONENTS ==============================
import useMap from '../dzyne_components/hooks/useMap'

// ============================== HELPERS ==============================
import { percent2Color } from './helpers/dashboardHelpers'
import { generateScenePopup } from '../../js/mapHelpers'

// ============================== CONFIG ==============================
import config from '../../config.json'
const OFFLINE_MAP_HOST = process.env.REACT_APP_OFFLINE_MAP_HOST || window._env_?.offlineMapHost || config.offlineMapHost || window.location.hostname
const OFFLINE_MAP_PORT = process.env.REACT_APP_OFFLINE_MAP_PORT || window._env_?.offlineMapPort || config.offlineMapPort || 4014

const offlineMapUrl = `http://${OFFLINE_MAP_HOST}:${OFFLINE_MAP_PORT}/styles/osm-bright/{z}/{x}/{y}.png`


export default function AreaMap() {

    // STATE
    const { state, setters } = useContext(ChangeDetectionContext)
    const map = useMap("area-map", {}, {
        tileLayer: offlineMapUrl,
        attribution: " ",
    }, map => {
        map.on("zoomend", function(e){
            for(let layer of Object.values(this._layers) ){
                if(layer.isScenePolygon) {
                    switch(true){
                        case this._zoom <= 5:
                            layer.setStyle({weight: 20})
                            break;
                        case this._zoom <= 15:
                            layer.setStyle({weight: 10})
                            break;
                        default:
                            layer.setStyle({weight: 4})
                    }
                    layer.redraw()
                }
            }
        })
    })
    
    // RENDERERS
    const drawScenes = (scenes, map) => {

        let bbox,
            bounds,
            polygon;
        for (let scene of scenes) {
            bbox = scene.bbox;
            bounds = [
                [bbox[1], bbox[0]], // lower left
                [bbox[3], bbox[0]], // upper left
                [bbox[3], bbox[2]], // upper right
                [bbox[1], bbox[2]], // lower right
                [bbox[1], bbox[0]], // closing
            ]
            polygon = L.polygon(bounds, { color: percent2Color(scene.score) })
            polygon.setStyle({weight: 20})
            polygon.addTo(map)
            polygon.isScenePolygon = true
            polygon.sceneObject = scene

            polygon.bindPopup(generateScenePopup(scene), {autoPan: false})
            polygon.on("mouseover", function() {this.openPopup()})
            polygon.on("mouseout", function() {this.closePopup()})

            polygon.on("click", () => {
                setters.setSelectedScene(scene)
            })

        }

    }

    useEffect(() => {
        if (!!map) {
            drawScenes(state.scenes ?? [], map)
        }
    }, [state.scenes, map])

    return (
        <div id="area-map" style={{ height: "100%", width: "100%" }}></div>
    )
}
