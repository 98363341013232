
// this file contains provider/context pairs for both the explorer and data prep states

import CantusFirmus from 'cantus-firmus';

import { explorerState, dataPrepState } from './state'
import setters, { dataPrepSetters } from './setters'
import getters from './getters'
import methods, { geoserverAPI, dataPrepAPI, linkShortenerAPI } from './methods'

const privateStatePaths = [
    "page",
    "viewer",
    "layers",
    "baseLayer",
    "renderedLayers",
    "selectedLayer",
    "numFeatures",
    "selectedLayerAnalytics",
    "globalSettings",
    "layerInfoBox",
    "dpSessions",
    "selectedSession",
    "sessionTasks",
    "selectedTask",
    "annotationLayers",
    "depthDataSource",
    // ["globalSettings", "defaultLocation"]
]

/* 
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::: EXPLORER :::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
*/
const explorer = new CantusFirmus(explorerState, { nestedSetters: true, nestedGetters: true });

explorer.addCustomSetters(setters)
explorer.addCustomGetters(getters)

explorer.addMethods(methods)
explorer.addNamespacedMethods({ geoserverAPI, linkShortenerAPI })


// explorer.connectToLocalStorage({
//     name: "explorer",
//     providerWindow: "CASIv4",
//     clearStorageOnUnload: true,
//     initializeFromLocalStorage: true,
//     privateStatePaths,
// })

export const ExplorerContext = explorer.context;
export const ExplorerProvider = explorer.createProvider();


/* 
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::: DATA PREP :::::::::::::::::::::::::::::::
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
*/

const dataPrep = new CantusFirmus(dataPrepState, { nestedSetters: true, nestedGetters: true });

dataPrep.addCustomSetters({...setters, ...dataPrepSetters});
dataPrep.addCustomGetters(getters);

dataPrep.addMethods(methods)
dataPrep.addNamespacedMethods({ geoserverAPI, dataPrepAPI })

dataPrep.connectToLocalStorage({
    name: "dataPrep",
    providerWindow: "CASIv4",
    clearStorageOnUnload: true,
    initializeFromLocalStorage: true,
    privateStatePaths
})

export const DataPrepContext = dataPrep.context
export const DataPrepProvider = dataPrep.createProvider();