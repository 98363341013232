import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import useWindowResize from '../../../hooks/useWindowResize';
import "./MapPopupWrapper3D.css"
import ScrollContent from './ScrollContent';

export default function MapPopupWrapper3D({
    children,

    viewer,

    closeButton = false,
    onClose = e => { },
    minimizeButton = false,

    location = "static-top-left",
    positioning = "top-left",
    resizable = false,

    className = "",

    containerStyle = {},
    bodyStyle = {},
}) {


    // STATE
    const [position, setPosition] = useState([{ x: 0, y: 0 }])
    const size = useWindowResize({ stroll: true, resize: true })
    const [isMinimized, setIsMinimized] = useState(false)
    const [maximizeHeight, setMaximizeHeight] = useState(300)
    const [maximizeWidth, setMaximizeWidth] = useState(500)
    const draggingRef = useRef(null);
    const popupRef = useRef();

    // HELPERS
    const positionPopup = (mapBounds, element) => {
        let x, y;
        if(!mapBounds || !element) return
        
        const [width, height] = [element.clientWidth, element.clientHeight];

        // if the location provided is a dynamic point
        if (typeof location === "object") {
            switch (positioning) {
                case "top-left":
                    x = location.x
                    y = location.y
                    break;
                case "top-right":
                    x = location.x - width
                    y = location.y
                    break;
                case "bottom-left":
                    x = location.x
                    y = location.y - height
                    break;
                case "bottom-right":
                    x = location.x - width
                    y = location.y - height
                    break;
                case "center":
                    x = location.x - (width / 2)
                    y = location.y - (height / 2)
                    break;
                case "center-left":
                    x = location.x
                    y = location.y - (height / 2)
                    break;
            }

        } else if (typeof location === "string" && location.match("static")) {
            switch (location) {
                case "static-top-left":
                    x = mapBounds.left + 5;
                    y = mapBounds.top + 5;
                    break;
                case "static-top-right":
                    x = mapBounds.right - width - 10;
                    y = mapBounds.top + 5;
                    break;
                case "static-bottom-right":
                    x = mapBounds.right - width - 10;
                    y = mapBounds.bottom - height - 10;
                    break;
                case "static-bottom-left":
                    x = mapBounds.left + 5;
                    y = mapBounds.bottom - height - 10;
                    break;
                case "static-center":
                    x = mapBounds.left + ((mapBounds.width / 2) - (width / 2))
                    y = mapBounds.top + ((mapBounds.height / 2) - (height / 2))
                    break;
            }
        }

        // adjust x and y to ensure that the popup does not overflow the map bounds        
        if (y < mapBounds.top) y = mapBounds.top + 5 // overflow on top
        if (x + width > mapBounds.right) x = mapBounds.right - width - 5 // overflow on right
        if (y + height > mapBounds.bottom) y = mapBounds.bottom - height - 5 // overflow on bottom
        if (x < mapBounds.left) x = mapBounds.left + 5 // overflow on left

        x = x >= mapBounds.left ? x : mapBounds.left
        y = y >= mapBounds.top ? y : mapBounds.top

        setPosition({ x, y })
    }

    // EVENTS

    const handleCloseClick = e => {
        e.stopPropagation()
        e.preventDefault()

        onClose(e)
    }

    const handleMinimizeClick = e => {
        e.stopPropagation()
        e.preventDefault()

        setIsMinimized(!isMinimized)
    }

    // ON LOAD
    useEffect(() => {
        const element = draggingRef.current;
        const canvas = viewer.canvas;
        const mapBounds = canvas.getBoundingClientRect()
        // const elementBounds = element.getBoundingClientRect()

        positionPopup(mapBounds, element)

        if (!element.onmousedown) {
            element.onmousedown = e => {
                e.preventDefault()
                const el = e.target
                const [width, height] = [el.clientWidth, el.clientHeight];

                // disable dragging on the map temporarily 
                // map && map.dragging.disable()


                const [grabPosX, grabPosY] = [e.clientX, e.clientY]
                const popupPos = el.getBoundingClientRect();
                const [prevX, prevY] = [popupPos.left, popupPos.top]
                const windowMouseMove = e => {
                    e.preventDefault()

                    const [mousePosX, mousePosY] = [e.clientX, e.clientY]

                    // calculate the x and y coordinates
                    let [x, y] = [prevX + mousePosX - grabPosX, prevY + mousePosY - grabPosY];

                    // adjust coordinates based on the size of the popup element
                    if (x < mapBounds.left) x = mapBounds.left;
                    if (x + width > mapBounds.right) x = mapBounds.right - width - 5;
                    if (y < mapBounds.top) y = mapBounds.top;
                    if (y + popupRef.current.clientHeight > mapBounds.bottom) y = mapBounds.bottom - popupRef.current.clientHeight - 5;

                    setPosition({ x, y })
                    el.style.cursor = "grabbing"

                }
                window.onmousemove = windowMouseMove;
                window.onmouseup = e => {
                    // map && map.dragging.enable()
                    window.onmousemove = null
                    window.onmouseup = null
                    el.style.cursor = "grab";
                }
            }
        }


    }, [])

    useEffect(() => {
        const element = draggingRef.current;
        const canvas = viewer?.canvas;
        if (canvas) {
            const mapBounds = canvas.getBoundingClientRect()
            positionPopup(mapBounds, element)
        }
    }, [size])

    useEffect(() => {
        if (isMinimized) {
            setMaximizeHeight(popupRef.current.style.height)
            setMaximizeWidth(popupRef.current.style.width)
            popupRef.current.style.height = "0px"
            popupRef.current.style.width = "0px"
        } else {
            popupRef.current.style.height = maximizeHeight
            popupRef.current.style.width = maximizeWidth
        }
    }, [isMinimized])


    //RESIZING
    const startX = useRef(0)
    const startY = useRef(0)
    const startWidth= useRef(0)
    const startHeight = useRef(0)

    const resizeHandle = useRef();
    useEffect(()=>{
        if (resizeHandle.current) {
            resizeHandle.current.removeEventListener('mousedown', initResizeDrag);
            resizeHandle.current.addEventListener('mousedown',initResizeDrag);
        }
    }, [resizable, isMinimized])

    function initResizeDrag(e) {
        e.preventDefault()
        startX.current = e.clientX
        startY.current = e.clientY
        startWidth.current = parseInt(document.defaultView.getComputedStyle(popupRef.current).width, 10)
        startHeight.current = parseInt(document.defaultView.getComputedStyle(popupRef.current).height, 10)
        if (resizeHandle.current) {
            document.documentElement.addEventListener('mousemove',doResizeDrag);
            document.documentElement.addEventListener('mouseup',stopResizeDrag);
        }
    }
    
    function doResizeDrag(e) {
        e.preventDefault()
        const element = draggingRef.current;
        const canvas = viewer?.canvas;
        if (!canvas) {
            console.log("no canvas?")
        }
        const mapBounds = canvas.getBoundingClientRect()
        popupRef.current.style.width = (startWidth.current + e.clientX - startX.current) + 'px';
        popupRef.current.style.height = (startHeight.current + e.clientY - startY.current) + 'px';
        if (e.clientX > mapBounds.right) {
            popupRef.current.style.width = (startWidth.current + mapBounds.right - startX.current) + 'px';
        }
        if (e.clientY > mapBounds.bottom) {
            popupRef.current.style.height = (startHeight.current + mapBounds.bottom - startY.current) + 'px';
        }
    }
    
    function stopResizeDrag(e) {
        if (resizeHandle.current) {
            document.documentElement.removeEventListener('mousemove', doResizeDrag);   
            document.documentElement.removeEventListener('mouseup', stopResizeDrag);
        } 
    }

    useLayoutEffect(() => {
        // if children are ScrollContent, save our width and height while they are
        // at their inital width and height, they will lose their constraints next frame
        // if our children aren't ScrollContent this does effectively nothing

        //get bounds of viewer and make sure we don't exceed them
        const canvas = viewer?.canvas;
        if (!canvas) {
            console.log("no canvas?")
        }
        const mapBounds = canvas.getBoundingClientRect()
        popupRef.current.style.height = Math.min(mapBounds.height, popupRef.current.clientHeight) + "px"
        popupRef.current.style.width = Math.min(mapBounds.width, popupRef.current.clientWidth + 1) + "px"
    }, [])


    return (
        <div className={`map-popup-3d ${className}`} ref={popupRef} style={{ top: position.y, left: position.x, ...containerStyle }}>
            <div style={{ overflow: "hidden", width: "100%", height: "100%", display: "flex", flexFlow: "column" }}>
                {
                    <div className="map-popup-window-controls-3d" ref={draggingRef}>
                        {
                            closeButton
                            &&
                            <>
                                <button className="map-popup-close-btn-3d" onClick={handleCloseClick}>
                                    <svg height="12" width="12">
                                        <line x1="0" y1="0" x2="12" y2="12" style={{ stroke: "#000", strokeWidth: 3 }} />
                                        <line x1="12" y1="0" x2="0" y2="12" style={{ stroke: "#000", strokeWidth: 3 }} />
                                    </svg>
                                </button>
                            </>
                        }
                        {
                            minimizeButton
                            &&
                            <>
                                <button className="map-popup-close-btn-3d" onClick={handleMinimizeClick}>
                                    <svg height="12" width="12">
                                        <line x1="0" y1="6" x2="12" y2="6" style={{ stroke: "#000", strokeWidth: 3 }} />
                                    </svg>
                                </button>
                            </>
                        }
                    </div>
                }
                <div className="map-popup-inner-3d" style={bodyStyle}>
                    {!isMinimized && children}
                </div>
            </div>
            {resizable && !isMinimized &&
                <div ref={resizeHandle} className="map-popup-resizer-3d"></div>
            }
        </div>
    )
}
