import React from 'react'

import './Grid.css'


const formatAreas = areas => {
    return areas.map(line => `'${line.join(" ")}'`).join(" ")
}

const Grid = ({
    children,
    cols = "auto",
    rows = "auto",
    precedence = "cols",
    colSpacing = "1fr",
    rowSpacing = "1fr",
    areas = null,
    id = "",
    className = "",
    style = {}
}) => {
    
    className = `grid ${className}`

    colSpacing = !!colSpacing.match(" ") ? colSpacing : `repeat(${cols}, ${colSpacing})`
    rowSpacing = !!rowSpacing.match(" ") ? rowSpacing : `repeat(${rows}, ${rowSpacing})`

    let gridStructure;
    if (cols === 'auto' && rows === 'auto') {
        if (cols === "auto" && children?.length) cols = children?.length
        if (rows === "auto" && children?.length) rows = children?.length
        
        switch (precedence) {
            case "cols":
                gridStructure = {gridTemplateColumns: colSpacing}
                break;
            case "rows":
                gridStructure = {gridTemplateRows: rowSpacing}                
                break;
        }
    } else {
        if (cols === "auto" && children?.length) cols = children?.length
        if (rows === "auto" && children?.length) rows = children?.length
        gridStructure = { gridTemplateColumns: colSpacing, gridTemplateRows: rowSpacing }

    }

    if(cols === 'none' && !colSpacing.match(" ")) delete gridStructure.gridTemplateColumns
    if(rows === 'none' && !rowSpacing.match(" ")) delete gridStructure.gridTemplateRows

    if(areas) {
        gridStructure.gridTemplateAreas = formatAreas(areas);
    }

    return <div id={id} className={className} style={{ ...gridStructure, ...style }}>{!!children ? children : ""}</div>
}

export default Grid