
const globalSettings = {
    defaultLocation: { lat: 0, lng: 0, alt: 1e4 },
}

const baseState = {
    viewer: null,
    baseLayer: null, 

    showRibbon: true,

    globalSettings,

    layers: [],
    renderedLayers: {},

    selectedLayer: null,
    numFeatures: null,
    selectedLayerAnalytics: null,

    layerInfoBox: null,
    
    depthDataSource: null,

}

export const explorerState = {
    ...baseState,
    page: "explorer"
}


export const dataPrepState = {
    ...baseState,
    page: "dataPrep",
    privilege: new URLSearchParams(window.location.search).get("privilege"),

    dpSessions: [],
    selectedSession: null,

    sessionTasks: [],
    selectedTask: null,

    annotationLayers: [],
}
