import { useEffect, useContext } from "react"
import { useLocation } from "react-router"
import { UserContext } from "../state/users/userProvider"

export const usePage = () => {
    return useLocation().pathname.slice(1)
}

export const useGetGeoserverToken = (page) => {

    const { API: userAPI } = useContext(UserContext);
    useEffect(() => {
        if (page !== "login") {
            const exec = async () => {
                const token = await userAPI.getGeoserverToken()
            }
            exec()
        }
    }, [page])
}