
import CantusFirmus from 'cantus-firmus';

import state from './state'
import setters from './setters'
import API from './API'
import constants from './constants'


const main = new CantusFirmus(state)

main.addCustomSetters(setters)
main.addMethods(API)

main.addConstants(constants)

export const MainContext = main.context;
export const MainProvider = main.createProvider();

