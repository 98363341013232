import React from 'react';
import SidebarLayer from './SidebarLayer';


export default function ServerLayerGroup({
    serverName,
    layers,
    page,
    viewer,
    toggleLayer,
    deleteLayer,
    selectedLayer,
    setSelectedLayer,
    geoserverAPI,
    toggleDataFilter,
}) {

    // RENDERERS
    const renderLayers = (layers) => {
        return layers.map(layer => (
            <SidebarLayer
                key={layer.id}
                layer={layer}
                {...{
                    page,
                    viewer,
                    toggleLayer,
                    deleteLayer,
                    selectedLayer,
                    setSelectedLayer,
                    geoserverAPI,
                    toggleDataFilter,
                }}
            />
        ))
    }

    return (
        <>
            <hr className='mt-1' />
            <div className='ml-4 mt-1'>
                <h4 className='px-1 rounded-sm font-bold text-white truncate shadow-sm shadow-gray-800' style={{ backgroundColor: "var(--primaryAccent-4)" }}>
                    Server: {serverName}
                </h4>
                <div className='ml-4'>
                    {renderLayers(layers)}
                </div>
            </div>
        </>
    )

}