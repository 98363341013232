import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'

// ===================================== MODULES =====================================
import { BarChart, ResponsiveContainer, XAxis, YAxis, Bar, Cell, LineChart, Line, Tooltip, CartesianGrid, Label, Legend } from 'recharts'
import copyToClipboard from '../../js/copyToClipboard'

// ===================================== HELPERS =====================================
import { formatSelectedHistogramData, formatSelectedTimeseriesData, percent2Color } from './helpers/dashboardHelpers'

// ===================================== CHILDREN =====================================

const METRICS = {

}



const LineLegend = ({ payload, toggleMetric, metrics }) => {

    const [statefulPayload, setStatefulPayload] = useState({ ...payload })

    const renderToggleButtons = () => {
        return Object.values(statefulPayload).map(pl => {
            console.log(pl)
            return (
                <button
                    key={pl.dataKey}
                    className='m-1 px-1 bg-gray-100 text-black text-lg font-bold'
                    style={{ backgroundColor: pl.color, opacity: metrics[pl.dataKey] ? 1 : 0.5 }}
                    onClick={toggleMetric(pl.dataKey)}
                >
                    {pl.value}
                </button>
            )
        })
    }
    return (
        <div className='relative bottom-4 text-center'>
            {renderToggleButtons()}
        </div>
    )
}

const RenderTimeseriesTooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
        const data = payload[0].payload
        return (
            <div
                className='text-2xl font-bold'
                style={{
                    backgroundColor: "var(--neutral2)",
                    color: "var(--neutral12)",
                    padding: "0.25rem"
                }}
            >
                <strong>{data.formattedDatetime}</strong>
                <p style={{ color: "var(--neutral8)", margin: 0 }}><strong>Catid:</strong> {data.catid}</p>
                <hr />
                <p style={{ color: "var(--neutral0)", backgroundColor: "red", margin: 0 }}><strong>Composite Stability:</strong> {data.average?.toFixed(5) || "Data Unavailable"}</p>
                <h4>Breakdown</h4>
                <hr />
                <p style={{ color: "var(--neutral0)", backgroundColor: "var(--primaryAccent3)", margin: 0 }}><strong>% Change:</strong> {data.stability?.toFixed(5) || "Data Unavailable"}</p>
                <p style={{ color: "var(--neutral0)", backgroundColor: "var(--secondaryAccent3)", margin: 0 }}><strong>Verification:</strong> {data.validity?.toFixed(5) || "Data Unavailable"}</p>
                <p style={{ color: "var(--neutral0)", backgroundColor: "var(--tertiaryAccent3)", margin: 0 }}><strong>Image Quality:</strong> {data.quality?.toFixed(5) || "Data Unavailable"}</p>
            </div>
        )
    }
    return null
}

export default function SelectedSceneChart({ scene, chartType }) {

    const [chartData, setChartData] = useState([])
    const [metrics, setMetrics] = useState({ "average": true, "quality": false, "validity": false, "stability": false })

    // EVENTS
    const handleTimeseriesClick = (e, data) => {
        copyToClipboard(data.payload.catid)
    }

    const toggleMetric = metric => () => {
        setMetrics(metrics => {
            return {
                ...metrics,
                [metric]: !metrics[metric]
            }
        })
    }


    // EFFECTS
    useEffect(() => {

        let formatted;
        switch (chartType) {
            case "Change Validation":
                formatted = formatSelectedHistogramData(scene)
                setChartData(formatted)
                break;
            case "Stability Validation":
                formatted = formatSelectedTimeseriesData(scene)
                console.log(formatted)
                setChartData(formatted)
                break;
        }
    }, [scene, chartType])

    useEffect(() => {
        // reset which metrics are shown
        setMetrics({ "average": true, "quality": false, "validity": false, "stability": false })
    }, [scene, chartType])


    return (
        <div className={"dashboard-chart"}>
            <ResponsiveContainer>
                <LineChart data={chartData}>
                    <CartesianGrid strokeDasharray="4 4" />
                    <XAxis
                        dataKey={"timestamp"}
                        stroke="var(--neutral16)"
                        type="number"
                        domain={["dataMin", "dataMax"]}
                        tickFormatter={ts => DateTime.fromMillis(ts).toFormat("MM/yyyy")}
                    // angle={45}
                    >
                        <Label value="Datetime" stroke={"var(--neutral16)"} position={"insideBottom"} offset={-5} />
                    </XAxis>

                    

                    <YAxis stroke="var(--neutral16)" domain={[0, 1]} >
                        <Label value={"STABLE"} angle={-90} stroke={percent2Color(100)} position={"insideTop"}  />
                        <Label value={"Score"} angle={-90} stroke={"var(--neutral16)"} position={"insideLeft"} />
                        <Label value={`UNSTABLE`} angle={-90} stroke={percent2Color(0)} position={"insideBottom"}/>
                    </YAxis>




                    <Tooltip content={<RenderTimeseriesTooltip />} />

                    {/* <Legend verticalAlign="top" align="center" layout="horizontal"/> */}
                    <Legend verticalAlign="top" align="center" layout="horizontal" content={<LineLegend metrics={metrics} toggleMetric={toggleMetric} />} />

                    <Line
                        dataKey="average"
                        name="Overall Confidence"
                        stroke={"red"}
                        strokeWidth={metrics.average ? 5 : 0}
                        dot={metrics.average ? { stroke: "red", color: "red", strokeWidth: 5, r: 4 } : null}
                        isAnimationActive={false}
                    />

                    <Line
                        dataKey="stability"
                        name="% Change"
                        stroke={"var(--primaryAccent3)"}
                        strokeWidth={metrics.stability ? 2 : 0}
                        dot={metrics.stability ? { stroke: "var(--primaryAccent3)", strokeWidth: 1, r: 3 } : null}
                        activeDot={{ onClick: handleTimeseriesClick }}
                        strokeDasharray={"4"}
                        isAnimationActive={false}
                    />

                    <Line
                        dataKey="validity"
                        name="Verification"
                        stroke={"var(--secondaryAccent3)"}
                        strokeWidth={metrics.validity ? 2 : 0}
                        dot={metrics.validity ? { stroke: "var(--secondaryAccent3)", strokeWidth: 1, r: 3 } : null}
                        activeDot={{ onClick: handleTimeseriesClick }}
                        strokeDasharray={"4"}
                        isAnimationActive={false}
                    />

                    <Line
                        dataKey="quality"
                        name="Image Quality"
                        stroke={"var(--tertiaryAccent3)"}
                        strokeWidth={metrics.quality ? 2 : 0}
                        dot={metrics.quality ? { stroke: "var(--tertiaryAccent3)", strokeWidth: 1, r: 3 } : null}
                        activeDot={{ onClick: handleTimeseriesClick }}
                        strokeDasharray={"4"}
                        isAnimationActive={false}
                    />

                </LineChart>
            </ResponsiveContainer>
        </div>
    )


}
