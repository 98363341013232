

// const TEST_SCENES = Array.from({ length: 98 })
//     .fill()
//     .map(generateDummyData)

const state = {
    scenes: [],
    filteredScenes: [],
    
    areas: [],
    filteredAreas: [],

    filters:{
        name: "",
        stabilityScore: 100,
        nob: "",
        detectors: [],
        country: null
    },
    selectedScene: null,

}

export default state;
