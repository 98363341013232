import React, { useContext, useState, useEffect } from 'react'

// =================================== STATE ===================================
import { ChangeDetectionContext } from '../../state/changeDetection/changeDetectionProvider';

// =================================== DZYNE COMPONENTS ===================================
import Grid from '../dzyne_components/layout/Grid/Grid';
import FormElementWrapper from '../dzyne_components/ui/forms/FormElementWrapper/FormElementWrapper';
import Center from '../dzyne_components/layout/Center/Center'
import HintModal from '../dzyne_components/ui/modals/HintModal/HintModal'
import InlineButton from '../dzyne_components/ui/buttons/InlineButton/InlineButton'

export default function SceneFilter() {

    const { state, setters } = useContext(ChangeDetectionContext)

    // EVENTS
    const handleFilterChange = (filterSetter, type) => e => {
        let val = e.target.value;
        if (type === "int") val = parseInt(val)
        if (type === "float") val = parseFloat(val)
        if (type === "multiselect") {
            val = [];
            [...e.target.children].forEach(o => o.selected && val.push(o.value))
        }

        filterSetter(val)
    }

    const handleResetClick = () => {
        setters.setFilters({
            stabilityScore: 100,
            name: "",
            country: null
        })
    }

    // RENDERERS
    const renderCountryOptions = (scenes) => {
        const countries = Array.from(new Set(scenes.map(s => s.country)))
        return countries.map(country => (
            <option key={country} value={country}>{country}</option>
        ))
    }

    // EFFECTS
    useEffect(() => {
        let filtered = state.scenes

        // regex filter
        let re;
        try {
            re = new RegExp(state.filters.name, "gi")
        } catch (err) {
            re = null
        }

        // NOB ID
        filtered = re ? filtered.filter(scene => !!scene.name.match(re)) : []
        // STABILITY SCORE
        filtered = filtered.filter(scene => scene.score <= state.filters.stabilityScore / 100)
        // COUNTRY
        filtered = state.filters.country ? filtered.filter(scene => scene.country === state.filters.country) : filtered;

        setters.setFilteredScenes(filtered)

        // reset scenes when we leave this page
        return () => {
            setters.setFilteredScenes(state.scenes)
        }
    }, [state.filters, state.scenes])

    return (
        <div id="scene-filter" style={{ gridArea: "filter" }}>
            <Grid cols={2} colSpacing={"3fr 9fr"} className="font-bold" style={{ gridGap: "1rem" }}>
                <div className="dashboard-panel">
                    <HintModal content="The total number of scenes and the number of displayed scenes given the filtering criteria">
                        <h3 className="dashboard-panel-header text-lg font-bold" style={{ textAlign: "center", cursor: "help" }}>Scenes</h3>
                    </HintModal>

                    <Grid cols={2} style={{ gridGap: "0.25rem" }}>

                        <Center className="dashboard-panel-section ">
                            <div>
                                <h3 className="dashboard-panel-header text-lg font-bold" style={{ textAlign: "center" }}>Total</h3>
                                <h1 className="dashboard-panel-header text-lg font-bold" style={{ textAlign: "center" }}>{state?.scenes?.length}</h1>
                            </div>
                        </Center>


                        <Center className="dashboard-panel-section">
                            <div>
                                <h3 className="dashboard-panel-header text-lg font-bold" style={{ textAlign: "center" }}>Filtered</h3>
                                <h1 className="dashboard-panel-header text-lg font-bold" style={{ textAlign: "center" }}>{state?.filteredScenes?.length}</h1>
                            </div>
                        </Center>

                    </Grid>
                </div>

                <div className="dashboard-panel">
                    <HintModal content="Use the inputs below to filter the scenes displayed in the list">
                        <h3 className="dashboard-panel-header text-lg font-bold" style={{ cursor: "help" }}>Filters</h3>
                    </HintModal>
                    <Grid cols={3}>
                        <div>

                            <FormElementWrapper labels={["Job Name", "Stability (100=stable, 0=unstable)"]}>
                                <input id="scene-filter-nob" className='mb-2 p-1 rounded-sm drop-shadow-sm shadow-black' type="text" value={state.filters.name} onChange={handleFilterChange(setters.setFilters_name)} />
                                <div>
                                    <input className='mr-2 p-1 rounded-sm drop-shadow-sm shadow-black' id="scene-filter-prct-chng" type="number" min="0" max="100" step="1" value={state.filters.stabilityScore} onChange={handleFilterChange(setters.setFilters_stabilityScore, "int")} />
                                    <input className='cursor-pointer' type="range" min="0" max="100" value={state.filters.stabilityScore} onChange={handleFilterChange(setters.setFilters_stabilityScore, "int")} />
                                </div>
                            </FormElementWrapper>

                        </div>

                        <div>
                            <label>Country</label><br />
                            <select className='p-1 rounded-sm drop-shadow-sm shadow-black' value={state.filters.country} onChange={handleFilterChange(setters.setFilters_country)}>
                                <option value={""}>Any</option>
                                {renderCountryOptions(state.scenes)}
                            </select>
                        </div>

                        <div>
                            <InlineButton onClick={handleResetClick}>Reset</InlineButton>
                        </div>


                    </Grid>
                </div>




            </Grid>
        </div>
    )
}
