import { v4 as uuidv4 } from 'uuid';

// CONFIGURATION
const DEFAULT_OPTIONS = {

}

const HANDLE_RADIUS_SM = 5
const HANDLE_RADIUS_LG = 9

// UTILITY FUNCTIONS
const zoomed2Actual = (point, zoomFactor) => {
    return {
        x: Math.round(point.x / zoomFactor),
        y: Math.round(point.y / zoomFactor),
    }
}

const actual2Zoomed = (point, zoomFactor) => {
    return {
        x: Math.round(point.x * zoomFactor),
        y: Math.round(point.y * zoomFactor)
    }
}

const clearEntitiesByID = (id) => {
    const elements = [...document.querySelectorAll(`[data-annotationid='${id}']`)]
    elements.forEach(el => el.remove())
}

const clearHandles = id => {
    const elements = [...document.querySelectorAll(`.annotation-handle[data-annotationid='${id}']`)]
    elements.forEach(el => el.remove())
}

const rect2Polygon = points => {
    if((points[0].x < points[1].x && points[0].y > points[1].y) || (points[0].x > points[1].x && points[0].y < points[1].y)){
        return [
            {...points[0]},
            {x: points[1].x, y: points[0].y},
            {...points[1]},
            {x: points[0].x, y:points[1].y}
        ]
    } else {
        return [
            {...points[0]},
            {x: points[0].x, y: points[1].y},
            {...points[1]},
            {x: points[1].x, y:points[0].y}
        ]
    }
}

const WKT_HANDLERS = {

    point(annotation){
        let point = annotation.current[0];
        return `POINT(${point.x} ${point.y})`
    },

    multipoint(annotation){
        let points = annotation.current;
        points = points.map(point => `${point.x} ${point.y}`)
        return `MULTIPOINT(${points.join(", ")})`
    },

    polyline(annotation){
        return `LINESTRING(${annotation.current.map(point => `${point.x} ${point.y}`).join(", ")})`
    },

    polygon(annotation){
        const points = [...annotation.current, annotation.current[0]]
        return `POLYGON((${points.map(point => `${point.x} ${point.y}`).join(", ")}))`
    },

    rectangle(annotation){
        let points = rect2Polygon(annotation.current)
        points = [...points, points[0]]        
        let pointString = points.map(point => `${point.x} ${point.y}`)
        return `POLYGON((${pointString.join(", ")}))`
    },

    circle(annotation){
        let points = annotation.current;
        const radius = Math.sqrt((points[1].x - points[0].x) ** 2 + (points[1].y - points[0].y) ** 2);
        return {
            center: `POINT(${points[0].x} ${points[0].y})`,
            radius
        }
    },

    default(annotation){
        console.log("DEFAULT", annotation)
    }
}

const annotation2WKT = annotation => {
    return WKT_HANDLERS[annotation.type]?.(annotation) ?? WKT_HANDLERS.default(annotation)
}

// RENDERERS
const drawHandles = (annotation, customAttributes = {}) => {
    const { current: points, svg, _id: id } = annotation;

    // retrieve the zoom factor
    const zoomFactor = parseInt(svg.dataset.zoom) / 100

    // setup default attributes
    let marker;
    let attributes = {
        class: "annotation-handle",
        "data-annotationid": id,
        "data-type": "handle",
        "r": HANDLE_RADIUS_SM,
        "stroke-width": 3,
        stroke: "red",
        fill: "red",
        ...customAttributes
    }

    for (let [index, point] of Object.entries(points)) {
        // transform point based on current zoom factor
        point = actual2Zoomed(point, zoomFactor)

        // create svg circle element
        marker = document.createElementNS("http://www.w3.org/2000/svg", "circle",)

        // add specific attributes and apply to element
        attributes.cx = point.x
        attributes.cy = point.y
        attributes["data-index"] = index

        for (let [attribute, value] of Object.entries(attributes)) {
            marker.setAttribute(attribute, value)
        }

        // add any necessary event listeners
        marker.addEventListener("click", () => {
            annotation.constructor.selectByID(annotation._id)
        })

        marker.addEventListener("mouseenter", e => {
            e.target.setAttribute("r", HANDLE_RADIUS_LG)
        })

        marker.addEventListener("mouseleave", e => {
            e.target.setAttribute("r", HANDLE_RADIUS_SM)
        })

        marker.addEventListener("dblclick", e => {
            e.stopPropagation()
            e.preventDefault()

            annotation.removePoint(index)
        })

        // add the element to the svg container
        svg.appendChild(marker)
    }

}

const drawPolyline = (annotation, customAttributes = {}) => {
    const { current: points, svg, _id: id } = annotation;

    if (points.length < 2) return; // don't draw polyline when not at least 2 points

    const zoomFactor = parseInt(svg.dataset.zoom) / 100
    const attributes = {
        class: "annotation-line",
        "data-annotationid": id,
        "data-type": "line",
        "stroke-width": 3,
        stroke: "red",
        ...customAttributes
    }
    let line,
        startPoint,
        endPoint;

    for (let i = 0; i < points.length - 1; i++) {
        startPoint = actual2Zoomed(points[i], zoomFactor);
        endPoint = actual2Zoomed(points[i + 1], zoomFactor);

        attributes.x1 = startPoint.x
        attributes.y1 = startPoint.y
        attributes.x2 = endPoint.x
        attributes.y2 = endPoint.y

        line = document.createElementNS("http://www.w3.org/2000/svg", "line");
        for (let [attribute, value] of Object.entries(attributes)) {
            line.setAttribute(attribute, value)
        }

        line.addEventListener("click", () => {
            annotation.constructor.selectByID(annotation._id)
        })

        svg.appendChild(line)
    }

}

const drawPolygon = (annotation, customAttributes = {}) => {
    const { current: points, svg, _id: id } = annotation;


    if (points.length < 2) return; // don't draw polygon when not at least 2 points

    // retrieve the zoom factor
    const zoomFactor = parseInt(svg.dataset.zoom) / 100
    const attributes = {
        class: "annotation-line",
        "data-annotationid": id,
        "data-type": "line",
        "stroke-width": 3,
        stroke: "red",
        ...customAttributes
    }
    let line,
        startPoint,
        endPoint;

    for (let i = 0; i < points.length; i++) {
        startPoint = actual2Zoomed(points[i], zoomFactor)
        endPoint = actual2Zoomed(points[i === points.length - 1 ? 0 : i + 1], zoomFactor)

        attributes.x1 = startPoint.x
        attributes.y1 = startPoint.y
        attributes.x2 = endPoint.x
        attributes.y2 = endPoint.y

        line = document.createElementNS("http://www.w3.org/2000/svg", "line");
        for (let [attribute, value] of Object.entries(attributes)) {
            line.setAttribute(attribute, value)
        }

        line.addEventListener("click", () => {
            annotation.constructor.selectByID(annotation._id)
        })

        line.style.cursor = "pointer"
        svg.appendChild(line)
    }

}

const drawRectangle = (annotation, customAttributes = {}) => {
    const { current: points, svg, _id: id } = annotation;

    // don't draw rectangle if not at least 2 points
    if (points.length < 2) return;

    const zoomFactor = parseInt(svg.dataset.zoom) / 100
    const attributes = {
        class: "annotation-line",
        "data-annotationid": id,
        "data-type": "line",
        "stroke-width": 3,
        stroke: "red",
        ...customAttributes
    }

    const [pnt1, pnt2] = points.map(point => actual2Zoomed(point, zoomFactor))
    let lines = [
        { x1: pnt1.x, x2: pnt2.x, y1: pnt1.y, y2: pnt1.y },
        { x1: pnt2.x, x2: pnt2.x, y1: pnt1.y, y2: pnt2.y },
        { x1: pnt2.x, x2: pnt1.x, y1: pnt2.y, y2: pnt2.y },
        { x1: pnt1.x, x2: pnt1.x, y1: pnt2.y, y2: pnt1.y },
    ];

    let edge, appliedAttributes;
    for (let line of lines) {
        appliedAttributes = { ...line, ...attributes }

        edge = document.createElementNS("http://www.w3.org/2000/svg", "line");
        for (let [attribute, value] of Object.entries(appliedAttributes)) {
            edge.setAttribute(attribute, value)
        }

        edge.addEventListener("click", () => {
            annotation.constructor.selectByID(annotation._id)
        })

        svg.appendChild(edge)

    }


}

const drawCircle = (annotation, customAttributes = {}) => {
    const { current: points, svg, _id: id } = annotation;

    // don't draw rectangle if not at least 2 points
    if (points.length < 2) return;

    const zoomFactor = parseInt(svg.dataset.zoom) / 100
    const attributes = {
        class: "annotation-circle",
        "data-annotationid": id,
        "data-type": "line",
        "stroke-width": 3,
        fill: "none",
        stroke: "red",
        ...customAttributes
    }


    const [pnt1, pnt2] = points.map(point => actual2Zoomed(point, zoomFactor))

    attributes.cx = pnt1.x
    attributes.cy = pnt1.y
    attributes.r = Math.sqrt((pnt2.x - pnt1.x) ** 2 + (pnt2.y - pnt1.y) ** 2)

    const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");

    for (let [attribute, value] of Object.entries(attributes)) {
        circle.setAttribute(attribute, value)
    }

    circle.addEventListener("click", () => {
        annotation.constructor.selectByID(annotation._id)
    })

    svg.appendChild(circle)


}


export default {
    accessRegistry(id) {

        if (id && window._imageAnnotationRegistry?.[id]) return { registry: window._imageAnnotationRegistry[id] }

        class ImageAnnotation {
            constructor({
                svg,
                id,
                type,
                metadata = {},
                options = {},
                initPoints = null,

            }) {

                // INITIALIZATION
                this._id = id ?? uuidv4();
                this.svg = svg;
                this.type = type;
                this.metadata = metadata;
                this.options = { ...DEFAULT_OPTIONS, ...options }
                this._events = {}


                // INITIALIZED DEFAULTS
                this.current = initPoints ?? []
                this.history = initPoints ? [initPoints]: []
                this._redoHistory = []
                this._selected = false
        
                // BINDINGS
                this._pointerDown = this._pointerDown.bind(this);
                this._pointerMove = this._pointerMove.bind(this);
                this._pointerUp = this._pointerUp.bind(this);
                this.on = this.on.bind(this);
                this._emitEvent = this._emitEvent.bind(this);

                // CLASS REGISTRY
                this.constructor._annotations = this.constructor._annotations || []
                this.constructor._annotations.push(this)

                this.constructor.selectByID(this._id) // this operation will also trigger an update event

            }

            // CLASS METHODS
            static load(annotationDefs, svg) {
                // takes annotation definitions {type, metadata, geometry} and recreates a new annotation from it
                let annotation;
                for (let def of annotationDefs) {
                    annotation = new this({
                        svg,
                        type: def.type,
                        metadata: def.metadata,
                    })
                    annotation._history = [def.geometry];
                    annotation.update();
                }
            }

            static selectByID(id) {
                this._annotations.forEach(annotation => {
                    annotation._id === id
                        ? annotation._select()
                        : annotation._deselect()
                })
            }

            static deselect() {
                console.log("deselect")
                this._annotations?.find(annotation => annotation._selected)?._deselect()
            }

            static redrawAnnotations() {
                // this is called when the zoom factor changes and we need to redraw annotations at a new zoom level
                if (this._annotations) {
                    for (let annotation of this._annotations) {
                        annotation.draw()
                    }
                }
            }

            static deleteByID(id) {
                const annotation = this._annotations.find(a => a._id === id);
                annotation?.delete();
                this._annotations = this._annotations.filter(a => a._id !== id);
            }

            static exportRegistry(format) {
                const exports = []
                this._annotations?.forEach(annotation => {
                    exports.push({ ...annotation.export(format, {executeEvent: false}) })
                })
                return exports
            }

            static handleIsBeingDragged() {
                return !!this._annotations?.find(a => a._draggingHandle)
            }

            static restore(svg) {
                this._annotations?.forEach(annotation => {
                    annotation.svg = svg // update the svg reference to the current svg
                    annotation.update()
                })
            }

            static clearAll(){
                this._annotations?.forEach(annotation => {
                    this.deleteByID(annotation._id);
                })
            }

            // INSTANCE METHODS

            // Events
            on(eventName, callback) {
                // defines new events
                this._events[eventName] = callback;
            }

            _emitEvent(eventName, payload) {
                // emits existing events
                this._events[eventName]?.(payload)
            }


            update(source = "history") {
                if (source === "history") {
                    this.current = this.history.slice(-1)[0] ?? []
                }

                // if source === current, then we just use the current points
                this.draw()
                this._emitEvent("update", { annotation: this })
            }

            removePoint(index) {

                const currentPoints = JSON.parse(JSON.stringify(this.history.slice(-1)[0]));
                currentPoints.splice(index, 1)
                this.history.push(currentPoints);
                this.update()
            }

            movePoint(index, newPoint, commit = true) {
                const currentPoints = JSON.parse(JSON.stringify(this.history.slice(-1)[0]));
                currentPoints[index] = newPoint;
                if (commit) {
                    this.history.push(currentPoints);
                } else {
                    this.current = currentPoints
                }

                this.update(commit ? "history" : "current")
            }

            draw() {

                // Clear any previous annotation before drawing an updated one
                clearEntitiesByID(this._id)

                // only draw handles if the annotation is selected or if the annotation type is point or multipoint

                switch (this.type) {
                    case "polyline":
                        drawPolyline(this, this.metadata?.style || {})
                        break;
                    case "polygon":
                        drawPolygon(this, this.metadata?.style || {})
                        break;
                    case "rectangle":
                        drawRectangle(this, this.metadata?.style || {})
                        break;
                    case "circle":
                        drawCircle(this, this.metadata?.style || {})
                        break;
                }

                // draw handle at the end so they are on top
                if (this._selected || ["point", "multipoint"].includes(this.type)) {
                    drawHandles(this, this.metadata?.style || {})
                }
            }

            undo() {
                const prev = this.history.pop()
                prev && this._redoHistory.push(prev)
                this.update()
            }

            redo() {
                const prevUndone = this._redoHistory.pop()
                prevUndone && this.history.push(prevUndone)
                this.update()
            }

            // UTILITY METHODS
            _pointerDidMove() {
                return (
                    this._pointerDownStart.x !== this._pointerLastKnownPosition.x
                    && this._pointerDownStart.y !== this._pointerLastKnownPosition.y
                )
            }


            // === Events ===
            _pointerDown(event) {

                if (event.path[0] !== this.svg) {
                    if (event.path[0]?.tagName === "circle") {
                        this._selectedPointIndex = event.path[0].dataset.index ?? null
                        this._draggingHandle = !!this._selectedPointIndex

                        // if user clicks on another annotation's point (point or multipoint), we deselect here to stop all further operations
                        if (this._id !== event.path[0].dataset.annotationid) {
                            this._deselect()
                        }
                    }
                    return
                }
                this._pointerIsDown = true
                const position = { x: event.offsetX, y: event.offsetY };
                this._pointerDownStart = position;
                this._pointerLastKnownPosition = position;
            }

            _pointerMove(event) {
                this._pointerLastKnownPosition = { x: event.offsetX, y: event.offsetY };

                if (this._selectedPointIndex) {
                    const updatedPoint = zoomed2Actual({ x: event.offsetX, y: event.offsetY }, this._getZoom() / 100)
                    // we don't want to commit the point move until the mouse comes up so we don't over-populate our history
                    this.movePoint(this._selectedPointIndex, updatedPoint, false)
                }
            }

            _pointerUp(event) {
                this._draggingHandle = false
                // If there is a selected point index, we move that point to the pointer-up location and commit it
                if (this._selectedPointIndex) {
                    const updatedPoint = zoomed2Actual({ x: event.offsetX, y: event.offsetY }, this._getZoom() / 100)
                    this.movePoint(this._selectedPointIndex, updatedPoint, true)
                    this._selectedPointIndex = null;
                    return
                }

                // if the pointer never registered as down (pointer down initiated over a child element instead)
                if (!this._pointerIsDown) return

                if (this._pointerDidMove()) return

                this._pointerIsDown = false

                // 1. Get the most recent points array from history so it can be updated
                let currentPoints = !!this.history.length
                    ? [...this.history.slice(-1)[0]]
                    : []


                // 2. Get and process the new point
                let point = { x: event.offsetX, y: event.offsetY }
                // transform point coordinates by the current zoom factor so they always appear as if zoom is at 100% (default)
                const zoomFactor = this._getZoom() / 100;
                point = zoomed2Actual(point, zoomFactor)

                if (this.type === "point") {
                    currentPoints = [point];
                }

                if (["multipoint", "polyline", "polygon"].includes(this.type)) {
                    currentPoints.push(point)
                }

                if (["rectangle", "circle"].includes(this.type)) {
                    if (currentPoints.length === 2) {
                        currentPoints[1] = point
                    }
                    if (currentPoints.length <= 1) {
                        currentPoints.push(point)
                    }
                }

                this.history.push(currentPoints)
                this._redoHistory = []

                this.update()
            }

            // === interaction methods ===


            _getZoom() {
                return parseInt(this.svg.dataset.zoom)
            }

            _select() {
                this._selected = true;
                this.svg.style.cursor = "cell"

                this.svg.addEventListener("pointerdown", this._pointerDown)
                this.svg.addEventListener("pointermove", this._pointerMove)
                this.svg.addEventListener("pointerup", this._pointerUp)

                this._selectedPointIndex = null;
                this.update()
                this._emitEvent("select", { annotation: this })
            }

            _deselect() {
                this._selected = false;
                this.svg.style.cursor = "auto"
                this.svg.removeEventListener("click", this._pointerDown)
                this.svg.removeEventListener("pointermove", this._pointerMove)
                this.svg.removeEventListener("pointerup", this._pointerUp)

                this._selectedPointIndex = null;
                this._draggingHandle = null;

                if (!["point", "multipoint"].includes(this.type)) {
                    clearHandles(this._id)
                }


                this._emitEvent("deselect", { annotation: this })
            }

            delete() {
                this._deselect();
                this._emitEvent("delete", { annotation: this })
                clearEntitiesByID(this._id)
            }

            export(format = "raw", options= {executeEvent: true}) {
                let geometry;

                switch (format) {
                    case "wkt":
                        geometry = annotation2WKT(this) 
                        break;                      
                    default:
                        geometry = { annotation: this, geometry: this.current }
                }
                const payload = {annotation: this, geometry}
                if("executeEvent" in options){                    
                    options.executeEvent && this._emitEvent("export", payload)
                } else {
                    this._emitEvent("export", payload)
                }
                return payload

            }
        }

        if (id) {
            window._imageAnnotationRegistry = window._imageAnnotationRegistry || {}
            window._imageAnnotationRegistry[id] = ImageAnnotation
            return { registry: window._imageAnnotationRegistry[id] }
        }
        return { registry: ImageAnnotation };


    }
}

