
import React, { useContext, useEffect, useState } from 'react'
import { calculateDistance } from '../../../js/mapHelpers';

// ========================== STATE ==========================
import { DataPrepContext, ExplorerContext } from '../../../state/explorer/explorerProvider'

// ========================== DZYNE COMPONENTS ==========================
import TabGroup from '../../dzyne_components/ui/buttons/TabGroup/TabGroup';
import AnnotationManager3D from '../../dzyne_components/map/3d/AnnotationManager3D/AnnotationManager3D'
import { usePage } from '../../../js/hooks';

const Mensuration = ({
    className
}) => {

    const page = usePage()
    const { state, setters } = useContext(page === "explorer" ? ExplorerContext : DataPrepContext);
    const [unit, setUnit] = useState("meters")
    const [distances, setDistances] = useState(null)
    const [totalDistance, setTotalDistance] = useState(null)

    // HELPERS
    const formatDistance = (distance, unit) => {
        switch (unit) {
            case "meters":
                return distance;
            case "kilometers":
                return distance / 1e3;
            case "miles":
                return distance / 1609;
            case "feet":
                return distance * 3.281;
        }
    }

    // EVENTS
    const handleUnitChange = tab => {
        setUnit(tab)
    }
    const handleAnnotationUpdate = ({ annotation }) => {
        const points = annotation.current;

        if (points.length > 1) {
            let distancesArr = [];
            for (let i = 0; i < points.length - 1; i++) {
                distancesArr.push(calculateDistance(points[i], points[i + 1]))
            }

            const total = distancesArr.reduce((a, b) => a + b)

            setDistances(distancesArr)
            setTotalDistance(total)
        } else {
            setDistances(null)
            setTotalDistance(null)
        }
    }

    const handleAnnotationDelete = () => {
        setDistances(null)
        setTotalDistance(null)
    }

    // RENDERERS
    const renderDistances = (distances, unit) => {
        return distances.map((distance, i) => {
            return (
                <li>
                    <h4 className="ribbon-subsection-text">
                        <em>
                            {formatDistance(distance, unit).toFixed(3)} {unit}
                        </em>
                    </h4>
                </li>
            )
        })
    }
    
    return (
        <div id="mensuration" className={`text-left overflow-hidden h-full flex flex-col ${className}`}>
            {
                state.viewer
                &&
                <AnnotationManager3D
                    viewer={state.viewer}
                    removeAnnotationsOnUnmount
                    maxAnnotations={1}
                    availableTypes={["polyline"]}
                    availableActions={["save", "delete", "undo", "redo"]}
                    iconStyle={{ height: "2rem", width: "2rem" }}
                    events={{
                        update: handleAnnotationUpdate,
                        delete: handleAnnotationDelete
                    }}
                />
            }
            {
                (distances && totalDistance)
                &&
                <>
                    <TabGroup
                        tabs={["meters", "kilometers", "feet", "miles"]}
                        defaultTab={unit}
                        forceTab={unit}
                        onClick={handleUnitChange}
                    />
                    <div className='pt-1'></div>

                    <div className="border-b overflow-auto flex-auto">
                        <ol className=''>
                            {renderDistances(distances, unit)}
                        </ol>
                    </div>
                    <h3><em>Total: {formatDistance(totalDistance, unit).toFixed(3)}</em></h3>
                </>
            }
            {
                (!distances || !totalDistance)
                &&
                <p className='italic pt-1'>Click the "polyline" to mark points for distance measurements</p>
            }
        </div>
    )
}

export default Mensuration