
import CantusFirmus from 'cantus-firmus';

import state from './state'
import setters from './setters'
import { methods, API } from './methods'


const user = new CantusFirmus(state)

user.addCustomSetters(setters)

user.addMethods(methods)
user.addNamespacedMethods({ API })



export const UserContext = user.context;
export const UserProvider = user.createProvider();

