import React, { useContext, useEffect } from 'react'
import { Redirect } from 'react-router'

// ========================= STATE =========================
import { UserContext } from '../../state/users/userProvider'

export default function Logout() {
    
    // STATE
    const {state: userState, setters: userSetters, API: userAPI} = useContext(UserContext)
    
    useEffect(() => {
        userAPI.logout()
    }, [])
    
    return userState.user   
        ? <div className="content-container"></div>
        : <Redirect to="/" />
}
