import { v4 as uuidv4 } from 'uuid';
import { faker } from "@faker-js/faker"
import _ from "lodash"

const getBodyTemplate = () => (
    {
        "created": "2021-12-24T15:07:51.652513",
        "last_modified": "2021-12-24T15:07:51.652513",
        "id": 1,
        "name": "TEST",
        "description": "",
        "bbox": [
            116.811718,
            40.054125,
            116.831017,
            40.061969
        ],
        "images": [],
        "score": 0.236,
        "country": "TEST",
        "nobID": "0589ea0d-9e87-485d-a5b0-4c1370f8f8e3"
    }
)


// ==================================== IMAGE TEMPLATE ====================================

const getImageTemplate = () => (
    {
        "catid": "ac9985083cfa9a3c5a56b95a63cc5302",
        "wms_layer": "images:ac9985083cfa9a3c5a56b95a63cc5302",
        "bbox": [
            116.8113772,
            40.0519335,
            116.8314006,
            40.0638998
        ],
        "timestamp": "2015-11-26T03:15:46+00:00",
        "band_colors": "1 - None, 2 - blue, 3 - green, 4 - None, 5 - red, 6 - None, 7 - None, 8 - None",
        "height": 517,
        "width": 853,
        "num_bands": 8,
        "niirs": 2.9,
        "country": "TEST",
        "sensor": "WV03",
        "gsd": 2.0955,
        "obliquity_angle": 26.138,
        "last_review": {
            "is_valid": true,
            "timestamp": "2021-08-14T17:43:42.131034",
            "comments": ""
        },
        "detection_layers": [
            {
                "run_id": "ec49fe1b-e429-4d11-b15f-715e3c87d7fe",
                "detector_name": "land_cover",
                "polygons": 289,
                "timestamp": "2021-12-24T15:21:08.154229",
                "bbox": [
                    116.811718,
                    40.054125,
                    116.831017,
                    40.061969
                ],
                "wms": {
                    "viewparams": {
                        "run_id": "ec49fe1b-e429-4d11-b15f-715e3c87d7fe",
                        "image_catid": "ac9985083cfa9a3c5a56b95a63cc5302"
                    },
                    "layer": "casi:detection_results"
                },
                "debug": {
                    "url": "http://192.168.35.174:4013/geoserver/wms?service=WMS&version=1.1.1&request=GetMap&transparent=true&format=application%2Fopenlayers&layers=images%3Aac9985083cfa9a3c5a56b95a63cc5302%2Ccasi%3Adetection_results&bbox=116.811718%2C40.054125%2C116.831017%2C40.061969&width=1280&height=1024&srs=EPSG%3A4326&viewparams=run_id%3Aec49fe1b-e429-4d11-b15f-715e3c87d7fe%3Bimage_catid%3Aac9985083cfa9a3c5a56b95a63cc5302"
                }
            }
        ],
        "coverage": null,
        "analysis": {
            "quality": 0.29,
            "validity": 0.19999999999999996,
            "stability": null
        }
    }
)

const SEASONAL_MAP = [
    0,
    0,
    1,
    1,
    1,
    2,
    2,
    2,
    3,
    3,
    3,
    0,
]

const AGGREGATE_WEIGHTING = {
    stability: 5,
    validity: 6,
    quality: 1
}

const createBbox = () => {
    const lat1 = faker.datatype.number({min: 29, max: 35, precision: 0.0000000000001})
    const lng1 = faker.datatype.number({min: 36, max: 52, precision: 0.0000000000001})
    return [
        lng1,
        lat1,
        lng1 + faker.datatype.number({min: 0.001, max: 0.01, precision: 0.001}),
        lat1 + faker.datatype.number({min: 0.001, max: 0.01, precision: 0.001}),
    ]
}

export const createDemoArea = ({
    name = "",
    numImages = 5,
    dateRange = ["2017-01-01", new Date().toDateString()],
    reviewProbability = 0.05,
    seasonalInstability = false,
    instabilityMomentPercent = 0,
    initialInstabilityHit = 0.25,
    stabilityDecayRate = 0.05,
    niirsRange=[5,9]
}) => {
    const body = getBodyTemplate()
    body.name = name
    body.id = 101
    body.nobID = uuidv4()
    body.bbox = createBbox()

    let template,
        catid,
        runid,
        timestamp,
        niirs
    for (let i = 0; i < numImages; i++) {
        template = getImageTemplate()
        catid = uuidv4()
        runid = uuidv4()
        timestamp = faker.date.between(...dateRange).toISOString()
        niirs = faker.datatype.number({ min: niirsRange?.[0] ?? 5, max: niirsRange?.[1] ?? 9, precision: 0.1 })

        template.catid = catid
        template.wms_layer = "images:" + catid;
        template.timestamp = timestamp
        template.niirs = niirs
        template.last_review.is_valid = Math.random() > 1 - reviewProbability

        template.analysis.quality = scaleValue(niirs, [0, 9], [0, 1])
        template.analysis.stability = 0.75

        body.images.push(template)
    }

    // sort by time
    body.images = body.images.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())

    // setup anchor image
    const anchorImage = body.images[0]
    anchorImage.analysis.stability = 1
    anchorImage.analysis.validity = 1
    // const anchorSeason = SEASONAL_MAP[new Date(anchorImage.timestamp).getMonth()]
    const anchorMonth = new Date(anchorImage.timestamp).getMonth()

    let imagesSinceLastReview = 0,
        image,
        prevStability,
        curStability,
        stabilityDiff,
        wasPreviouslyStable = true,
        curMonth,
        minimalMonthDiff
    for (let i = 1; i < body.images.length; i++) {
        image = body.images[i]
        // validity calculation
        if (image.last_review.is_valid) {
            imagesSinceLastReview = 0
            image.analysis.validity = 1
        } else {
            imagesSinceLastReview += 1
            image.analysis.validity = 1 - (imagesSinceLastReview / body.images.length)
            // image.analysis.validity = 1-(imagesSinceLastReview / i)
        }

        // stability generation
        prevStability = body.images[i - 1].analysis.stability
        if (seasonalInstability) { // seasonal scenario
            curMonth = new Date(image.timestamp).getMonth()
            minimalMonthDiff = scaleValue(Math.min(curMonth - anchorMonth, anchorMonth + 12 - curMonth), [0, 6], [0, 1])
            stabilityDiff = faker.datatype.number({ min: 0, max: 0.05, precision: 0.0001 }) //* minimalMonthDiff
            curStability = minimalMonthDiff <= 0.5 ? prevStability + stabilityDiff :  prevStability - stabilityDiff
            image.analysis.stability = Math.min(curStability, 0.95)
        } else { // non seasonal scenario
            if (i / body.images.length >= instabilityMomentPercent) {
                if (wasPreviouslyStable) {
                    curStability = prevStability - initialInstabilityHit
                    image.analysis.stability = Math.max(curStability, faker.datatype.number({ min: 0, max: 0.05, precision: 0.0001 }))
                } else {
                    stabilityDiff = faker.datatype.number({ min: 0, max: stabilityDecayRate, precision: 0.0001 })
                    curStability = prevStability - stabilityDiff
                    image.analysis.stability = Math.max(curStability, faker.datatype.number({ min: 0, max: 0.05, precision: 0.0001 }))
                }
                wasPreviouslyStable = false
            } else {
                // currently stable
                stabilityDiff = faker.datatype.number({ min: 0, max: stabilityDecayRate, precision: 0.0001 })
                curStability = prevStability + (Math.random() > 0.5 ? stabilityDiff : stabilityDiff * -1)
                image.analysis.stability = Math.min(curStability, 0.95)
            }
        }


        // calculate aggregate score for the image
        let compositeValues = []
        for (let [metric, value] of Object.entries(image.analysis)) {
            for (let i = 0; i < AGGREGATE_WEIGHTING[metric]; i++) {
                compositeValues.push(value)
            }
        }
        image.analysis.aggregate_score = _.mean(compositeValues)
    }

    // calculate displayed score
    const lastImage = body.images.at(-1)
    body.score = lastImage.analysis.aggregate_score
    return body

}

const scaleValue = (n, rng1, rng2) => {
    return (n - rng1[0]) * (rng2[1] - rng2[0]) / (rng1[1] - rng1[0]) + rng2[0]
}