import React, { useContext } from 'react'
import Grid from '../dzyne_components/layout/Grid/Grid'


import "./dashboard.css"
import SceneFilter from './SceneFilter'
import { ChangeDetectionContext } from '../../state/changeDetection/changeDetectionProvider'
import SceneList from './SceneList'
import GlobalChart from './GlobalChart'
import SelectedSceneChart from './SelectedSceneChart'
import { useEffect } from 'react'
import ChartContainer from './ChartContainer'



export default function DashboardContainer() {

    const { state, methods, changeDetectionAPI } = useContext(ChangeDetectionContext)


    useEffect(() => {
        // changeDetectionAPI.getAllChangeDetections();
        changeDetectionAPI.getAllAreas();
    }, [])


    return (

        <Grid
            id="dashboard-container"
            className="content-container"
            cols={2}
            colSpacing={"3fr 9fr"}
            rowSpacing={"2fr 10fr"}
            areas={[
                ["filter", "filter"],
                ["scene-list", "chart"]
            ]}
            style={{ gridGap: "1rem" }}
        >
            <SceneFilter />
            <SceneList />
            <ChartContainer />

        </Grid>


    )
}
