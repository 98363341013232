import React from 'react';


const FormElementWrapper = ({
    children,
    elementDisplay = "block",
    labels = [],
    onChange=(elementValues, e) => {},
    labelStyle = {},
    elementStyles = {},
    className="",
}) => {


    // EVENTS
    const handleChange = e => {
        
        const elementValues = {}
        
        const traverse = element => {
            if(element.hasOwnProperty("value")){
                if(element.id) {
                    elementValues[element.id] = element.value;
                }
                return;
            }
            
            for(let el of element.children){
                traverse(el)
            }
            
        }
        
        traverse(e.currentTarget);
        onChange(elementValues, e);
    }

    // RENDERERS
    const wrapChildren = children => {

        // coerce to an array if not an array
        children = Array.isArray(children) ? children : [children]

        let label,
            style;

        return children.map((child, i) => {
            label = labels[i]

            // parse styles for selected object
            style = Array.isArray(elementStyles) ? elementStyles[i] : elementStyles
            style = !style ? {} : style

            // clone the child elements so we can pass styles to them on an individual level
            child = React.cloneElement(child, { ...child.props, style })

            return (
                <div style={{ display: elementDisplay }} key={i}>
                    {label && <><label htmlFor={child.props.id} key={`label-${i}`} style={labelStyle}>{label}</label><br /></>}

                    {child}
                </div>
            )
        })
    }

    return (
        <div className={`form-element-wrapper ${className}`} onChange={handleChange}>
            {wrapChildren(children)}
        </div>
    )

}



export default FormElementWrapper;