import React, { useState, useContext, useEffect } from 'react'
import { BarChart, ResponsiveContainer, XAxis, Bar, YAxis, Cell, Tooltip, Label } from 'recharts';

// ======================= STATE =======================
import { ChangeDetectionContext } from '../../state/changeDetection/changeDetectionProvider'
import setters from '../../state/changeDetection/setters';

// ======================= DZYNE COMPONENTS =======================
import useWindowResize from '../dzyne_components/hooks/useWindowResize';
import { formatDataForChart, percent2Color } from './helpers/dashboardHelpers';

export default function GlobalChart() {
    
    const {state, setters} = useContext(ChangeDetectionContext)
    const [chartData, setChartData] = useState([])

    // EVENTS
    const handleBarClick = data => {
        const val = parseInt(data?.activeLabel?.split("-")[1])
        !isNaN(val) && setters.setFilters_stabilityScore(val)
    }

    // EFFECT
    useEffect(() => {
        setChartData(formatDataForChart(state.filteredScenes))
    }, [state.filteredScenes])

    return (
        <div className="dashboard-chart">
            {/* <h3 className="dashboard-panel-header">Percent Change Distribution</h3> */}
            <ResponsiveContainer >
                <BarChart data={chartData} onClick={handleBarClick} >
                    <XAxis dataKey="range" stroke="var(--neutral16)">
                        <Label value="STABLE" stroke={percent2Color(100)} position={"insideBottomRight"} offset={-3}/>
                        {/* <Label className='text-xl' value="% Stability" stroke={"var(--neutral16)"} position={"insideBottom"} offset={-10}/> */}
                        <Label value="UNSTABLE" stroke={percent2Color(0)} position={"insideBottomLeft"} offset={-3}/>
                    </XAxis>
                    <YAxis stroke="var(--neutral16)" interval={1}>
                        <Label className='text-2xl' value="Scene Count" stroke={"var(--neutral16)"} angle={-90} position={"insideLeft"}/>
                    </YAxis>
                    <Tooltip />
                    <Bar dataKey="count" name={"# Scenes"}>
                        {chartData.map((cd, i) => <Cell key={i} fill={cd.color}/>)}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
            <h1 className='text-center text-2xl font-bold' style={{color: "var(--neutral16"}}>% Stability</h1>
        </div>
    )
}

