import React, { useState, useRef, useEffect } from 'react'
import useWindowResize from '../../../hooks/useWindowResize';
import * as mgrs from 'mgrs';

import "./MapCoordinateBox3D.css"

const Cesium = require("cesium");

// ================================= CONFIG =================================
const MODIFIERS = {
    ctrlKey: Cesium.KeyboardEventModifier.CTRL,
    shiftKey: Cesium.KeyboardEventModifier.SHIFT,
    altKey: Cesium.KeyboardEventModifier.ALT,

}


export default function MapCoordinateBox3D({
    viewer,
    activationKey = "ctrlKey",
    positioning = "cursor",
    format = "table",
    style = {}
}) {

    // STATE
    const [visible, setVisible] = useState((activationKey !== "none" && positioning !== "cursor") || (activationKey === "none" && positioning !== "cursor"));
    const [position, setPosition] = useState({ left: 0, top: 0 })
    const size = useWindowResize({ resize: true, scroll: true })
    const [handler, setHandler] = useState(new Cesium.ScreenSpaceEventHandler(viewer.canvas))
    const [point, setPoint] = useState({ lat: 0, lng: 0 })

    const boxRef = useRef(null)

    // HELPERS
    const positionBox = (x, y) => setPosition({ x, y })

    const getMapBounds = () => {
        const canvas = viewer.canvas;
        return canvas.getBoundingClientRect()
    }

    const getElementBounds = () => {
        const element = boxRef.current;
        return element.getBoundingClientRect()
    }

    const configureStaticPosition = () => {
        const mapBounds = getMapBounds();
        const elementBounds = getElementBounds();
        switch (positioning) {
            case "static-top-left":
                positionBox(mapBounds.left + 5, mapBounds.top + 5)
                break;
            case "static-top-right":
                positionBox(mapBounds.right - elementBounds.width - 5, mapBounds.top + 5)
                break;
            case "static-bottom-right":
                positionBox(mapBounds.right - elementBounds.width - 5, mapBounds.bottom - elementBounds.height - 5)
                break;
            case "static-bottom-left":
                positionBox(mapBounds.left + 5, mapBounds.bottom - elementBounds.height - 5)
                break;
        }
    }



    // EVENTS

    const handleMouseMove = (mapBounds, elementBounds) => e => {

        let { x, y } = e.endPosition;
        const { width, height } = elementBounds;

        // determine latlng location of mouse
        const ellipsoid = viewer.scene.globe.ellipsoid;
        const cartesian = viewer.camera.pickEllipsoid(new Cesium.Cartesian3(x, y), ellipsoid)
        if (cartesian) {
            const cartographic = ellipsoid.cartesianToCartographic(cartesian)
            const lat = Cesium.Math.toDegrees(cartographic.latitude)
            const lng = Cesium.Math.toDegrees(cartographic.longitude)

            setPoint({ lat, lng })
        }

        if (x < mapBounds.left) x = mapBounds.left + 5;
        if (x + width > mapBounds.right) x = mapBounds.right - width - 5;
        if (y < mapBounds.top) y = mapBounds.top + 5;
        if (y + height > mapBounds.bottom) y = mapBounds.bottom - height - 5;

        if (positioning === "cursor") {
            setPosition({ x, y })
        } else {
            configureStaticPosition()
        }
    }

    // RENDERERS
    const renderMgrs = point => {
        try {
            return mgrs.forward([point.lng, point.lat]);
        } catch(err) {
            return "--"
        }

    }


    // ON LOAD
    // set event listener on canvas container
    useEffect(() => {
        const handleMouseMoveInCanvas = e => {
            activationKey !== "none" && setVisible(e[activationKey]);
            (!visible && activationKey === "none") && setVisible(true)
        }

        viewer.canvas.addEventListener("mousemove", handleMouseMoveInCanvas);

        return () => {
            viewer.canvas.removeEventListener("mousemove", handleMouseMoveInCanvas);
        }

    }, [])

    useEffect(() => {

        const element = boxRef.current;
        const canvas = viewer.canvas;
        const mapBounds = canvas.getBoundingClientRect()
        const elementBounds = element.getBoundingClientRect()

        positioning !== "cursor" && configureStaticPosition();

        // if no mouse move event has been placed on the handler yet
        if (!handler.getInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE)) {
            handler.setInputAction(
                handleMouseMove(mapBounds, elementBounds),
                Cesium.ScreenSpaceEventType.MOUSE_MOVE,
                activationKey !== "none" ? MODIFIERS[activationKey] : null
            )
        }


        return () => {

        }

    }, [size])

    return (
        <div
            id="map-coordinate-box-3d"
            ref={boxRef}
            style={{
                display: visible ? "block" : "none",
                left: position.x,
                top: position.y,
                ...style
            }}
        >
            {
                format === "column"
                &&
                <>
                    {point.lat}
                    <br />
                    {point.lng}
                </>
            }

            {
                format === "row" && <>{point.lat} {point.lng}</>
            }

            {
                format === "table"
                &&
                <table>
                    <tbody>
                        <tr>
                            <td>Lat</td>
                            <td>{point.lat}</td>
                        </tr>
                        <tr>
                            <td>Lng</td>
                            <td>{point.lng}</td>
                        </tr>
                    </tbody>
                </table>
            }

            {
                format === "mgrs"
                &&
                <h3 className='text-left'>MGRS: {renderMgrs(point)}</h3>
            }
        </div>
    )
}
