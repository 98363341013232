import React, { useContext } from 'react'
// =========================== STATE ===========================
import { ExplorerContext } from '../../state/explorer/explorerProvider'
import Grid from '../dzyne_components/layout/Grid/Grid'
import AOIFilter from './AOIFilter'

// =========================== CHILDREN ===========================
import FilterQueries from './FilterQueries'

export default function LayerFilter() {

   

    return (
        // <div className='grid grid-cols-2 h-full gap-2'></div>
        <Grid className="rendered-ribbon-content" cols={2} style={{height: "100%", gridGap: "0.5rem"}}>
            <FilterQueries />
            <AOIFilter/>
        </Grid>
    )
}
