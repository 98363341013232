import React, { useState, useContext, useEffect } from 'react'

// ============================== STATE ==============================
import { ExplorerContext, DataPrepContext } from '../../state/explorer/explorerProvider';

// ============================== DZYNE COMPONENTS ==============================
import AnnotationManager3D from '../dzyne_components/map/3d/AnnotationManager3D/AnnotationManager3D'

// ============================== HOOKS ==============================
import { usePage } from '../../js/hooks'

export default function AOIFilter() {

    // STATE
    const page = usePage()
    const { state, setters, methods } = useContext(page === "explorer" ? ExplorerContext : DataPrepContext);
    const [aoiType, setAoiType] = useState("none")
    const [selectedAnnotation, setSelectedAnnotation] = useState(null)
    const [annotationExists, setAnnotationExists] = useState(false)

    // EVENTS
    const handleChange = async e => {
        setAoiType(e.target.value)
        await setters.adjustLayerParameter(state.selectedLayer.id, "aoi", { type: e.target.value })

        // handle setup of a dynamic filtering interval
        if (e.target.value === "dynamic") {
            methods.pollViewGeometry(state.selectedLayer.id)
        }
    }

    // Annotation Events
    const handleAnnotationUpdate = ({annotation, registry}) => {
        setSelectedAnnotation(annotation)
        let geometry;
        switch (annotation.type) {
            case "polygon":
                if (annotation?.current?.length > 2) {
                    geometry = annotation.exportGeometry({output: "wkt"})

                }
                break;
            case "rectangle":
                if (annotation?.current?.length === 2) {
                    geometry = annotation.exportGeometry({output: "wkt"})
                }
                break;
        }

        if(geometry){
            console.log({geometry})
            methods.applyUserGeometry(state.selectedLayer.id, geometry.geometry)
        }

        setAnnotationExists(!!registry.length)

    }

    const handleAnnotationDelete = () => {
        methods.applyUserGeometry(state.selectedLayer.id, null)
    }

    const handleAnnotationSelect = ({annotation}) => {
        console.log("SELECTED", annotation)
        setSelectedAnnotation(annotation)
    }
    
    const handleAnnotationDeselect = ({annotation}) => {
        console.log("DESELECTED", annotation)
        setSelectedAnnotation(null)
    }


    // ON LOAD
    useEffect(() => {

        setAoiType(state.selectedLayer.aoi?.type || "none")

    }, [])

    return (
        <div className="ribbon-subsection">
            <h3 className="text-lg">Area of Interest</h3>

            <select onChange={handleChange} value={aoiType}>
                <option value="none">No AOI</option>
                <option value="dynamic">Dynamic to View</option>
                <option value="drawn">User Specified</option>
            </select>
            <hr className='mt-2' />

            {
                aoiType === "none"
                &&
                <h4 className="ribbon-subsection-text"><em>No AOI is specified. Layer is unfiltered by geometry.</em></h4>
            }

            {
                aoiType === "dynamic"
                &&
                <h4 className="ribbon-subsection-text"><em>Layer filtered based on the current map view</em></h4>
            }

            {
                aoiType === "drawn"
                &&
                <AnnotationManager3D
                    viewer={state.viewer}
                    annotationRegistryID={"AOI"}
                    restoreFromRegistry
                    autoSelectIndex={0}
                    availableTypes={["polygon", "rectangle"]}
                    availableActions={["save", "delete", "undo", "redo"]}
                    maxAnnotations={1}
                    defaultMetadata={{
                        fill: null
                    }}
                    events={{
                        update: handleAnnotationUpdate,
                        delete: handleAnnotationDelete,
                        select: handleAnnotationSelect,
                        deselect: handleAnnotationDeselect,
                    }}
                />
            }
            {
                !!selectedAnnotation
                &&
                <h4 className="ribbon-subsection-text"><em>Define your AOI bounds and click the "Save" icon to commit your AOI. You can update these bounds as needed</em></h4>
            }
            {
                (annotationExists && !selectedAnnotation)
                &&
                <h4 className="ribbon-subsection-text"><em>AOI bounds have been specified. Click the bounds of your AOI to select, edit, or delete the annotation.</em></h4>
            }
        </div>
    )
}
