import React from 'react'
import NavArrow from '../NavArrow/NavArrow';


export default function NavArrowGroup({
    className = "",
    labels = [],
    onClick = (label, event) => { },

    scale = "1.5rem",
    spacing = 4,
    textColor = "#000000",
    backgroundColor = "#CFCFCF",

    bodyStyle = {},
    containerStyle = {},
    activeArrowStyle = {}
}) {

    const handleClick = label => e => {
        onClick(label, e)
    }

    const renderArrows = labels => {
        return labels.map((label, i) => {
            return <NavArrow
                key={`${i}-${label}`}
                onClick={handleClick(label)}
                scale={scale}
                spacing={spacing}
                textColor={textColor}
                backgroundColor={backgroundColor}
                bodyStyle={{...bodyStyle, ...(i === labels.length - 1 ? activeArrowStyle : {})}}
            >
                {label}
            </NavArrow>
        })
    }
    return (
        <div className={`nav-arrow-group ${className}`} style={containerStyle}>
            {renderArrows(labels)}
        </div>
    )
}


