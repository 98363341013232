export const formatNumberString = num => {
    return typeof num === "number"
        ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : null
}

export const imageLayerIsAdded = (layers, imageCatID) => {
    return layers.map(l => l.layerLocation).includes(`images:${imageCatID}`)
}

export const downloadJSON = (data, filename = "download") => {

    const blob = new Blob([JSON.stringify(data, null, 2)], { type: "text/plain;charset=utf-8" })

    const el = document.createElement("a");
    el.setAttribute("download", filename);
    el.setAttribute("href", URL.createObjectURL(blob));

    el.style.display = "none";
    document.body.appendChild(el);
    el.click()

    document.body.removeChild(el);
}