import axios from "axios";
import { calcSceneZoomFromBbox, createLinkLayer } from "../../components/dashboard/helpers/dashboardHelpers";
import { generateDummyData } from "./dummy_data_helpers";
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash'

import config from '../../config.json';
import { createDemoArea } from "./_demoAreaTemplate";

const CONTROLLER_PROTOCOL = process.env?.REACT_APP_CONTROLLER_PROTOCOL || window._env_?.controllerProtocol || config.controllerProtocol || config.defaultProtocol || "http"
const CONTROLLER_HOST = process.env?.REACT_APP_CONTROLLER_HOST || window._env_?.controllerHost || config.controllerHost || window.location.hostname
const CONTROLLER_PORT = process.env?.REACT_APP_CONTROLLER_PORT || window._env_?.controllerHostPort || config.controllerPort || 4011

const CONTROLLER_BASE = `${CONTROLLER_PROTOCOL}://${CONTROLLER_HOST}:${CONTROLLER_PORT}/api/v4`

const GEOSERVER_HOST = process.env?.REACT_APP_GEOSERVER_HOST || window._env_?.geoserverHost || config.baseHost || window.location.hostname;
const GEOSERVER_PORT = process.env?.REACT_APP_GEOSERVER_PORT || window._env_?.geoserverPort || config.geoserverPort || 4013;

const methods = {
    generateExplorerLink() {
        const baseURL = `${window.location.origin}/explorer?shared=`

        let layers = this.state.selectedScene.images
            .map((image, i, a) => createLinkLayer({
                image,
                index: i,
                ip: GEOSERVER_HOST,
                port: GEOSERVER_PORT,
                isActive: i === 0 || i === a.length - 1
            }))

        layers.reverse(); // ensures that newest image is on top
        layers = _.flattenDeep(layers)
        const zoomParam = calcSceneZoomFromBbox(this.state.selectedScene.bbox, 10_000)

        const link = baseURL + JSON.stringify(layers) + `&zoom=${JSON.stringify(zoomParam)}`;

        window.open(link, "_blank");


    }
}
export default methods;

export const changeDetectionAPI = {
    getAllChangeDetections() {

        axios.get(`${CONTROLLER_BASE}/engine-tasks/changes`)
            .then(response => {
                Promise.all(response.data.map(async scene => await this.changeDetectionAPI.getChangeDetection(scene.id)))
                    .then(scenes => {
                        // const dummyData = Array.from({ length: 99 }) // for testing only
                        //     .fill()
                        //     .map(generateDummyData)
                        // scenes = [...scenes.map(generateDummyData), ...dummyData]
                        scenes = [...scenes.map(generateDummyData)]
                        scenes = scenes.filter(scene => !!scene)
                        this.setters.updateScenes(scenes)
                    })
            })
            .catch(err => {
                console.log(err)
            })
    },

    getChangeDetection(changeDetectionID) {
        return new Promise(resolve => {
            axios.get(`${CONTROLLER_BASE}/engine-tasks/changes/${changeDetectionID}?units=percent`)
                .then(response => {
                    // console.log(response.data)
                    resolve(response.data)
                })
                .catch(err => {
                    console.log(err);
                    resolve(null)
                })
        })
    },

    getAllAreas() {
        const areas = [
            createDemoArea({ name: "Small Sample", numImages: 10, instabilityMomentPercent: 1, reviewProbability: 0.0, stabilityDecayRate: 0.005, niirsRange: [5, 9], dateRange:["2022-01-01", new Date().toDateString()] }), // never unstable
            createDemoArea({ name: "Generally Stable", numImages: 100, instabilityMomentPercent: 1, reviewProbability: 0.05, stabilityDecayRate: 0.005 }), // never unstable
            createDemoArea({ name: "Minor Instability", numImages: 100, reviewProbability: 0.01, instabilityMomentPercent: 0, initialInstabilityHit: 0.01, stabilityDecayRate: 0.005, niirsRange:[3,9] }),
            createDemoArea({ name: "Unstable", numImages: 100, reviewProbability: 0, instabilityMomentPercent: 0.5, initialInstabilityHit: 0.7 }), // unstable after a specific moment
            createDemoArea({ name: "Recently Unstable", numImages: 100, reviewProbability: 0, instabilityMomentPercent: 0.95, initialInstabilityHit: 0.7 }), // unstable after a specific moment
            // createDemoArea({name: "Seasonally Unstable", numImages: 100, reviewProbability: 0, instabilityMomentPercent: 0.5, initialInstabilityHit: 0.7, seasonalInstability: true}), // unstable after a specific moment

        ]
        this.setters.setScenes(areas)
        // return new Promise(resolve => {
        //     axios.get(`${CONTROLLER_BASE}/areas`)
        //         .then(async response => {
        //             let areas = await Promise.all(response.data.map(area => this.changeDetectionAPI.getArea(area.id)));
        //             areas = areas.filter(a => a) // in case any area request failed this will remove the null value before setting to state
        //             areas = areas.map(a => ({...a, nobID: uuidv4()})); // only for testing purposes
        //             // console.log(areas)
        //             debugger
        //             this.setters.setScenes(areas)
        //         })
        // })
    },

    getArea(areaID) {
        return new Promise(resolve => {
            axios.get(`${CONTROLLER_BASE}/areas/${areaID}`)
                .then(response => {
                    let area = response.data;
                    area.score = _.mean(area.images // derives a generalized score from the analysis values of each image
                        .map(image => Object.values(image.analysis).filter(val => typeof val === "number"))
                        .map(analysis => _.mean(analysis)))

                    area.country = area.images.map(i => i.country)[0] // currently taking just the first country. TODO: get most prevelent country

                    resolve(area)
                })
                .catch(err => {
                    resolve(null)
                })
        })
    }
}
