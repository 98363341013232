import config from '../config.json';
const CONTROLLER_PROTOCOL = process.env?.REACT_APP_CONTROLLER_PROTOCOL || window._env_?.controllerProtocol || config.controllerProtocol || config.defaultProtocol || "http"
const CONTROLLER_HOST = process.env?.REACT_APP_CONTROLLER_HOST || window._env_?.controllerHost || config.controllerHost || window.location.hostname
const CONTROLLER_PORT = process.env?.REACT_APP_CONTROLLER_PORT || window._env_?.controllerPort || config.controllerPort || 4011
const CONTROLLER_BASE = `${CONTROLLER_PROTOCOL}://${CONTROLLER_HOST}:${CONTROLLER_PORT}/api/v4/` 

const GEOSERVER_PROTOCOL = process.env.REACT_APP_GEOSERVER_PROTOCOL || window._env_?.geoserverProtocol || config.geoserverProtocol || config.defaultProtocol || "http";

export function authorize(username, password, headers) {

    headers = headers || new Headers()
    // headers.append("Authorization", `Basic ${btoa(username + ":" + password)}`)
    if(window[window._geoserverTokenAccessor]){
        headers.append("Authorization", `Basic ${window[window._geoserverTokenAccessor]}`);
    };
    return headers

}

export const tokenAuthorization = (headers) => {
    headers = headers ?? new Headers();
    const token = window[window._tokenAccessor] ?? localStorage.CASI_TOKEN;
    
    headers.append("Authorization", `Bearer ${token}`)
    return headers;
}

export const saveTokenToLocalStorage = (token) => {
    localStorage.setItem("CASI_TOKEN", token);
}

export const removeTokenFromLocalStorage = () => {
    localStorage.removeItem("CASI_TOKEN");
}


export function formatQueryString(url, params) {
    const esc = encodeURIComponent;
    url = url[url.length - 1] === "?" ? url : url + "?"
    return url + Object.keys(params)
        .map(k => esc(k) + '=' + esc(params[k]))
        .join('&');
}


// GEOSERVER THUMBNAILS
export const feature2ThumbnailSrc = (layer, feature, options = { size: 128 }) => {
    const params = {
        service: "WMS",
        version: "1.1.0",
        request: "GetMap",
        layers: `images:${feature.properties.catid}`,
        // bbox: feature.properties.bbox,
        bbox: feature?.bbox?.join(","),
        width: options.size || 128,
        height: options.size || 128,
        srs: "EPSG:4326",
        format: "image/jpeg"
    }

    const baseURL = `${GEOSERVER_PROTOCOL}://${layer.ip}:${layer.port}/geoserver/wms`

    return formatQueryString(baseURL, params)
}

export const feature2RawSrc = (layer, feature) => {
    
    const dimensions = feature.properties?.dimensions.match(/\d+/g)?.map(d => parseInt(d))
    
    const params = {
        service: "WMS",
        version: "1.1.0",
        request: "GetMap",
        layers: `images:${feature.properties.catid}`,
        // bbox: feature.properties.bbox,
        bbox: feature?.bbox?.join(","),
        width: dimensions?.[0] || 0,
        height: dimensions?.[1] || 0,
        srs: "EPSG:4326",
        format: "image/jpeg"
    }
    
    const baseURL = `http://${layer.ip}:${layer.port}/geoserver/wms`
    return formatQueryString(baseURL, params)
    
}


// CONTROLLER THUMBNAILS
export const feature2ProcessedThumbnail = (layer, feature) => {
    return feature.properties?.catid
        ? CONTROLLER_BASE + `images/${feature.properties?.catid}_thumb.jpg`
        : feature2ThumbnailSrc(layer, feature) // if above not found, retrieve thumbnail from geoserver
}