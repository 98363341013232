import { v4 as uuidv4 } from 'uuid';
import fCodes from './fCodes.json'

// ============================ CONSTANTS ============================
const SCENE_SCHEMA = {
    created: "2021-10-14T00:27:51.107910",
    last_modified: "2021-10-14T00:27:51.107910",
    id: 1,
    nobID: "",
    country: "",
    name: "",
    description: "Description here",
    task_type: "detection",
    details: {
        images: [
            "a8988a246d58b0e4f064a83f76ba8d9e"
        ],
        detectors: [],
        percent_change: 0,
    },
    coverage_list: [],
    coverage_units: "sq_km",
    start_time: "2021-10-13T21:07:09.012000",
    end_time: "2021-10-13T21:07:09.012000",
    bounds: "POLYGON ((-125 38.4, -121.8 38.4, -121.8 40.9, -125 40.9, -125 38.4))"
}

const COUNTRIES = ["DE", "SY", "IQ", "IR", "KP", "RU", "CN", "MX"]
const DETECTORS = ["roads", "buildings", "water", "crops", "grassland", "barren earth"]

// ============================ HELPER FUNCTIONS ============================
export const generateDummyData = (sceneSchema) => {
    const schema = JSON.parse(JSON.stringify(sceneSchema ?? SCENE_SCHEMA))
    const id = uuidv4()
    schema.name = schema.name || id
    schema.nobID = id

    schema.country = COUNTRIES[Math.floor(Math.random() * COUNTRIES.length)]

    schema.details.detectors = DETECTORS.sort(() => 0.5 - Math.random()).slice(0, Math.floor(Math.random() * (DETECTORS.length - 1 + 1)) + 1)
    schema.details.detectors = schema.details.detectors.map(d => ({ [d]: Math.random() }))

    if(!schema.coverage_list.length) return null
    
    schema.coverage_list = schema.coverage_list.length ? schema.coverage_list : generateCoverageItems(Math.random() * 10, Math.floor(Math.random() * 20 + 2));
    // const percents = Object.values(schema.details.detectors.map(d => Object.values(d))).flat()
    schema.details.class_change_percents = calcPercentChange(schema.coverage_list, schema)

    schema.details.avg_percent_change = Object.values(schema.details.class_change_percents).reduce((a, b) => a + b) / Object.values(schema.details.class_change_percents).length
    // console.log(schema.details.avg_percent_change)
    // schema
    // schema.details.avg_percent_change = percents.reduce((a, b) => a + b) / percents.length
    // console.log(schema)
    return schema
}


export const generateCoverageItems = (totalArea, numImages) => {
    // define basic template
    const itemTemplate = {
        image_timestamp: "2015-07-04T00:00:00+00:00",
        image_catid: "c887db0bbe6bac279f735cd75b4f8aaa",
        total: totalArea,
        classes: {}

    }

    // random num features and grab those features from the fCodes json
    const numFeatures = Math.floor(Math.random() * 10 + 1)
    const features = Array.from(new Set(Array.from({ length: numFeatures }, () => fCodes[Math.floor(Math.random() * fCodes.length)])))

    const items = []

    let curItem, coverages
    for (let i = 0; i < numImages; i++) {

        curItem = JSON.parse(JSON.stringify(itemTemplate))
        coverages = randomizePercentsCoverage(totalArea, features.length)
        features.forEach((feature, i) => {
            curItem.classes[feature.code] = coverages[i]
        })

        items.push(curItem)

    }

    return items
    // debugger



}

const randomizePercentsCoverage = (totalArea, numFeatures) => {
    let passed = false
    let percents;
    const margin = Math.random() * 5e-2
    while (!passed) {
        percents = Array.from({ length: numFeatures }, () => Math.random() > 0.5 ? (1 / numFeatures) + margin + (Math.random() * 2e-2) : (1 / numFeatures) - margin - (Math.random() * 2e-2))
        passed = percents.reduce((a, b) => a + b) <= 1
    }
    return percents.map(p => p * totalArea)
}


export const calcPercentChange = (coverages, schema) => {
    const anchor = coverages[0]

    const features = Object.keys(coverages[0].classes).reduce((acc, curr) => (acc[curr] = [], acc), {})
    let cov, chng
    for (let i = 1; i < coverages.length; i++) {
        cov = coverages[i]
        for (let feature in features) {
            chng = Math.abs(cov.classes[feature] - anchor.classes[feature])
            features[feature].push(isNaN(chng) ? 0 : chng)
        }
    }  
    
    for (let feature in features) {
        features[feature] = Math.abs((features[feature].reduce((a, b) => a + b) / features[feature].length) - 1)
    }
    
    return features

    // return Object.values(features).reduce((a, b) => a + b) / Object.values(features).length
}
