import React, { useContext, useState } from 'react'

// ================================ MODULES ================================
import {DateTime} from 'luxon';

// ================================ STATE ================================
import { DataPrepContext } from '../../../state/explorer/explorerProvider'
import { UserContext } from '../../../state/users/userProvider';

// ================================ DZYNE COMPONENTS ================================
import FormElementWrapper from '../../dzyne_components/ui/forms/FormElementWrapper/FormElementWrapper'
import FormCompletion from '../../dzyne_components/ui/forms/FormCompletion/FormCompletion'
import { formatDate } from '../../../js/sessionHelpers';
import InlineButton from '../../dzyne_components/ui/buttons/InlineButton/InlineButton';

// ================================ CONFIGURATION ================================

const GET_LAYERS_MESSAGE = "All layers that are currently added will be copied over to the new session"
const DONT_GET_LAYERS_MESSAGE = "The new session will initialize with no layers"

export default function SessionForm({
    session, 
    onClose
}) {

    // STATE
    const { state: userState } = useContext(UserContext)
    const { state: dpState, dataPrepAPI } = useContext(DataPrepContext);

    const [layersMessage, setLayersMessage] = useState(GET_LAYERS_MESSAGE)
    const [errorMessage, setErrorMessage] = useState(null)

    // EVENTS
    const handleSubmit = async e => {
        e.preventDefault();

        const userID = userState.user?.id;
        const name = document.querySelector("#session-name").value
        const description = document.querySelector("#session-description").value
        const getLayers = document.querySelector("#session-form-use-layers")?.checked

        let start = document.querySelector("#session-start").value 
        let end = document.querySelector("#session-end").value 
        
        start = start + "T00:00:00"
        end = end + "T23:59:59"

        const dtStart = DateTime.fromISO(start)
        const dtEnd = DateTime.fromISO(end)
        const dtCurrent = DateTime.fromMillis(Date.now())

        if(dtStart < dtEnd && dtEnd < dtCurrent){
            if(!!session){ // updated existing session
                session.session_type = "data-prep"
                session.name = name
                session.description = description
                session.start_time = start
                session.end_time = end

                const response = await dataPrepAPI.updateDataPrepSession(session)
                if(response.status === 200){
                    setErrorMessage(null)
                    onClose()
                } else {
                    setErrorMessage(`Status: ${response.status}: There was a problem processing your request`)
                }

            } else { // creating new session
                const data = {
                    session_type: "data-prep",
                    user_id: userID,
                    name,
                    description,
                    start_time: start,
                    end_time: end,
                    session_data: {layers: getLayers ? dpState.layers : []},
                    bounds: "POLYGON((-125 38.4,-121.8 38.4,-121.8 40.9,-125 40.9,-125 38.4))",
                    images: []
                }
                
                const response = await dataPrepAPI.createDataPrepSession(data)
                
                if(response.status === 201){
                    setErrorMessage(null)
                    onClose()
                } else {
                    setErrorMessage(`Status: ${response.status}: There was a problem processing your request`)
                }
            }

        } else {
            setErrorMessage("Start date must be before end date, and both must be before the current date")
        }
    }

    const handleMaxRangeClick = e => {
        e.preventDefault();
        e.stopPropagation()

        let startElement = document.querySelector("#session-start") 
        let endElement = document.querySelector("#session-end") 

        const startTime = DateTime.fromISO('1970-01-01').toFormat('yyyy-MM-dd')
        const endTime = DateTime.now().minus({days: 1}).toFormat('yyyy-MM-dd')
        
        startElement.value = startTime
        endElement.value = endTime

    }

    const handleGetLayersChange = e => {
        setLayersMessage(e.target.checked ? GET_LAYERS_MESSAGE : DONT_GET_LAYERS_MESSAGE)
    }


    return (
        <form className="w-1/2 text-neutral-3" onSubmit={handleSubmit}>

            {
                <h2 className="text-xl font-bold">
                    {session ? "Edit Data Prep Session" : "New Data Prep Session"}
                </h2>
            }

            <FormElementWrapper labels={["Name", "Description"]} elementStyles={{width: "98%", resize: "none"}}>
                <input id="session-name" type="text" defaultValue={session?.name ?? ""} required/>
                <textarea id="session-description" defaultValue={session?.description ?? ""} required></textarea>
            </FormElementWrapper>

            <FormElementWrapper labels={["Start Date", "End Date"]} elementDisplay={"inline-block"}>
                <input id="session-start" type="date" defaultValue={session ? formatDate(session.start_time) : ""} required />
                <input id="session-end" type="date" defaultValue={session ? formatDate(session.end_time) : ""} required />
                <InlineButton className="shadow-md" onClick={handleMaxRangeClick}>Max date range</InlineButton>
            </FormElementWrapper>

            {
                !session
                &&
                <>
                    <hr/>

                    <input id="session-form-use-layers" type="checkbox" defaultChecked={true} onChange={handleGetLayersChange}/>
                    <label htmlFor="session-form-use-layers">Use Current Layers</label>
                    <br/>
                    <sub><em>{layersMessage}</em></sub>
                </>
            }

            <FormCompletion submitName={session ? "Update" : "Create"} cancelButton onCancel={onClose}/>

            {errorMessage && <em className="form-error-message">{errorMessage}</em>}

        </form>
    )
}
