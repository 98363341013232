import React, { useContext, useEffect, useState } from 'react'

// ========================== STATE ==========================
import { DataPrepContext, ExplorerContext } from '../../../state/explorer/explorerProvider'

// ========================== DZYNE COMPONENTS ==========================
import Modal from '../../dzyne_components/ui/modals/Modal/Modal'
import FormCompletion from "../../dzyne_components/ui/forms/FormCompletion/FormCompletion"
import FormElementWrapper from '../../dzyne_components/ui/forms/FormElementWrapper/FormElementWrapper';
import AnnotationManager3D from '../../dzyne_components/map/3d/AnnotationManager3D/AnnotationManager3D'
import InlineButton from '../../dzyne_components/ui/buttons/InlineButton/InlineButton';
import { usePage } from '../../../js/hooks';

const Cesium = require("cesium");

const RegionAnnotator = () => {
    const page = usePage();
    const { state, setters, getters } = useContext(page === "explorer" ? ExplorerContext : DataPrepContext);
    const [showRegionSaveModal, setShowRegionSaveModal] = useState(false)
    const [selectedAnnotation, setSelectedAnnotation] = useState(null)
    const [regionName, setRegionName] = useState(null)
    const [regionsAreDisplayed, setRegionsAreDisplayed] = useState(true);

    // EVENTS
    const handleNameChange = e => {
        setRegionName(e.target.value)
    }

    const handleSubmit = e => {
        e.preventDefault()
    }

    const handleCreateRegion = (payload) => {
        setSelectedAnnotation(payload.annotation)
    }

    const handleRegionSubmit = e => {
        e.preventDefault();
        const name = document.querySelector("#region-name").value
        setters.appendOrUpdateRegion(selectedAnnotation, name)
        setSelectedAnnotation(null)
        setShowRegionSaveModal(false)
    }

    const handleDeleteRegion = (payload) => {
        setters.removeRegionFromSession(payload.annotation._id)
        setSelectedAnnotation(null)
        setRegionName(null)
    }

    const handleSelectRegion = (payload) => {
        setSelectedAnnotation(() => payload.annotation)
        const selectedSession = getters.getSelectedSession()
        const regions = selectedSession?.session_data?.regions
        const name = regions?.find(r => r.id === payload.annotation._id)?.name
        setRegionName(name)
    }

    const handleToggleRegions = () => {
        const registry = window._annotationRegistries?.regions
        if (!!registry) {
            const regions = registry.getAnnotations();
            for (let annotation of regions) {
                annotation.metadata = { ...annotation.metadata, material: Cesium.Color.CYAN.withAlpha(regionsAreDisplayed ? 0.0 : 0.3) }
                annotation._update()
            }
            setRegionsAreDisplayed(bool => !bool);
        }
    }

    // EFFECTS
    useEffect(() => {
        // determine if regions are already displayed or not
        const registry = window._annotationRegistries?.regions;
        if (!!registry) {
            const regions = registry.getAnnotations();
            setRegionsAreDisplayed(regions?.[0]?.metadata?.material?.alpha > 0)
        }
    }, [])

    useEffect(() => {
        if (state.selectedSession && selectedAnnotation) {
            setRegionName(state.selectedSession.session_data?.regions?.find(region => region.id === selectedAnnotation._id)?.name);
        }
    }, [selectedAnnotation])


    return (
        <>
            {
                showRegionSaveModal
                &&
                <Modal>
                    <form className='text-left' onSubmit={handleRegionSubmit}>
                        <FormElementWrapper labels={["Add or Confirm Region Name"]} >
                            <input id="region-name" className="px-1" value={regionName} onChange={handleNameChange} type="text" autoFocus required />
                        </FormElementWrapper>
                        <FormCompletion cancelButton onCancel={() => setShowRegionSaveModal(false)} submitName="Save" />
                    </form>
                </Modal>
            }
            <form className="text-left" onSubmit={handleSubmit}>
                {!state.selectedSession && "Select/Create a session to use this feature"}
                {
                    !!state.selectedSession
                    &&
                    <>
                        <InlineButton onClick={handleToggleRegions}>{regionsAreDisplayed ? "Hide" : "Show"} Regions</InlineButton>
                        <div>
                            <em>Select a region type</em>
                        </div>
                        {
                            !!state.viewer
                            &&
                            <AnnotationManager3D
                                viewer={state.viewer}
                                annotationRegistryID="regions"
                                removeAnnotationsOnUnmount={false}
                                restoreFromRegistry
                                initializationAnnotations={state.selectedSession?.session_data?.regions}
                                availableTypes={["rectangle", "polygon"]}
                                availableActions={["save", "delete", "undo", "redo"]}
                                defaultMetadata={{ material: Cesium.Color.CYAN.withAlpha(0.3) }}
                                // iconStyle={{ height: "2rem", width: "2rem" }}
                                events={{
                                    create: handleCreateRegion,
                                    save: () => setShowRegionSaveModal(true),
                                    delete: handleDeleteRegion,
                                    select: handleSelectRegion,
                                    // deselect: handleDeselectRegion,
                                }}
                            />
                        }
                    </>
                }

                <input id="region-submit-button" type="submit" style={{ display: "none" }} />

            </form>
        </>
    )
}

export default RegionAnnotator