import { useContext, useEffect, useState } from "react";

// ========================= MODUELS =========================
import { DateTime } from "luxon";

// ========================= STATE =========================
import { DataPrepContext } from "../../../state/explorer/explorerProvider";

// ========================= DZYNE COMPONENTS =========================
import Grid from "../../dzyne_components/layout/Grid/Grid";
import Center from "../../dzyne_components/layout/Center/Center";
import IconButton from "../../dzyne_components/ui/buttons/Iconbutton/IconButton";
import Modal from "../../dzyne_components/ui/modals/Modal/Modal";
import HintModal from "../../dzyne_components/ui/modals/HintModal/HintModal";

// ========================= ICONS =========================
import Save from "../../dzyne_components/assets/media/save.svg";
import Edit from "../../dzyne_components/assets/media/edit.svg";
import Delete from "../../dzyne_components/assets/media/trash.svg";
import Export from "../../dzyne_components/assets/media/export.svg";

// ========================= COMPONENTS =========================
import SessionForm from "./SessionForm";
import SubmitSessionJob from "./SubmitSessionJob";

// ========================= STYLING =========================
const ICON_STYLE = {
    transform: "scale(0.75)",
};

// ========================= CHILDREN COMPONENTS =========================
const Hint = ({ session }) => {
    return (
        <div className="m-0 p-0 whitespace-nowrap max-w-full overflow-ellipsis overflow-x-hidden">
            <h4 className="font-bold">{session.name}</h4>
            <hr />
            <h4 className="font-bold">Date Range</h4>
            <p>
                From:{" "}
                {DateTime.fromISO(session.start_time).toLocaleString(
                    DateTime.DATE_SHORT
                )}
            </p>
            <p>
                To:{" "}
                {DateTime.fromISO(session.end_time).toLocaleString(
                    DateTime.DATE_SHORT
                )}
            </p>
            <hr />
            <h4 className="font-bold">Description</h4>
            <p className="overflow-ellipsis overflow-x-hidden">
                {session.description}
            </p>
        </div>
    );
};

export default function SessionListing({ session, deleteDataPrepSession, showSessionDropdown, setShowSessionDropdown }) {
    const { state, setters, dataPrepAPI, methods } =
        useContext(DataPrepContext);
    const [isSelected, setIsSelected] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showSubmitForm, setShowSubmitForm] = useState(false);

    // EVENTS
    const handleListingClick = () => {
        if (state.selectedSession?.id === session.id) {
            setters.clearMap();
            methods.removeRegionAnnotations();
            setters.setSelectedSession(null);
        } else {
            setters.setSelectedSession(session);
            methods.removeRegionAnnotations();
            setShowSessionDropdown(false)
        }
        // console.log({showSessionDropdown})
        // showSessionDropdown && setShowSessionDropdown(false)

    };

    const handleSaveClick = (e) => {
        e.stopPropagation();
        dataPrepAPI.updateDataPrepSession(session);
    };

    const handleEditClick = (e) => {
        e.stopPropagation();
        setShowEditForm(true);
    };

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        deleteDataPrepSession(session.id);
    };

    const handleExportClick = (e) => {
        e.stopPropagation();
        setShowSubmitForm(true);
    };

    // ON LOAD
    useEffect(() => {
        setIsSelected(session.id === state.selectedSession?.id);
    }, [state.selectedSession]);

    return (
        <>
            {showEditForm && (
                <Modal>
                    <SessionForm
                        session={session}
                        onClose={() => setShowEditForm(false)}
                    />
                </Modal>
            )}
            {showSubmitForm && (
                <Modal closeButton onClose={() => setShowSubmitForm(false)} contentStyle={{minWidth: "33vw"}}>
                    <SubmitSessionJob
                        session={session}
                        setShowSubmitForm={setShowSubmitForm}
                    />
                </Modal>
            )}
            <div
                className={`p-1 font-bold cursor-pointer ${
                    isSelected
                        ? " bg-primary2 text-black"
                        : "bg-gray-200 text-gray-700"
                }`}
                onClick={handleListingClick}
            >
                <Grid cols={4} colSpacing={"50% auto auto auto auto"}>
                    <HintModal
                        content={<Hint session={session} />}
                        hoverTimer={500}
                        positioning="top-left"
                        closeButton
                        wrapperStyle={{ border: "none" }}
                        bodyStyle={{
                            backgroundColor: "var(--neutral2)",
                            borderRadius: "4px",
                            color: "var(--primaryAccent5)",
                            maxWidth: "10rem",
                        }}
                    >
                        <Center center="vertical">
                            <p className="overflow-ellipsis overflow-x-hidden whitespace-nowrap">
                                {session.name}{" "}
                            </p>
                        </Center>
                    </HintModal>

                    <IconButton
                        icon={Save}
                        alt="Save Session"
                        containerStyle={ICON_STYLE}
                        onClick={handleSaveClick}
                        disabled={!session.changesDetected || state.privilege === "demo"}
                    />
                    <IconButton
                        icon={Edit}
                        alt="Edit Session Parameters"
                        containerStyle={ICON_STYLE}
                        onClick={handleEditClick}
                        disabled={state.privilege === "demo"}
                    />
                    <IconButton
                        icon={Delete}
                        alt="delete session"
                        containerStyle={ICON_STYLE}
                        onClick={handleDeleteClick}
                        disabled={state.privilege === "demo"}
                    />
                    <IconButton
                        icon={Export}
                        alt=""
                        containerStyle={ICON_STYLE}
                        onClick={handleExportClick}
                    />
                </Grid>
            </div>
        </>
    );
}
