import { DateTime } from "luxon";
import { percent2Color } from "../components/dashboard/helpers/dashboardHelpers";
const Cesium = require("cesium");

export const captureCurrentView = viewer => {
    // const rect = new Cesium.Rectangle()
    const ellipsoid = viewer.scene.globe.ellipsoid;
    const cameraRect = { ...viewer.camera.computeViewRectangle(ellipsoid) };

    const cartesian3Coordinates = viewer.camera.getRectangleCameraCoordinates(cameraRect)
    const pos = Cesium.Cartographic.fromCartesian(cartesian3Coordinates)

    const lat = pos.latitude * (180 / Math.PI),
        lng = pos.longitude * (180 / Math.PI),
        alt = pos.height

    // cameraRect.position = {lat, lng, alt}
    // cameraRect.position = pos
    cameraRect.position = viewer.camera.positionWC
    cameraRect.positionCoords = { lat, lng, alt }

    cameraRect.heading = viewer.camera.heading;
    cameraRect.pitch = viewer.camera.pitch;
    cameraRect.roll = viewer.camera.roll;

    return cameraRect;
}

const extractLatLng = (locObj, reverse = false) => {
    const point = [Cesium.Math.toDegrees(locObj.latitude), Cesium.Math.toDegrees(locObj.longitude)]
    reverse && point.reverse();
    return point
}

export const rectangleToWKT = (rect, reverse = false) => {
    const [p1, p2, p3, p4] = [
        extractLatLng(Cesium.Rectangle.northwest(rect), reverse).join(" "),
        extractLatLng(Cesium.Rectangle.northeast(rect), reverse).join(" "),
        extractLatLng(Cesium.Rectangle.southeast(rect), reverse).join(" "),
        extractLatLng(Cesium.Rectangle.southwest(rect), reverse).join(" "),
    ]

    return `POLYGON((${p1}, ${p2}, ${p3}, ${p4}, ${p1}))`
}

export const calculateDistance = (point1, point2) => {
    console.log({ point1, point2 })
    if (point1 && point2) {
        const startPoint = Cesium.Cartographic.fromCartesian(Cesium.Cartesian3.fromDegrees(...point1))
        const endPoint = Cesium.Cartographic.fromCartesian(Cesium.Cartesian3.fromDegrees(...point2))
        const ellipsoidGeodesic = new Cesium.EllipsoidGeodesic(startPoint, endPoint)
        const distance = ellipsoidGeodesic.surfaceDistance;
        return distance
    } else {
        return null
    }
}

export const paramStringParser = params => {
    if (!params) return;
    const formattedParams = {}
    let key, value;
    params.split("&").forEach(param => {
        [key, value] = param.split("=")
        formattedParams[key] = value
    })

    return !!Object.keys(formattedParams).length
        ? formattedParams
        : null
}

export const stringifyParams = paramsObj => {
    if (!paramsObj) return;
    let formattedString = ""
    for (let [key, value] of Object.entries(paramsObj)) {
        if (!!key && !!value) {
            formattedString += `${key}=${value}&`
        }
    }

    return formattedString;
}

export const applyGeometryQuery = (cqlFilterString, wkt) => {
    let newStringSegment = `INTERSECTS(geom, ${wkt})`
    if (/AND INTERSECTS\(/gi.test(cqlFilterString)) newStringSegment = "AND " + newStringSegment // add 'AND' if necessary

    if (cqlFilterString) {
        if (/(AND\s)?INTERSECTS\(.+\)/gi.test(cqlFilterString)) {
            cqlFilterString = cqlFilterString.replace(/(AND\s)?INTERSECTS\(.+\)/gi, wkt ? newStringSegment : "");
        } else {
            cqlFilterString += wkt ? ` AND INTERSECTS(geom, ${wkt})` : ""
        }
    } else {
        cqlFilterString = newStringSegment
    }

    return cqlFilterString;
}

export const zoomToBbox = (viewer, bbox) => {
    if (!bbox) return
    viewer.camera.flyTo({
        destination: Cesium.Rectangle.fromDegrees(...bbox),
        duration: 0.5
    })
}

export const generateScenePopup = scene => {
    const dates = scene.images
        .map(scene => new Date(scene.timestamp).getTime())
        .sort()
        .map(time => DateTime.fromMillis(time).toFormat("yyyy/MM/dd"))
    return `
        <div class="text-xl">
            <h3 class="text-2xl font-bold">${scene.name}</h3>
            <hr/>
            <h3 class="text-center" style="background-color: ${percent2Color(scene.score)}">${(scene.score * 100).toFixed(2)}%</h3>
            <hr/>
            <p class="m-0 p-0 font-bold">Images: ${scene.images.length}</p>
            <p class="m-0 p-0 font-bold">Start: ${dates[0]}</p>
            <p class="m-0 p-0 font-bold">End: ${dates.at(-1)}</p>
        </div>

    `
}