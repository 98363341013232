import React, { useEffect, useRef, useState } from 'react';
import MenuUp from '../../../assets/icons/arrow_drop_up.svg'
import MenuDown from '../../../assets/icons/arrow_drop_down.svg'
import { DetectorTree } from './helpers';

export default function DetectorGroup({ type, detectors, selectedDetectors, toggleDetector }) {

    // STATE
    const [expanded, setExpanded] = useState(false);
    const numActiveDetectors = useRef(0) 
    const [parsedDetectors, setParsedDetectors] = useState(null)

    // RENDERERS
    const renderDetectors = (parsedDetectors) => {
        numActiveDetectors.current = 0;
        const detectors = parsedDetectors.map(detector => {
            const isActive = selectedDetectors.includes(detector.id);
            numActiveDetectors.current += isActive ? 1 : 0 
            return (
                <label key={detector.id} className='block cursor-pointer hover:font-bold' style={{ marginLeft: detector.level * 32 }}>
                    <input type="checkbox" className='mr-2' checked={selectedDetectors.includes(detector.id)} onClick={toggleDetector(detector.id)} />
                    {detector.data.name}
                </label>
            )
        })
        return detectors;
    }

    // EFFECTS
    useEffect(() => {
        const tree = new DetectorTree();
        for (let detector of detectors) {
            tree.append(detector);
        }
        setParsedDetectors(tree.toArray());
    }, [detectors])

    return (
        <div>
            <h3 className='text-lg font-bold cursor-pointer' onClick={() => { setExpanded(exp => !exp) }}>
                <img src={expanded ? MenuDown : MenuUp} className='inline-block' />
                {type} {!!numActiveDetectors.current && <sub className='text-green-500'>({numActiveDetectors.current})</sub>}
            </h3>
            {(expanded && parsedDetectors) && renderDetectors(parsedDetectors)}
        </div>
    )
}

