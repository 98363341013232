import React, { useContext, useState } from 'react'

// ================================ STATE ================================
import { ExplorerContext, DataPrepContext } from '../../state/explorer/explorerProvider';
// ================================ HOOKS ================================
import { usePage } from '../../js/hooks';

// ================================ CHILDREN ================================
import TabGroup from '../dzyne_components/ui/buttons/TabGroup/TabGroup';
import FormContainer from './FormContainer';

// ================================ CONSTANTS ================================
const TAB_MAPPING = {
    feature: "imagery"
}

export default function NewLayerForm({activeLayerType, setActiveLayerType}) {

    // STATE
    const page = usePage()
    const { setters } = useContext(page === "explorer" ? ExplorerContext : DataPrepContext);

    const [formType, setFormType] = useState("dzyne_server")


    // EVENTS
    const handleFormTypeChange = e => {
        setFormType(e.target.value)
    }

    const handleTabClick = tab => {
        /* 
        IMPORTANT: This if statement overrides the "imagery" selection to "feature"
        Basically, everything in the current build is a 
        */
        if(tab === "imagery"){
            setActiveLayerType("feature")
            return;
        } 
        setActiveLayerType(tab)
    }

    

    return (
        <div className='w-full'>
            <TabGroup
                tabs={["terrain", "base", "data",  "imagery", "file"]}
                // tabs={["terrain", "base", "feature", "imagery", "file"]}
                forceTab={TAB_MAPPING[activeLayerType] ?? activeLayerType}
                defaultTab={"data"}
                orientation={"horizontal"}
                onClick={handleTabClick}
                containerStyle={{ 
                    width: "fit-content", 
                    margin: "0.5rem auto", 
                    border: "none", 
                    boxShadow: "2px 2px 5px var(--primaryAccent-9)",
                    borderRadius: "0"
                }}
                tabStyle={{ color: "var(--neutral8)" }}
                selectedStyle={{ color: "var(--neutral-3)", backgroundColor: "var(--primaryAccent2)" }}
                className='grid grid-cols-5 w-full text-center'
                tabItemClassName='py-2 truncate font-verdana capitalize cursor-pointer'
                selectedClassName=''
            />

            <FormContainer {...{activeLayerType, setActiveLayerType}}/>
            
        </div>
    )
}
