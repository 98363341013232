import React, { useContext, useState, useEffect } from 'react'
import { Redirect } from 'react-router'

// ============================== STATE ==============================
import { UserContext } from '../../state/users/userProvider'
import Center from '../dzyne_components/layout/Center/Center'

// ============================== DZYNE COMPONENTS ==============================
import FormCompletion from '../dzyne_components/ui/forms/FormCompletion/FormCompletion'
import FormElementWrapper from '../dzyne_components/ui/forms/FormElementWrapper/FormElementWrapper'

// ============================== CHILDREN ==============================
import SatelliteSpinner from '../general/SatelliteSpinner'

// ============================== ASSETS ==============================
import CasiLogo from '../../assets/images/casi_logo.png'
import Satellite from '../../assets/icons/satellite.svg'

// ============================== STYLE ==============================
import "./user.css"

export default function Login() {

    // STATE
    const { state: userState, setters: userSetters, API: userAPI } = useContext(UserContext);
    const [error, setError] = useState(null)
    const [submitted, setSubmitted] = useState(false)


    // EVENTS
    const handleSubmit = async e => {
        e.preventDefault()

        const username = document.querySelector("#login-username").value
        const password = document.querySelector("#login-password").value

        setSubmitted(true)

        const user = await userAPI.login({ username, password })

        if (user.error) {
            setError(user.error)
            setSubmitted(false)
        } else {
            userSetters.setUser({ username: user.user_name, id: user.user_id, expires: user.expires })
            // userSetters.setToken(user.token)
            setSubmitted(false)
        }
    }

    useEffect(() => {
        const exec = async () => {
            if (localStorage.CASI_TOKEN) {
                setSubmitted(true);
                const user = await userAPI.renew();
                if(user) {
                    userSetters.setUser({username: user.user_name, id: user.user_id, expires: user.expires})
                    setSubmitted(false)
                } else {
                    setSubmitted(false);
                    setError("Session has timed out. Please login again")
                }
            }
        }
        exec();
    }, [])

    if (userState.user) return <Redirect to="/explorer" />

    return (
        <div className="content-container" >
            <Center>
                <form id="login-form" onSubmit={handleSubmit}>
                    <div style={{textAlign: "center"}}>
                        <img className="logo logo-md" src={CasiLogo} alt="casi logo" />
                    </div>

                    <FormElementWrapper
                        labels={["Username", "Password"]}
                        labelStyle={{
                            color: "var(--primaryAccent5)"
                        }}
                        elementStyles={{
                            border: "none",
                            color: "var(--primaryAccent9)",
                            margin: "0.5rem 0 1rem 0",
                            padding: "0.15rem 0.5rem",
                            fontSize: "1.5rem",
                            borderRadius: "4px",
                            backgroundColor: "var(--neutral-1)",
                            boxShadow: "1px 1px 4px var(--neutral-3)"
                        }}
                    >
                        <input id="login-username" type="text" autoFocus required />
                        <input id="login-password" type="password" required />
                    </FormElementWrapper>

                    {
                        submitted
                            ? <SatelliteSpinner size="3rem" animation="spin" />
                            : <FormCompletion submitName="Login" cancelButton />
                    }

                    
                    

                    {error && <h5 className="login-error-message">{error}</h5>}
                </form>
            </Center>
        </div>
    )
}
