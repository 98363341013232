export default function parseSharingLink(link){

    const params = new URLSearchParams(window.location.search);
    let shared = params.get("shared");
    let zoom = params.get("zoom");

    let results = {};
    try{
        results.shared = JSON.parse(shared)
    } catch(err){
        results.shared = []
    }
    
    try{
        results.zoom = JSON.parse(zoom)
    } catch(err){
        results.zoom = {lat: 0, lng: 0, alt: 100000}
    }

    return results;

}