import React, { useContext, useEffect, useState } from 'react'
import { extractProperties, retrieveDataFromProperty } from '../../js/analyticsHelpers'

// ====================== STATE ======================
import { ExplorerContext, DataPrepContext } from '../../state/explorer/explorerProvider'

// ====================== HOOKS ======================
import { usePage } from '../../js/hooks';

// ====================== CHILDREN ======================
import Grid from '../dzyne_components/layout/Grid/Grid';
import FormElementWrapper from "../dzyne_components/ui/forms/FormElementWrapper/FormElementWrapper";
import FormCompletion from "../dzyne_components/ui/forms/FormCompletion/FormCompletion";
import AnalyticsChart from './AnalyticsChart';
import InlineButton from '../dzyne_components/ui/buttons/InlineButton/InlineButton';

// ====================== CONFIGURATION ======================
const AVAILABLE_CHARTS = [
    "Categorical Histogram",
    "Binned Histogram",
    "Pie Chart",
    "Scatter Plot",
]

export default function LayerAnalytics() {

    // STATE
    const page = usePage()
    const { state, geoserverAPI } = useContext(page === "explorer" ? ExplorerContext : DataPrepContext)
    const [formLabels, setFormLabels] = useState(["Property", "Chart"])
    const [property, setProperty] = useState(null);
    const [chartType, setChartType] = useState(null);
    const [numBins, setNumBins] = useState(5)
    const [changesApplied, setChangesApplied] = useState(false)

    // EVENTS
    const handleSubmit = e => {
        e.preventDefault();

        const chart = document.querySelector("#analytics-form-chart").value;

        let bins = document.querySelector("#analytics-form-num-bins")?.value || "5"
        bins = parseInt(bins)
        
        const prop = chart === "Scatter Plot"
            ? [document.querySelector("#analytics-form-property-x").value, document.querySelector("#analytics-form-property-y").value,]
            : document.querySelector("#analytics-form-property").value


        setChartType(chart);
        setNumBins(bins);
        setProperty(prop);
        setChangesApplied(true)
        // const data = retrieveDataFromProperty(state.selectedLayerAnalytics, property)

    }

    const handleFormChange = () => {
        setChangesApplied(false)
    }

    const handleChartChange = e => {
        const chart = e.target.value;
        setChartType(chart)
        switch (true) {
            case ["Categorical Histogram", "Pie Chart"].includes(chart):
                setFormLabels(["Property", "Chart"])
                break;
            case chart === "Binned Histogram":
                setFormLabels(["Property", "Chart", "Num Bins"])
                break;
            case chart === "Scatter Plot":
                setFormLabels(["X Axis", "Y Axis", "Chart"])
                break;
        }
    }

    // RENDERERS
    const renderPropertyOptions = features => {
        const properties = extractProperties(features)
        return properties.map(prop => <option key={prop} value={prop}>{prop}</option>)
    }

    const renderChartOptions = chartTypes => {
        return chartTypes.map(type => <option key={type} value={type}>{type}</option>)
    }

    return (
        <Grid className="rendered-ribbon-content" cols={2} colSpacing={"3fr 9fr"} style={{ gridGap: "0.5rem" }}>
            <form onSubmit={handleSubmit} onChange={handleFormChange} className="ribbon-subsection">

                <label htmlFor="analytics-form-chart">Chart</label><br />
                <select id="analytics-form-chart" onChange={handleChartChange} value={chartType}>
                    {renderChartOptions(AVAILABLE_CHARTS)}
                </select>

                <br />

                {
                    chartType === "Binned Histogram"
                    &&
                    <>
                        <label htmlFor="analytics-form-num-bins"># Bins</label>
                        <br />
                        <input id="analytics-form-num-bins" type="number" min="2" max="99" defaultValue="5" />
                        <br />
                    </>
                }


                {
                    chartType === "Scatter Plot"
                        ? (
                            <Grid cols={2}>
                                <div>

                                    <label htmlFor="analytics-form-property-x">X Axis</label>
                                    <select id="analytics-form-property-x">
                                        {state.selectedLayerAnalytics && renderPropertyOptions(state.selectedLayerAnalytics)}
                                    </select>
                                </div>

                                <div>

                                    <label htmlFor="analytics-form-property-y">Y Axis</label>
                                    <select id="analytics-form-property-y">
                                        {state.selectedLayerAnalytics && renderPropertyOptions(state.selectedLayerAnalytics)}
                                    </select>
                                </div>

                            </Grid>
                        )
                        : (
                            <>
                                <label htmlFor="analytics-form-property-x">Property</label>
                                <br />
                                <select id="analytics-form-property">
                                    {state.selectedLayerAnalytics && renderPropertyOptions(state.selectedLayerAnalytics)}
                                </select>
                            </>
                        )
                }
                
                <br/>

                <button class="btn text-neutral0 bg-white">Apply</button>


            </form>

            {
                (!!state.selectedLayerAnalytics && changesApplied)
                &&
                <AnalyticsChart {...{ property, chartType, numBins }} />
            }
        </Grid>
    )
}
