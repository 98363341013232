import React from 'react';

import './ActionButton.css'


const ActionButton = ({ 
    children, 
    id = "",
    className = "",
    onClick = e => {},
    primaryColor = 'black',
    backgroundColor = 'white',
    style = {},
    pulse = false, 
    disabled = false
}) => { 

    return (
        <button
            id={id}
            className={`action-button action-button-emphasis-${!!pulse} ${className}`}
            onClick={onClick}
            disabled={disabled}
            style={{
                color: primaryColor,
                border: `4px solid ${primaryColor}`,
                backgroundColor,
                ...style
            }}
        >
            {children}
        </button>
    )

}

export default ActionButton