import { useContext, useEffect, useState } from "react";

// ================================ DZYNE COMPONENTS ================================
import Grid from '../../dzyne_components/layout/Grid/Grid';
import Modal from "../../dzyne_components/ui/modals/Modal/Modal"
import InlineButton from "../../dzyne_components/ui/buttons/InlineButton/InlineButton"
import ActionButton from "../../dzyne_components/ui/buttons/ActionButton/ActionButton"
import ImageAnnotationManager from '../../dzyne_components/ui/images/ImageAnnotationManager/ImageAnnotationManager';
import FormElementWrapper from '../../dzyne_components/ui/forms/FormElementWrapper/FormElementWrapper'

// ================================ HELPERS ================================
import { downloadJSON } from "../../../js/generalHelpers";
import { DataPrepContext } from "../../../state/explorer/explorerProvider";

export default function ImageAnnotator({
    annotationImage,
    setAnnotationImage,
    annotationClasses
}) {

    // STATE
    const { state, setters, dataPrepAPI } = useContext(DataPrepContext);
    const [selectedAnnotationLayer, setSelectedAnnotationLayer] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null)
    const [saveable, setSaveable] = useState(false)
    const [registry, setRegistry] = useState(null)
    const [priorAnnotations, setPriorAnnotations] = useState(null)


    // EVENTS
    const handleSaveAnnotationsClick = async () => {

        // export and format annotations for saving
        const annotations = registry?.registry?.exportRegistry("wkt")
        const formattedAnnotations = annotations.map(a => {
            return {
                id: a.annotation?._id,
                feature_type: a.annotation?.metadata?.code,
                properties: {
                    _id: a.annotation?._id,
                    metadata: a.annotation.metadata,
                    points: a.annotation.current,
                    type: a.annotation.type
                },
                geom: a.geometry
            }
        })

        // add annotations to annotation layer locally 
        const body = {
            name: selectedAnnotationLayer.name,
            image_catid: selectedAnnotationLayer.image_catid,
            annotations: formattedAnnotations
        }

        // send general update request with annotation layer and actual annotation data
        const success = await dataPrepAPI.updateAnnotationLayer(body, selectedAnnotationLayer.id)

        success && setSaveable(false)


    }

    const handleSelectedAnnotationLayerChange = e => {
        let proceed = true;
        if (saveable) {
            proceed = window.confirm("WARNING: You have unsaved work in this layer. Switching layers now may result in lost work. Are you sure you want to continue")
            if (proceed) {
                setSaveable(false)
            }
        }

        setSelectedAnnotationLayer(selectedAnnotationLayer => {
            if (proceed) {
                registry?.registry.clearAll()
                return state.annotationLayers.find(al => al.id == e.target.value)
            } else {
                return selectedAnnotationLayer
            }
        })
    }

    const handleDeleteAnnotationLayer = async () => {
        const success = await dataPrepAPI.deleteAnnotationLayer(selectedAnnotationLayer.id);
        registry?.registry.clearAll()

        if (success) {
            setters.removeAnnotationLayer(selectedAnnotationLayer.id)
            setSelectedAnnotationLayer(null)
            setPriorAnnotations(null)
        }
    }

    const handleAnnotationExport = payload => {
        const formatted = { catid: annotationImage.properties?.catid, metadata: payload.annotation.metadata, geometry: payload.geometry }
        downloadJSON(formatted, `annotation_${annotationImage.properties?.catid}.json`)
    }

    const handleAnnotationRegistryExport = payload => {
        const formatted = []
        let metadata;
        for (let ex of payload) {
            metadata = {
                ...ex.annotation.metadata,
                type: ex.annotation.type,
                current: ex.annotation.current,
                annotationID: ex.annotation._id
            }
            formatted.push(
                { catid: annotationImage.properties?.catid, metadata, geometry: ex.geometry }
            )
        }

        downloadJSON(formatted, `annotations_${annotationImage.properties?.catid}.json`)
    }

    const handleCreateAnnotationLayer = async e => {
        e.preventDefault()
        const nameEl = document.querySelector("#create-annotation-layer-name")
        const catid = annotationImage?.properties?.catid

        if (state.annotationLayers.map(al => al.name).includes(nameEl.value)) {
            return setErrorMessage("name already exists for this image");
        }

        const newAnnotationLayer = await dataPrepAPI.createAnnotationLayer({ name: nameEl.value, image_catid: catid, annotations: [] });
        if (newAnnotationLayer) {
            setSelectedAnnotationLayer(newAnnotationLayer);
            registry?.registry.clearAll()
        }

        nameEl.value = ""
        setErrorMessage(null)

    }

    // RENDERERS
    const renderAnnotationLayerOptions = (annotationLayers) => {
        if (!annotationLayers.length) return "No annotation layers created for this image yet"
        return (
            <select value={selectedAnnotationLayer?.id} onChange={handleSelectedAnnotationLayerChange}>
                <option>Select an annotation layer</option>
                {
                    annotationLayers.map(layer => (
                        <option key={layer.id} value={layer.id} >{layer.name}</option>
                    ))
                }
            </select>
        )

    }

    // ON LOAD
    // load annotation layers associated with this image catid
    useEffect(() => {
        dataPrepAPI.getAnnotationLayersByCatid(annotationImage.properties?.catid)
    }, [])

    useEffect(() => {
        const fetchAnnotations = async () => {
            if (selectedAnnotationLayer && registry) {
                console.log({ registry })
                const annotationLayer = await dataPrepAPI.getAnnotationLayerByID(selectedAnnotationLayer.id)
                setPriorAnnotations(annotationLayer?.annotations)
            }
        }

        fetchAnnotations()


    }, [selectedAnnotationLayer, registry])



    return (
        <Modal
            closeButton
            onClose={() => {setAnnotationImage(null); registry?.registry.clearAll()}}
            bodyStyle={{ color: "var(--neutral3)", backgroundColor: "var(--neutral12)" }}
        >
            <h3>Annotations for image {annotationImage.properties?.catid}</h3>

            <Grid cols={3}>
                <InlineButton onClick={handleSaveAnnotationsClick} disabled={!saveable}>
                    Save Annotation Layer
                </InlineButton>

                <div>
                    <h4 className="m-0">Current annotation layer</h4>
                    {renderAnnotationLayerOptions(state.annotationLayers)}
                    {
                        !!selectedAnnotationLayer
                        &&
                        <InlineButton onClick={handleDeleteAnnotationLayer}>
                            Delete
                        </InlineButton>
                    }
                </div>

                <form id="create-annotation-layer-form" onSubmit={handleCreateAnnotationLayer} >
                    <h4 className="m-0">Create new annotation layer</h4>
                    <input id="create-annotation-layer-name" type={"text"} placeholder="Name" required />
                    <InlineButton>create</InlineButton>
                    {errorMessage && <em><h5 className="annotator-text" style={{ color: "var(--secondaryAccent0)" }}>{errorMessage}</h5></em>}
                </form>



            </Grid>
            {
                selectedAnnotationLayer
                &&
                <ImageAnnotationManager
                    // annotationRegistryID={annotationImage.id}
                    restoreFromRegistry={false}
                    initializationAnnotations={priorAnnotations}
                    setRegistry={setRegistry}
                    src={annotationImage.rawURL}
                    imageWidth={parseInt(annotationImage.dimensions[0])}
                    imageHeight={parseInt(annotationImage.dimensions[1])}
                    containerWidth={"80vw"}
                    containerHeight={"75vh"}
                    annotationClasses={annotationClasses}
                    exportFormat="wkt"
                    events={{
                        export: handleAnnotationExport,
                        exportRegistry: handleAnnotationRegistryExport,
                        update: () => { setSaveable(true) }
                    }}
                />
            }
        </Modal>
    )
}
