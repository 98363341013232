import React from 'react';
import "./IconButton.css"

const IconButton = ({
    id = "",
    className = "",
    icon,
    label = null,
    labelLocation = "bottom",
    alt = "",

    disabled = false,
    onClick = e => { },

    display = "inline-block",

    containerStyle = {},
    iconStyle = {}
}) => {
    return (
        <div
            id={id || null}
            className={`icon-button ${className} ${disabled && "icon-button-disabled"}`}
            style={{ display, ...containerStyle }}
            onClick={!disabled ? onClick : () => { }}
            title={label ? "" : alt}
        >
            {
                label
                &&
                <>
                    {(labelLocation === "top") && `${label}`}
                    <br />
                </>
            }
            <img src={icon} alt={alt} style={iconStyle} />
            {
                label
                &&
                <>
                    <br />
                    {(labelLocation === "bottom") && `${label}`}
                </>
            }
        </div>
    )
}

export default IconButton;