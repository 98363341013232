
// HELPERS
function hexToHSL(H) {
    // Convert hex to RGB first
    let r = 0, g = 0, b = 0;
    if (H.length == 4) {
      r = "0x" + H[1] + H[1];
      g = "0x" + H[2] + H[2];
      b = "0x" + H[3] + H[3];
    } else if (H.length == 7) {
      r = "0x" + H[1] + H[2];
      g = "0x" + H[3] + H[4];
      b = "0x" + H[5] + H[6];
    }
    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r,g,b),
        cmax = Math.max(r,g,b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;
  
    if (delta == 0)
      h = 0;
    else if (cmax == r)
      h = ((g - b) / delta) % 6;
    else if (cmax == g)
      h = (b - r) / delta + 2;
    else
      h = (r - g) / delta + 4;
  
    h = Math.round(h * 60);
  
    if (h < 0)
      h += 360;
  
    l = (cmax + cmin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);
  
    // return ["hsl(" + h + "," + s + "%," + l + "%)", l];
    return [h, s, Math.round(l)]
  }

  const formatHSL = (h, s, l) => {
      return `hsl(${h}, ${s}%, ${l}%)`
  }


export default function ColorPalette ({ colors, display }) {


    colors = colors || {}

    const transformColors = colors => {
        /* 
        takes color objects, parses the gradient adjustments, and places them into [identifier, color] arrays indexed by the name of the color swatch
        */
        const shades = {};

        let h, s, l, 
            positiveIdentifier,
            negativeIdentifier,
            lightGradient,
            darkGradient,
            i;


        for (let [name, params] of Object.entries(colors)) {
            
            shades[name] = [];
            i = 0;

            // add the base color
            [h, s, l] = hexToHSL(params.base) 
            shades[name].push([`--${name}0`, formatHSL(h,s,l)])
            
            lightGradient = l
            darkGradient = l

            // create gradients
            while (lightGradient < 100 || darkGradient > 0){
                
                i += 1

                lightGradient += params.gradientStep;
                darkGradient -= params.gradientStep;

                if(lightGradient <= 100){
                    positiveIdentifier = `${name}${i}` 
                    shades[name].push([`--${positiveIdentifier}`, formatHSL(h, s, lightGradient)])
                }
                
                if(darkGradient >= 0){
                    negativeIdentifier = `${name}-${i}`
                    shades[name].push([`--${negativeIdentifier}`, formatHSL(h, s, darkGradient)])
                }
            }


            shades[name].sort((a,b) => {
                a = parseInt(a[0].match(/\-?\d+/))
                b = parseInt(b[0].match(/\-?\d+/))

                if(a < b) return -1
                if(a > b) return 1
                return 0
            })

        }

        return shades
    }

    // COLORS
    const parsedColors = transformColors(colors)
    

    const genPalette = (colors) => {
        let styleString = ":root{\n"
        for (let val of Object.values(colors)) {
            for(let colorIdentifier of val){
                styleString += `${colorIdentifier[0]}: ${colorIdentifier[1]};\n`
            }
        }
        return styleString + "}";
    }

    const displayColorPalette = (colors) => {
        const swatches = []

        for (let [key, val] of Object.entries(parsedColors)) {
            for(let color of val){
                swatches.push(
                    <div 
                        className="color-swatch" 
                        key={color} 
                        style={{
                            height: "2rem",
                            width: (100 / val.length) + "vw",
                            display: "inline-block",
                            backgroundColor: color[1]
                        }}
                    ></div>
                )
            }
            swatches.push(<br key={key} />)
        }

        return swatches;
    }

    return (
        <>
            <style id="color-palette">
                {genPalette(parsedColors)}
            </style>
            {!!display && displayColorPalette(parsedColors)}
        </>
    )

}