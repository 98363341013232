import { useContext, useRef, useState, useEffect, useLayoutEffect } from "react";

// ========================= MODULES  =========================
import use3DMap from "../dzyne_components/hooks/use3DMap";
import useWindowResize from "../dzyne_components/hooks/useWindowResize";
import MapPopupWrapper3D from "../dzyne_components/map/3d/MapPopupWrapper3D/MapPopupWrapper3D";
import MapCoordinateBox3D from "../dzyne_components/map/3d/MapCoordinateBox3D/MapCoordinateBox3D";
import MapLegend from "./MapLegend";

// ========================= STATE  =========================
import { ExplorerContext, DataPrepContext } from "../../state/explorer/explorerProvider";

import config from "../../config.json";
import FeatureInfoBox from "./FeatureInfoBox";
import setters from "../../state/config/setters";
import { usePage } from "../../js/hooks";

// ========================= ICONS =========================
import DropDownIcon from "../../assets/icons/arrow_drop_down.svg";
import DropUpIcon from "../../assets/icons/arrow_drop_up.svg";
import Compass from "../../assets/icons/north_up_path.svg";

const Cesium = require("cesium");

const CESIUM_BASE_PROTOCOL = process.env.REACT_APP_CESIUM_PROTOCOL || window._env_?.cesiumBaseProtocol || config.cesiumBaseProtocol || config.defaultProtocol || "http";
const CESIUM_BASE_HOST = process.env.REACT_APP_CESIUM_BASE_HOST || window._env_?.cesiumBaseHost || config.cesiumBaseHost || window.location.hostname;
const CESIUM_BASE_PORT = process.env.REACT_APP_CESIUM_BASE_PORT || window._env_?.cesiumBasePort || config.cesiumBasePort || 4000;
const CESIUM_BASE_SLUG = process.env.REACT_APP_CESIUM_BASE_SLUG || window._env_?.cesiumBaseSlug || config.cesiumBaseSlug || null;

const CESIUM_BASE_URL = `${CESIUM_BASE_PROTOCOL}://${CESIUM_BASE_HOST}:${CESIUM_BASE_PORT}${CESIUM_BASE_SLUG || ""}`;
console.log({ CESIUM_BASE_URL });

export default function ExplorerMap() {
    const page = usePage();
    const { state: explorerState, setters: explorerSetters, methods: explorerMethods } = useContext(page === "explorer" ? ExplorerContext : DataPrepContext);
    const [mapSize, setMapSize] = useState({ height: 0, width: 0 });
    const [compassHeading, setCompassHeading] = useState(0);

    const containerRef = useRef(null);

    const size = useWindowResize();
    // console.log(size)

    const viewer = use3DMap(
        "explorer-map",
        {
            cesiumBaseUrl: CESIUM_BASE_URL,
            // defaultTiles: `${configState.mapTileBase}/styles/osm-bright/{z}/{x}/{y}.png`,
            // defaultTiles: `https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png`,
            display: "scene",
        },
        {
            baseLayerPicker: false,
            infoBox: false,
            scene3DOnly: true,
            selectionIndicator: false,
            fullscreenButton: false,
            skyAtmosphere: false,
            // imageryProvider: new Cesium.TileCoordinatesImageryProvider({ tilingScheme: new Cesium.GeographicTilingScheme() }) // for debuggin purposes only
            // could be helpful: https://cesium.com/docs/cesiumjs-ref-doc/TileCoordinatesImageryProvider.html
        },
        function (viewer) {
            viewer.scene.fog.enabled = false;
            explorerSetters.setViewer(viewer);
            explorerSetters.setBaseLayer(viewer.imageryLayers._layers[0]);
            explorerMethods.zoomToDefaultLocation({ viewer });
            explorerMethods.forceRenderSharedTerrain(explorerState.layers, viewer);

            // viewer.scene.mode = Cesium.SceneMode.SCENE2D

            // set the base layer in the state when the base layer changes.
            viewer.imageryLayers.layerAdded.addEventListener(function (layer) {
                if (layer._isBaseLayer) {
                    console.log(layer, viewer);
                    explorerSetters.setBaseLayer(layer);
                }
            });

            // Reset the layer info box whenever the map is clicked to make way for new lay information to be entered
            viewer.canvas.addEventListener("pointermove", function (e) {
                window._mouseMoveOverCanvas = true;
            });
            viewer.canvas.addEventListener("pointerdown", function (e) {
                window._mouseMoveOverCanvas = false;
            });
            viewer.canvas.addEventListener("pointerup", function (e) {
                if (!window._mouseMoveOverCanvas) {
                    explorerSetters.setLayerInfoBox(null);
                }
            });

            viewer.camera.percentageChanged = 0.01;
            viewer.camera.changed.addEventListener(function () {
                const cameraHeading = Cesium.Math.toDegrees(viewer.camera.heading);
                setCompassHeading(360 - cameraHeading);
            });

            // viewer.extend(Cesium.viewerCesiumInspectorMixin)
        }
    );

    // RENDERERS
    const drawLayers = (layer) => {
        explorerMethods.drawLayerOnMap(layer.id);
    };

    // LAYER RENDERING
    useEffect(() => {
        if (explorerState.layers && explorerState.viewer) {
            let layer;

            // loop backwards through layers because we want the first layer in the array to be the top most layer on the map
            for (let i = explorerState.layers.length - 1; i >= 0; i--) {
                layer = explorerState.layers[i];
                layer.isActive && drawLayers(layer);
            }
        }
    }, [explorerState.viewer, explorerState.layers]);

    // ZOOMING
    useEffect(() => {
        explorerMethods.zoomToDefaultLocation({});
    }, [explorerState.globalSettings.defaultLocation]);

    // RESIZING
    useLayoutEffect(() => {
        const execAsync = async () => {
            let containerBounds = containerRef.current.getBoundingClientRect();
            const ribbonContainer = document.querySelector("#explorer-ribbon");

            if (!!ribbonContainer) {
                setMapSize({
                    height: 1,
                    width: size.width ? size.width - containerBounds.left : containerBounds.width,
                });
                await new Promise((r) => setTimeout(r, 0));
                containerBounds = containerRef.current.getBoundingClientRect(); // refresh reference to the container so we get the latest bounds
                setMapSize({
                    height: containerBounds.height,
                    width: size.width ? size.width - containerBounds.left : containerBounds.width,
                });
            } else {
                const containerBounds = containerRef.current.getBoundingClientRect();
                setMapSize({
                    height: containerBounds.height,
                    width: size.width ? size.width - containerBounds.left : containerBounds.width,
                });
            }
        };
        execAsync();
    }, [size, explorerState.showRibbon]);

    return (
        <div id="explorer-map-container" className="explorer-grid-area relative" style={{ gridArea: "map" }} ref={containerRef}>
            <div id="explorer-map" className="rounded-lg" style={{ ...mapSize }}></div>
            {explorerState.viewer && (
                <MapCoordinateBox3D
                    viewer={explorerState.viewer}
                    positioning="static-bottom-right"
                    activationKey="none"
                    format="mgrs"
                    style={{
                        fontFamily: "Jura, sans-serif",
                        fontWeight: "bold",
                    }}
                />
            )}
            {explorerState.layerInfoBox && explorerState.viewer && (
                <MapPopupWrapper3D viewer={explorerState.viewer} location={"static-upper-left"} closeButton minimizeButton resizable onClose={() => explorerSetters.setLayerInfoBox(null)} containerStyle={!!explorerState.layerInfoBox.features.length ? { minHeight: 200, minWidth: 100 } : { minHeight: "2rem" }}>
                    <FeatureInfoBox features={explorerState.layerInfoBox.features} methods={explorerMethods} />
                </MapPopupWrapper3D>
            )}
            <MapLegend />
            <img className="absolute right-2 top-12 h-8 w-8 bg-gray-100 rounded-full" src={Compass} alt="compass" style={{ transform: `rotate(${compassHeading}deg)`,}} />
            <button
                className="absolute bottom-0 w-16"
                onClick={() => explorerSetters.toggleShowRibbon()}
                style={{
                    backgroundColor: "var(--neutral0)",
                    left: "calc(50% - 2rem)",
                    borderTopRightRadius: 4,
                    borderTopLeftRadius: 4,
                }}
            >
                <img src={explorerState.showRibbon ? DropDownIcon : DropUpIcon} className="my-0 mx-auto w-8" />
            </button>
        </div>
    );
}
