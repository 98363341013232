import React, { useState } from 'react'

// =========================== CONFIG ===========================
import legendTemplates from '../../state/explorer/mapLegends.json';

export default function MapLegend() {

    // STATE
    const [showLegend, setShowLegend] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(legendTemplates[0] ?? {});

    // RENDERERS
    const renderTemplateOptions = (templates) => {
        return templates.map(template => {
            return (
                <option key={template.name} value={template.name}>
                    {template.name}
                </option>
            )
        })
    }

    const renderSelectedTemplate = (template) => {
        return template?.keys?.map(colorMap => {
            return (
                <div key={colorMap.name} className='my-1'>
                    <div className={`inline-block w-8 h-4 mr-2 shadow-lg shadow-gray-800`} style={{ backgroundColor: colorMap.color }}></div>
                    <div className='inline-block font-semibold'>{colorMap.name}</div>
                </div>
            )
        })
    }

    return (
        <div className='absolute left-2 bottom-2 px-2 rounded-sm' style={{ backgroundColor: "rgba(245, 245, 245, 0.50)", backdropFilter: "blur(2px)" }}>
            {
                !!showLegend
                    ? (
                        <div className='mt-1 text-left'>
                            <div>
                                <button
                                    onClick={() => { setShowLegend(false) }}
                                >
                                    <svg height="24" width="24">
                                        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm0 10.293l5.293-5.293.707.707-5.293 5.293 5.293 5.293-.707.707-5.293-5.293-5.293 5.293-.707-.707 5.293-5.293-5.293-5.293.707-.707 5.293 5.293z" /></svg>
                                    </svg>
                                </button>
                            </div>

                            <select
                                className='rounded-sm p-1 cursor-pointer'
                                value={selectedTemplate.name}
                                onChange={e => setSelectedTemplate(legendTemplates.find(template => template.name === e.target.value))}
                            >
                                {renderTemplateOptions(legendTemplates)}
                            </select>
                            <hr className='mt-1'/>
                            <div className='mt-2 pr-1 overflow-y-auto' style={{ maxHeight: "10rem" }}>
                                {renderSelectedTemplate(selectedTemplate)}
                            </div>

                        </div>

                    )
                    : (
                        <button
                            onClick={() => { setShowLegend(true) }}
                        >
                            Expand Color Legend
                        </button>
                    )
            }
        </div>
    )

}