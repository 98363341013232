
const setters = {

    updateScenes(scenes) {
        this.setState(prevState => {
            const updatedScenes = [...scenes, ...prevState.scenes]
            return { scenes: updatedScenes, filteredScenes: updatedScenes }
        })
    }

}

export default setters;
