class DetectorNode {
    constructor(detectorData, level){
        this.data = detectorData;
        this.id = detectorData.id;
        this.level = level;
        this.children = [];
    }

    appendChild(node) {
        node.level = this.level + 1;
        this.children.push(node);
        return node;
    }

    hasChildren() {
        return !!this.children.length;
    }
}

export class DetectorTree {
    static create(detector){
        const tree = new DetectorTree();
        tree.append(detector);
        return tree;
    }

    constructor() {
        this.roots = [];
        this.nodes = {};
    }

    findByID(id) {
        return this.nodes[id];
    }

    append(detector){
        const node = new DetectorNode(detector, null)
        const directParent = this.findByID(detector.parent_id);
        if(!directParent) {
            node.level = 0;
            this.roots.push(node);
            this.nodes[detector.id] = node;
            return true;
        } else {
            directParent.appendChild(node)
        }
    }

    toArray(){
        const arr = [];
        const exec = (node) => {
            arr.push(node);
            for(let child of node.children) {
                exec(child);
            }
        }

        for(let node of this.roots) {
            exec(node);
        }

        return arr;
    }
}