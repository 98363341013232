const getters = {

    getRenderedSelectedLayer() {
        return this.state.renderedLayers[this.state.selectedLayer.id]
    },

    getRenderedLayer(layerID) {
        return this.state.renderedLayers[layerID]
    },

    getNumberOfLayerType(layerType) {
        const types = layerType.split(",");
        return this.state.layers.filter(layer => types.includes(layer?.layerType)).length
    },

    getLayerByCatID(id) {
        return this.state.layers.find(layer => layer.layerLocation?.match?.(id));
    }

}

export default getters;