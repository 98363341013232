import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'

import ImageAnnotationRegistry from './ImageAnnotation';

// ======================== CHILDREN ========================
import IconButton from '../../buttons/Iconbutton/IconButton';
import Grid from '../../../layout/Grid/Grid';

// ======================== STYLING ========================
import "./ImageAnnotationManager.css"
import { extractBboxFromSrc, formatTileRequest } from './helpers';

// ======================== ASSETS ========================
const Point = require('../../../assets/media/point.svg').default
const Multipoint = require('../../../assets/media/multipoint.svg').default
const Polyline = require('../../../assets/media/polyline.svg').default
const Polygon = require('../../../assets/media/polygon.svg').default
const Circle = require('../../../assets/media/circle.svg').default
const Rectangle = require('../../../assets/media/rectangle.svg').default

const Save = require('../../../assets/media/save.svg').default;
const Cancel = require('../../../assets/media/remove_circle_outline.svg').default;
const Delete = require('../../../assets/media/trash.svg').default;
const Edit = require('../../../assets/media/edit.svg').default;
const Undo = require('../../../assets/media/undo.svg').default;
const Redo = require('../../../assets/media/redo.svg').default;
const Export = require('../../../assets/media/export.svg').default

const ZoomIn = require('../../../assets/media/zoom_in.svg').default
const ZoomOut = require('../../../assets/media/zoom_out.svg').default
const Crop = require('../../../assets/media/crop.svg').default


// ======================== CONFIGURATION ========================
const TYPE_MAP = {
    point: { icon: Point },
    multipoint: { icon: Multipoint },
    polyline: { icon: Polyline },
    polygon: { icon: Polygon },
    circle: { icon: Circle },
    rectangle: { icon: Rectangle },
}

const ACTION_MAP = {
    save: {
        title: "Save annotation",
        icon: Save
    },
    cancel: {
        title: "Revert to last save",
        icon: Cancel
    },
    edit: {
        title: "Edit properties",
        icon: Edit
    },
    delete: {
        title: "Delete annotation",
        icon: Delete
    },
    undo: {
        title: "Undo last annotation",
        icon: Undo
    },
    redo: {
        title: "Redo last annotation",
        icon: Redo
    },
    export: {
        title: "Export current geometry",
        icon: Export
    }

}

const DEFAULT_EVENTS = {
    update: () => { },
    delete: () => { },
    select: () => { },
    unselect: () => { },
    export: () => { },
}


/* 
:::::::::::::::::::::::::::::::
:::::::::: COMPONENT ::::::::::
:::::::::::::::::::::::::::::::
*/

export default function ImageAnnotationManager({
    src,
    annotationRegistryID,
    restoreFromRegistry = false,
    initializationAnnotations = null,
    removeAnnotationsOnUnmount = false,
    availableTypes = ["point", "multipoint", "polyline", "polygon", "rectangle", "circle"],
    availableActions = ["save", "cancel", "edit", "delete", "undo", "redo", "export"],

    setRegistry = () => { },

    annotationClasses = [],

    events = {},
    exportFormat = "raw",

    containerWidth = "100%",
    containerHeight = "100%",
    imageWidth = "100%",
    imageHeight = "100%",

    maxTilePxWidth = 1920,
    maxTilePxHeight = 1080,

    containerStyle = {},
    className="",
    iconStyle = { height: "1rem", width: "30px" },

}) {

    // initialization
    events = { ...DEFAULT_EVENTS, ...events }
    const [ImageAnnotation, setImageAnnotation] = useState(ImageAnnotationRegistry.accessRegistry(annotationRegistryID))
    const [dimensions, setDimensions] = useState({ x: imageWidth, y: imageHeight })
    const [tileGrid, setTileGrid] = useState(null)
    const [zoom, setZoom] = useState(100)
    const [selectedAnnotation, setSelectedAnnotation] = useState(null)
    const [currentAnnotationTypes, setCurrentAnnotationTypes] = useState(annotationClasses.length ? [] : availableTypes)
    const [currentMetadata, setCurrentMetadata] = useState({})

    const cropCanvasRef = useRef(null)
    const svgRef = useRef(null)
    const imageRef = useRef(null)

    // console.log(extractBboxFromSrc(src))

    // EVENTS
    const handleZoomChange = (direction, amount = 10) => () => {
        if (direction === "in") {
            setZoom(zoom => zoom + amount <= 1000 ? zoom + amount : 1000)
        } else {
            setZoom(zoom => zoom - amount >= amount ? zoom - amount : amount)
        }
    }

    const handleCreateAnnotation = type => () => {

        const annotation = new ImageAnnotation.registry({
            svg: svgRef.current,
            type,
            metadata: currentMetadata,
            options: {}
        })

        // apply events
        for (let [eventName, callback] of Object.entries(events)) {
            if (eventName === "select") {
                annotation.on("select", payload => {
                    setSelectedAnnotation(payload.annotation)
                    callback(payload)
                })
            } else {
                annotation.on(eventName, callback)
            }
        }

        setSelectedAnnotation(annotation)

    }

    const handleSave = () => {
        ImageAnnotation.registry.deselect();
        setSelectedAnnotation(null)
    }

    const handleDelete = () => {
        ImageAnnotation.registry.deleteByID(selectedAnnotation._id)
        setSelectedAnnotation(null)
    }

    const handleUndo = () => {
        selectedAnnotation.undo()
    }

    const handleRedo = () => {
        selectedAnnotation.redo()
    }

    const handleExportSelectedAnnotation = () => {
        selectedAnnotation?.export(exportFormat)
    }

    const handleExportAll = () => {
        const exports = ImageAnnotation.registry.exportRegistry(exportFormat)
        events.exportRegistry?.(exports)
    }

    const handleActionClick = action => () => {
        switch (action) {
            case "save":
                return handleSave()
            case "delete":
                return handleDelete()
            case "undo":
                return handleUndo()
            case "redo":
                return handleRedo()
            case "export":
                return handleExportSelectedAnnotation()
        }
    }

    const handleAnnotationClassChange = e => {
        const selection = [...e.target.children].find(child => e.target.value === child.innerText)

        e.target.style.backgroundColor = selection.style.backgroundColor

        let types = selection?.dataset?.annotationtypes
        if (!types) return

        if (types === "*") {
            setCurrentAnnotationTypes(availableTypes)
        } else {
            setCurrentAnnotationTypes(types.split(","))
        }

        setCurrentMetadata(JSON.parse(selection?.dataset?.metadata || "{}"))
    }

    // RENDERERS
    const renderImageTiles = () => {

        const indeces = []
        for (let r = 0; r < tileGrid.rows; r++) {
            for (let c = 0; c < tileGrid.cols; c++) {
                indeces.push([c, r])
            }
        }

        const images = []
        for (let index of indeces) {
            images.push(
                <img
                    key={index.toString()}
                    className="image-tile"
                    src={formatTileRequest(src, index, tileGrid)}
                    style={{ height: "100%", width: "100%" }}
                    alt={index.toString()}
                />
            )
        }

        for (let i = 0; i < tileGrid.cols * tileGrid.rows; i++) {
            images.push(

            )
        }
        return images

    }

    const renderAnnotationTypes = (availableTypes) => {
        return availableTypes.map(type => {
            return (
                <IconButton
                    onClick={handleCreateAnnotation(type)}
                    icon={TYPE_MAP[type].icon}
                    containerStyle={iconStyle}
                    alt={type}
                    iconStyle={iconStyle}
                />
            )
        })
    }

    const renderActions = availableActions => {
        return availableActions.map(action => {
            return (
                <IconButton
                    key={action}
                    id={action}
                    icon={ACTION_MAP[action].icon}
                    alt={ACTION_MAP[action].title}
                    onClick={handleActionClick(action)}
                    iconStyle={iconStyle}
                />
            )
        })
    }

    const renderAnnotationClasses = (annotationClasses) => {
        let metadata;
        return annotationClasses.map(cls => {
            metadata = { ...(cls.metadata || {}) }
            metadata.style = metadata.style || { fill: cls.color, stroke: cls.color }
            return (
                <option
                    key={cls.name}
                    value={cls.name}
                    data-annotationtypes={cls.annotationTypes || []}
                    data-metadata={JSON.stringify(metadata || {})}
                    style={{ backgroundColor: cls.color }}
                >

                    {cls.name}
                </option>
            )
        })
    }

    // ON LOAD

    // restore annotations from registry
    useEffect(() => {
        const svg = svgRef.current;

        if (!!ImageAnnotation?.registry && svg) {
            setRegistry(ImageAnnotation)
            if (restoreFromRegistry) {
                ImageAnnotation.registry?._annotations?.forEach(annotation => {
                    // update previous annotations with the current svg and event functionality
                    annotation.svg = svg;
                    for (let [eventName, callback] of Object.entries(events)) {
                        if (eventName === "select") {
                            annotation.on("select", payload => {
                                setSelectedAnnotation(payload.annotation)
                                callback(payload)
                            })
                        } else {
                            annotation.on(eventName, callback)
                        }
                    }
                })
            }
        }

        return () => {
            if (removeAnnotationsOnUnmount) {
                ImageAnnotation.registry?.clearAll()
            }
        }
    }, [ImageAnnotation, svgRef.current])

    // zooming
    useEffect(() => {
        const svg = svgRef.current;
        const img = imageRef.current

        if (!!svg && !!img) {
            svg.addEventListener("wheel", function (e) {
                if (e.ctrlKey) {
                    e.preventDefault();
                    e.stopPropagation();
                    if (e.wheelDelta > 0) handleZoomChange("in", 2)()
                    if (e.wheelDelta < 0) handleZoomChange("out", 2)()
                }
            })
        }
    }, [svgRef.current, imageRef.current])

    // tile grid
    useEffect(() => {
        const bbox = extractBboxFromSrc(src)
        if (bbox) {
            const cols = imageWidth % maxTilePxWidth ? Math.floor(imageWidth / maxTilePxWidth) + 1 : Math.floor(imageWidth / maxTilePxWidth)
            const rows = imageHeight % maxTilePxHeight ? Math.floor(imageHeight / maxTilePxHeight) + 1 : Math.floor(imageHeight / maxTilePxHeight)
            const tg = {
                cols,
                rows,
                tileWidth: Math.round(imageWidth / cols),
                tileHeight: Math.round(imageHeight / rows),
                lngWidth: bbox.deltaLng / cols,
                latHeight: bbox.deltaLat / rows,
                bbox
            }
            setTileGrid(tg)
        }
    }, [src, zoom])

    // keyboard shortcuts
    useEffect(() => {

        const keyboardShortcuts = e => {
            if (e.ctrlKey) {
                switch (e.key) {
                    case "z":
                        document.querySelector("#undo")?.click()
                        break;
                    case "y":
                        document.querySelector("#redo")?.click()
                        break;
                    case "s":
                        e.preventDefault();
                        document.querySelector("#save")?.click()
                        break;
                }
            }

            switch (e.key) {
                case "Delete":
                    document.querySelector("#delete")?.click()
                    break;
            }
        }

        window.addEventListener("keypress", keyboardShortcuts)

        return () => {
            window.removeEventListener("keypress", keyboardShortcuts);
        }

    }, [])

    // handle zoom change
    useEffect(() => {
        setDimensions(dimensions => {
            return {
                x: imageWidth * (zoom / 100),
                y: imageHeight * (zoom / 100)
            }
        })

        ImageAnnotation.registry.redrawAnnotations()
    }, [zoom])


    // handle screen dragging
    // useEffect(() => {
    //     const container = svgRef.current.parentElement;

    //     window._handleSvgClickDrag = window._handleSvgClickDrag || function (event) {
    //         if (event.buttons) {
    //             if (!ImageAnnotation.registry.handleIsBeingDragged()) {
    //                 container.scrollLeft += (-2 * event.movementX)
    //                 container.scrollTop += (-2 * event.movementY)
    //             }
    //         }
    //     }

    //     if (!!container) {
    //         container.removeEventListener("pointermove", window._handleSvgClickDrag) // remove any previous
    //         container.addEventListener("pointermove", window._handleSvgClickDrag)
    //     }

    // }, [svgRef])

    // load annotation defs
    useEffect(() => {
        if (svgRef.current && initializationAnnotations) {

            let currentAnnotation;
            for (let initAnnotation of initializationAnnotations) {
                currentAnnotation = new ImageAnnotation.registry({
                    svg: svgRef.current,
                    type: initAnnotation.properties.type,
                    metadata: initAnnotation.properties.metadata,
                    id: initAnnotation.id,
                    initPoints: initAnnotation.properties.points
                })

                // apply events
                for (let [eventName, callback] of Object.entries(events)) {
                    if (eventName === "select") {
                        currentAnnotation.on("select", payload => {
                            setSelectedAnnotation(payload.annotation)
                            callback(payload)
                        })
                    } else {
                        currentAnnotation.on(eventName, callback)
                    }
                }

            }
            // deselect all annotations so nothing is immediately editable
            ImageAnnotation.registry.deselect()
            // set the selected annotation to the last rendered annotation
            // setSelectedAnnotation(currentAnnotation)
        }
    }, [svgRef.current, initializationAnnotations])


    return (
        <div >
            {/* <IconButton
                icon={Crop}
                alt="crop"
                iconStyle={iconStyle}
                onClick={handleCropClick}
            /> */}
            <IconButton
                icon={ZoomOut}
                alt={"zoom in"}
                iconStyle={iconStyle}
                onClick={handleZoomChange("out")}
            />
            <IconButton
                icon={ZoomIn}
                alt={"zoom in"}
                iconStyle={iconStyle}
                onClick={handleZoomChange("in")}
            />


            {
                !!annotationClasses.length
                &&
                <select className="annotation-class-select" onChange={handleAnnotationClassChange}>
                    <option value={null} >Select Annotation Class</option>
                    {renderAnnotationClasses(annotationClasses)}
                </select>
            }

            {!selectedAnnotation && renderAnnotationTypes(currentAnnotationTypes)}
            {selectedAnnotation && renderActions(availableActions)}

            {/* <button onClick={clickMapper("undo")}>Undo</button>
            <button onClick={clickMapper("redo")}>Redo</button> */}

            {
                !!ImageAnnotation.registry._annotations?.length
                &&
                <IconButton
                    icon={Export}
                    alt={"Export All Annotations"}
                    iconStyle={iconStyle}
                    onClick={handleExportAll}
                />
            }



            <div className={`image-annotation-manager-container ${className}`} style={{ width: containerWidth, height: containerHeight, ...containerStyle }}>
                {/* {cropping && <canvas ref={cropCanvasRef} className="cropping-canvas"></canvas>} */}
                {
                    tileGrid
                        ? <div className="annotation-image-container" ref={imageRef} style={{ width: dimensions.x, height: dimensions.y }}>
                            <Grid cols={tileGrid.cols} rows={tileGrid.rows} style={{ width: "100%", height: "100%" }}>
                                {renderImageTiles()}
                            </Grid>
                        </div>
                        : <img
                            ref={imageRef}
                            className="annotation-image"
                            src={src}
                            width={dimensions.x}
                            height={dimensions.y}
                        />
                }

                <svg
                    ref={svgRef}
                    className="annotation-svg"
                    width={dimensions.x}
                    height={dimensions.y}
                    data-zoom={zoom}
                    data-basewidth={imageWidth}
                    data-baseheight={imageHeight}
                >

                </svg>
            </div>
        </div>
    )
}
