import React, { useContext, useState } from 'react'
import generateSharingLink from '../../js/generateSharingLink';
import { captureCurrentView } from '../../js/mapHelpers';

// ============================== STATE ==============================
import { ExplorerContext, DataPrepContext } from '../../state/explorer/explorerProvider';

// ============================== HOOKS ==============================
import { usePage } from '../../js/hooks';

// ============================== CHILDREN ==============================
import FormCompletion from '../dzyne_components/ui/forms/FormCompletion/FormCompletion';


export default function ShareSessionForm({ setShowShareModal, setLink }) {

    // STATE
    const page = usePage()
    const {state, getters, linkShortenerAPI} = useContext(page === "explorer" ? ExplorerContext : DataPrepContext);
    const [linkType, setLinkType] = useState("short")

    // EVENTS
    const handleSubmit = async e => {
        e.preventDefault();

        const fitToCurrentZoom = document.querySelector("#share-session-form-current-zoom").checked
        const prefix = document.querySelector("#share-session-form-prepend").value
        const includeFilters = document.querySelector("#share-session-form-filters").checked
        const includeStyling = document.querySelector("#share-session-form-styling").checked
        const includeHidden = document.querySelector("#share-session-form-hidden-layers").checked
        const includeViewLock = document.querySelector("#share-session-form-save-view").checked
        const customSlug = document.querySelector("#share-session-form-custom-slug")?.value

        let zoom;
        if(fitToCurrentZoom){            
            zoom = captureCurrentView(state.viewer)
            zoom = {
                ...zoom,
                type: "corners"
            }

        }

        const sharedLayers = []
        
        let currentLayer;
        for (let layer of state.layers){
            if(!includeHidden && !layer.isActive) continue;

            currentLayer = JSON.parse(JSON.stringify(layer));
            
            currentLayer.name = prefix + currentLayer.name

            if(!includeFilters) delete currentLayer.mapSettings.CQL_FILTER
            if(!includeStyling) delete currentLayer.mapSettings.styles
            if(!includeViewLock) delete currentLayer.lockedView

            sharedLayers.push(currentLayer);
        }

        const link = generateSharingLink(sharedLayers, zoom);

        if(customSlug){
            let response = await linkShortenerAPI.createShortLink(link, customSlug)
            if(response.error) response = await linkShortenerAPI.updateShortLink(link, customSlug) // if creation fails, slug may already exist, so try updating it.
            
            if (response.error){ // if still an error
                console.log("Could not create short link")
            } else {
                setLink(response.shortUrl)
            }
        } else {
            setLink(link)
        }

        // setShowShareModal(false)
    }

    const handleLinkTypeChange = e => {
        e.target.dataset.linktype === "short"
            ? setLinkType("short")
            : setLinkType("long")
    }

    const handleCancel = () => {
        setShowShareModal(false)
    }

    return (
        <form className="pt-2" onSubmit={handleSubmit}>
            <label htmlFor="share-session-form-prepend">Layer Prefix (optional)</label><br />
            <input id="share-session-form-prepend" type="text" placeholder="prefix..." />
            <sub className="block mt-1">adds a prefix to all layer names</sub><br />
            
            <hr/>

            <input id="share-session-form-current-zoom" type="checkbox" defaultChecked={true} />
            <label htmlFor="share-session-form-current-zoom">Fit to Current Zoom</label>

            <hr/>

            <input id="share-session-form-hidden-layers" type="checkbox" defaultChecked={true} />
            <label htmlFor="share-session-form-hidden-layers">Include Hidden Layers</label>

            <hr/>

            <input id="share-session-form-filters" type="checkbox" defaultChecked={true} />
            <label htmlFor="share-session-form-filters">Include Filters</label>

            <hr/>

            <input id="share-session-form-styling" type="checkbox" defaultChecked={true} />
            <label htmlFor="share-session-form-styling">Include Styling</label>

            <hr/>

            <input id="share-session-form-save-view" type="checkbox" defaultChecked={true} />
            <label htmlFor="share-session-form-view-lock">Include View Locks</label>

            <hr/>

            <input id="share-session-form-url-type-short" name="url-type" type="radio" data-linktype="short" defaultChecked={true} onChange={handleLinkTypeChange} />
            <label htmlFor="share-session-form-url-type" className="pr-2">Short URL</label>
            
            <input id="share-session-form-url-type-long" name="url-type" type="radio" data-linktype="long" defaultChecked={false} onChange={handleLinkTypeChange} />
            <label htmlFor="share-session-form-url-type">Long URL</label>

            {
                linkType === "short"
                &&
                <>                
                    <br/>
                    <input type="text" id="share-session-form-custom-slug" placeholder="custom url slug..." required/>
                </>
            }

            <FormCompletion submitButton cancelButton onCancel={handleCancel} submitName="Generate Link" />

        </form>
    )
}
