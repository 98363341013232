import React, { useContext, useState } from 'react'

// ================================= STATE =================================
import { ChangeDetectionContext } from '../../state/changeDetection/changeDetectionProvider'
import Center from '../dzyne_components/layout/Center/Center'
import SceneListItem from './SceneListItem'

export default function SceneList() {

    // STATE
    const { state } = useContext(ChangeDetectionContext)
    const [sortOrder, setSortOrder] = useState("stability-low-high")

    // EVENTS
    const handleSortingChange = e => {
        setSortOrder(e.target.value)
    }

    // RENDERERS
    const renderScenes = scenes => {
        if (!!scenes.length) {
            return scenes
                .sort((a, b) => {
                    switch (sortOrder) {
                        case "alphabetical":
                            return a.name > b.name ? 1 : -1
                        case "stability-low-high":
                            return a.score - b.score
                        case "stability-high-low":
                            return b.score - a.score
                        case "date-newest":
                            return new Date(a.end_time) > new Date(b.end_time) ? 1 : -1
                        case "date-oldest":
                            return new Date(a.end_time) > new Date(b.end_time) ? -1 : 1
                    }
                })
                .map(scene => (
                    <SceneListItem key={scene.nobID} scene={scene} />
                ))
        } else {
            return <Center>No Scenes Matching Criteria</Center>
        }
    }

    return (
        <div id="scene-list" className="dashboard-panel" style={{ gridArea: "scene-list"}}>

            <select onChange={handleSortingChange} value={sortOrder} defaultValue={"stability-high-low"}>
                <option value="stability-high-low">% Change (high-low)</option>
                <option value="stability-low-high">% Change (low-high)</option>
                <option value="alphabetical">A-Z</option>
                <option value="date-newest">By Date (newest first)</option>
                <option value="date-oldest">By Date (oldest first)</option>
            </select>
            <div>

                {renderScenes(state.filteredScenes)}
            </div>
        </div>
    )
}
