import { useState, useEffect, useContext } from "react";

// ====================== MODULE ======================
import {Redirect, useLocation} from 'react-router-dom'

// ====================== STATE ======================
import { DataPrepContext, ExplorerContext } from "../../state/explorer/explorerProvider";
import { UserContext } from "../../state/users/userProvider";

// ====================== CHILDREN ======================
import ExplorerMap from "./ExplorerMap";
import ExplorerSidebar from './ExplorerSidebar';
import ExplorerRibbon from './ExplorerRibbon';

import Grid from '../dzyne_components/layout/Grid/Grid';

// ====================== STYLES ======================
import './explorer.css';

// ====================== HELPERS ======================
import parseSharingLink from "../../js/parseSharingLink";
import { usePage } from "../../js/hooks";
import { explorerState } from "../../state/explorer/state";

export default function ExplorerContainer() {
    
    // STATE
    const page = usePage()
    const {state, setters, methods} = useContext(page === "explorer" ? ExplorerContext : DataPrepContext);
    const {state: userState, API: userAPI} = useContext(UserContext)
    const [colSpacing, setColSpacing] = useState("max(24vw,21rem) auto")
    const [rowSpacing, setRowSpacing] = useState("8fr 4fr")

    // INITIALIZE LAYERS FROM SHARED LINK
    useEffect(() => {
        const loadFromQueryString = async () => {
            const {shared: sharedLayers, zoom} = parseSharingLink()
            if(sharedLayers?.length){
                /* 
                    Place the base layers last so they are loaded towards the bottom of the imagery stack 
                    (initial imagery rendering goes from back to front of array). 
                    Note that it's important to keep relative order of the non-base layer images so those appear in the correct stacking.
                */
                const sortedLayers = [
                    ...sharedLayers.filter(l => l.layerType !== "base"), 
                    ...sharedLayers.filter(l => l.layerType === "base")
                ]
                sortedLayers?.length && await setters.addLayersFromSharingLink(sortedLayers);
            } else if (window._layers?.length && page === "explorer"){
                setters.addLayersFromSharingLink(window._layers)
            }
    
            if(zoom){
                setters.setGlobalSettings_defaultLocation(zoom)
            }
        }

        loadFromQueryString();

    }, [])

    // LAYER MOVEMENT KEYPRESS
    useEffect(() => {

        const handleArrowKeyClick = e => {
            if(e.key === "ArrowUp"){
                methods.moveSelectedLayer(e.ctrlKey ? "top" : "up")
            } else if (e.key === "ArrowDown"){
                methods.moveSelectedLayer(e.ctrlKey ? "bottom" : "down")
            }
        }

        window.addEventListener("keydown", handleArrowKeyClick)

        return () => {
            window.removeEventListener("keydown", handleArrowKeyClick)
        }

    }, [])

    if(page === "data-preparation" && !userState.user) return <Redirect to="/login" />

    return (
        <div id="explorer-container" className="content-container">
            <Grid 
                className="w-full h-full"
                cols={2} 
                colSpacing={colSpacing}

                rows={2} 
                rowSpacing={rowSpacing}
                areas={[
                    ["sidebar", "map"], 
                    ["sidebar", state.showRibbon ? "ribbon" : "map"]
                ]}

                style={{maxWidth: "100vw"}}
            >
                <ExplorerSidebar {...{user: userState.user, userAPI, page}} />
                <ExplorerMap {...{user: userState.user, page}}/>
                {state.showRibbon && <ExplorerRibbon {...{user: userState.user, page}} />}
            </Grid>
        </div>
    )
}
