import React, { useContext, useEffect, useState } from 'react'

// ================================ STATE ================================
import { ExplorerContext, DataPrepContext } from '../../state/explorer/explorerProvider';
// ================================ HOOKS ================================
import { usePage } from '../../js/hooks';

// ================================ CHILDREN ================================
import InlineButton from '../dzyne_components/ui/buttons/InlineButton/InlineButton';
import FormElementWrapper from "../dzyne_components/ui/forms/FormElementWrapper/FormElementWrapper";

// ================================ HELPER UTILITIES ================================
import { paramStringParser } from '../../js/mapHelpers';

// ================================ CONFIG ================================
import config from '../../config.json'

const BASE_HOST = process.env?.REACT_APP_BASE_HOST || window._env_?.baseHost || config.baseHost || window.location.hostname
const GEOSERVER_HOST = process.env?.REACT_APP_GEOSERVER_HOST || window._env_?.geoserverHost || config.baseHost || window.location.hostname;
const GEOSERVER_PORT = process.env?.REACT_APP_GEOSERVER_PORT || window._env_?.geoserverPort || config.geoserverPort || 4013;
const OFFLINE_MAP_HOST = process.env.REACT_APP_OFFLINE_MAP_HOST || window._env_?.offlineMapHost || config.offlineMapHost || window.location.hostname
const OFFLINE_MAP_PORT = process.env.REACT_APP_OFFLINE_MAP_PORT || window._env_?.offlineMapPort || config.offlineMapPort || 4014

const Cesium = require("cesium");

// ================================ CONSTANTS ================================
const STOCK_BASE_LAYERS = {
    naturalEarth: `http://${window.location.hostname}:${config.cesiumBasePort}/Assets/Textures/NaturalEarthII/{x}/{y}/{z}.jpg`,
    google: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
    esri: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    osm: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    esriStreet: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
    klokantech: `http://${OFFLINE_MAP_HOST}:${OFFLINE_MAP_PORT}/styles/klokantech-basic/{z}/{x}/{y}.png`,
    osmBright: `http://${OFFLINE_MAP_HOST}:${OFFLINE_MAP_PORT}/styles/osm-bright/{z}/{x}/{y}.png`,
    positron: `http://${OFFLINE_MAP_HOST}:${OFFLINE_MAP_PORT}/styles/positron/{z}/{x}/{y}.png`,
    darkMatter: `http://${OFFLINE_MAP_HOST}:${OFFLINE_MAP_PORT}/styles/dark-matter/{z}/{x}/{y}.png`,
}

const LAYER_TYPES = {
    // data: "feature",

}

// ================================ HELPER COMPONENTS ================================
const FormContent = ({ formType }) => {

    // STATE
    const page = usePage()
    const { state, setters, methods } = useContext(page === "explorer" ? ExplorerContext : DataPrepContext);

    switch (formType) {
        case "dzyne_server":
            return (
                <FormElementWrapper labels={["Name", "IP Address", "Port Number", "Layer", "Additional Params"]} labelStyle={{ cursor: "pointer" }} >
                    <input key={"new-layer-name"} id="new-layer-name" type="text" required defaultValue="casi data" />
                    <input key={"new-layer-ip"} id="new-layer-ip" type="text" required defaultValue={BASE_HOST} />
                    <input key={"new-layer-port"} id="new-layer-port" type="number" required defaultValue={GEOSERVER_PORT} />
                    <input key={"new-layer-layer"} id="new-layer-layer" type="text" required defaultValue="casi:images" />
                    <input key={"new-layer-params"} id="new-layer-params" type="text" defaultValue="" placeholder="key=value&key=value&..." />
                </FormElementWrapper>
            )
        case "url":
            return (
                <FormElementWrapper labels={["Name", "URL"]}>
                    <input key={"new-layer-name"} id="new-layer-name" type="text" defaultValue={"OSM"} required />
                    <input key={"new-layer-url"} id="new-layer-url" type="text" defaultValue={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"} required />
                </FormElementWrapper>
            )
        case "stock_base":
            return (
                <FormElementWrapper labels={["Base Layers"]}>
                    <select id="new-layer-stock-base">
                        {/* <option value={STOCK_BASE_LAYERS.naturalEarth}>Natural Earth (low-res)</option> */}
                        <option value={STOCK_BASE_LAYERS.google}>Google Satellite (Internet)</option>
                        <option value={STOCK_BASE_LAYERS.esri}>ESRI Satellite (Internet)</option>
                        <option value={STOCK_BASE_LAYERS.osm}>OSM (Internet)</option>
                        <option value={STOCK_BASE_LAYERS.esriStreet}>ESRI Street (Internet)</option>
                        <option value={STOCK_BASE_LAYERS.klokantech}>Klokantech (Offline)</option>
                        <option value={STOCK_BASE_LAYERS.osmBright}>OSM Bright(Offline)</option>
                        <option value={STOCK_BASE_LAYERS.positron}>Positron (Offline)</option>
                        <option value={STOCK_BASE_LAYERS.darkMatter}>Dark Matter (Offline)</option>
                    </select>
                </FormElementWrapper>
            )
        case "terrain_url":
            return (
                <FormElementWrapper labels={["Name", "Terrain File Format", "URL"]}>
                    <input key={"new-terrain-name"} id="new-terrain-name" type="text" defaultValue={"Cesium Terrain"} required />
                    <select key={"new-terrain-type"} id={"new-terrain-type"}>
                        <option value="cesium">Cesium</option>
                        <option value="google">Google</option>
                        <option value="vr">VRTheWorld</option>
                    </select>
                    {/* default value below for testing */}
                    <input key={"new-terrain-url"} id="new-terrain-url" type="text" defaultValue={"https://api.maptiler.com/tiles/terrain-quantized-mesh-v2/?key=IYSObWa01iZnDAh4OSX7"} required />
                </FormElementWrapper>
            )
        case "depthGeoJson":
            return (
                <div>
                    <FormElementWrapper labels={["Depth GeoJSON File Upload"]}>
                        <input id="new-file-json" type="file" placeholder="file upload..." className='cursor-pointer' required />
                    </FormElementWrapper>
                    {
                        !!state.depthDataSource
                        &&
                        <div>
                            <InlineButton onClick={e => { e.preventDefault(); e.stopPropagation(); methods.zoomToDepthDetection() }}>Zoom To</InlineButton>
                            <input 
                                id="depth-entity-opacity" 
                                className='cursor-pointer'
                                type="range" 
                                min="0" max="1" step="0.01" 
                                defaultValue={1}
                                onMouseUp={e => { methods.changeDepthEntityOpacity(parseFloat(document.querySelector("#depth-entity-opacity").value)) }} 
                            />
                            <br/>
                            <InlineButton onClick={e => { e.preventDefault(); e.stopPropagation(); methods.clearDepthResults(); document.querySelector("#new-file-json").value = "" }}>clear</InlineButton>
                        </div>
                    }
                </div>
            )
        case "kml":
            return (
                <div>KML not yet implemented</div>
            )

    }
}

export default function FormContainer({ activeLayerType, setActiveLayerType }) {

    const page = usePage()
    const { setters, methods } = useContext(page === "explorer" ? ExplorerContext : DataPrepContext);
    const [formType, setFormType] = useState("dzyne_server");

    // EVENTS
    const handleFormTypeChange = e => {
        setFormType(e.target.value)
    }

    const handleSubmit = async e => {
        e.preventDefault()

        let nameElement,
            urlElement,
            results

        switch (formType) {
            case "dzyne_server":
                nameElement = document.querySelector("#new-layer-name");
                const ipElement = document.querySelector("#new-layer-ip");
                const portElement = document.querySelector("#new-layer-port");
                const layerElement = document.querySelector("#new-layer-layer");
                const paramsElement = document.querySelector("#new-layer-params")

                const additionalParams = paramStringParser(paramsElement.value);
                
                results = await setters.appendLayer({
                    layerType: LAYER_TYPES[activeLayerType] ?? activeLayerType,
                    type: "dzyne_server",
                    name: nameElement.value,
                    ip: ipElement.value,
                    port: portElement.value,
                    layerLocation: layerElement.value,
                    mapSettings: additionalParams ?? {},
                    aoi: { type: "none" }
                })

                if (results.success) {
                    nameElement.value = "";
                    ipElement.value = BASE_HOST;
                    portElement.value = GEOSERVER_PORT;
                    layerElement.value = "casi:images";
                    paramsElement.value = "";
                }
                break;
            case "url":
                nameElement = document.querySelector("#new-layer-name");
                urlElement = document.querySelector("#new-layer-url");

                results = await setters.appendLayer({
                    layerType: LAYER_TYPES[activeLayerType] ?? activeLayerType,
                    type: "url",
                    name: nameElement.value,
                    url: urlElement.value,
                    mapSettings: {},
                    aoi: { type: "none" }
                })

                if (results.success) {
                    nameElement.value = "OSM";
                    urlElement.value = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
                }
                break;
            case "stock_base":
                const stockSelect = document.querySelector("#new-layer-stock-base")
                const stockName = [...stockSelect.children].find(c => c.value === stockSelect.value)?.innerText
                results = setters.appendLayer({
                    layerType: activeLayerType,
                    type: "url",
                    name: stockName,
                    url: stockSelect.value,
                    mapSettings: {},
                    aoi: { type: "none" }
                })
                break;
            case "terrain_url":
                nameElement = document.querySelector("#new-terrain-name");
                urlElement = document.querySelector("#new-terrain-url");
                const terrainTypeElement = document.querySelector("#new-terrain-type");

                results = await setters.appendLayer({
                    layerType: activeLayerType,
                    type: "terrain_url",
                    terrainType: terrainTypeElement.value,
                    name: nameElement.value,
                    url: urlElement.value,
                    mapSettings: {}
                })

                if (results.success) {
                    nameElement.value = "";
                    urlElement.value = ""
                }

                // we append the rendered layer here because terrain layers only need to access the layer definitions and not the rendered layers.
                // Since they set the "layers", the below would cause an infinite loop if it were placed in the drawLayersOnMap method.
                setters.appendRenderedTerrain(results.layer.id, new Cesium.CesiumTerrainProvider({ url: results.layer.url }))
                break;
            case "depthGeoJson":
                console.log("DEPTH SUBMIT")
                const file = document.querySelector("#new-file-json")
                const reader = new FileReader()
                reader.readAsText(file.files[0], "UTF-8")
                reader.onload = function (e) {
                    let data
                    try {
                        data = JSON.parse(e.target.result)
                    } catch (err) {
                        data = null
                    }
                    if (data) {
                        methods.clearDepthResults()
                        methods.renderDepthResults(data, { autoZoom: true })
                    }
                }
                break;
        }

        if(activeLayerType === "data") {
            setActiveLayerType("feature")
        }

    }

    // RENDERERS
    const renderFormTypeOptions = (activeLayerType) => {
        let options = [];
        switch (true) {
            case activeLayerType === "base":
                options = [
                    { name: "Stock Base Layers", value: "stock_base" },
                    { name: "URL", value: "url" },
                ]
                break;
            case activeLayerType === "terrain":
                options = [
                    { name: "Terrain URL", value: "terrain_url" }
                ]
                break;
            case activeLayerType === "file":
                options = [
                    { name: "Depth GeoJSON", value: "depthGeoJson" },
                    { name: "KML", value: "kml" },
                ]
                break;
            default:
                options = [
                    { name: "Dzyne Server", value: "dzyne_server" },
                    { name: "URL", value: "url" }
                ]
        }

        return options.map(op => {
            return <option key={op.name} value={op.value} className="cursor-pointer">{op.name}</option>
        })
    }

    // ON LOAD
    useEffect(() => {
        switch (true) {
            case activeLayerType === "terrain":
                setFormType("terrain_url")
                break;
            case activeLayerType === "base":
                setFormType("stock_base")
                break;
            case activeLayerType === "file":
                setFormType("depthGeoJson")
                break;
            default:
                setFormType("dzyne_server")
        }

    }, [activeLayerType])



    return (
        <div>

            <select value={formType} onChange={handleFormTypeChange} className="cursor-pointer">
                {renderFormTypeOptions(activeLayerType)}
            </select>


            <form id="new-layer-form" onSubmit={handleSubmit}>
                <FormContent formType={formType} />

                <button className='btn bg-white text-neutral-1'>Add Layer</button>

            </form >

        </div>
    )
}
