import React, { useContext, useEffect, useState } from 'react'


// ================================= STATE =================================
import { ExplorerContext, DataPrepContext } from '../../state/explorer/explorerProvider'

// ================================= HOOKS =================================
import { usePage } from '../../js/hooks';

// ================================= CHILDREN =================================
import SidebarLayer from './SidebarLayer';
import Drawer from '../dzyne_components/layout/Drawer/Drawer';
import NewLayerForm from './NewLayerForm';
import IconButton from '../dzyne_components/ui/buttons/Iconbutton/IconButton';

// ================================= ICONS =================================
import ShareIcon from '../../assets/icons/share.svg'
import ShareModal from './ShareModal';
import Export from '../../assets/icons/export.svg'
import { useHistory } from 'react-router';
import Grid from '../dzyne_components/layout/Grid/Grid';
import DataPrepSessions from './dataPrep/DataPrepSessions';
import ServerLayerGroup from './ServerLayerGroup';

// ================================= STYLES =================================
const DRAWER_CONTAINER_STYLE = {
    maxWidth: "100%"
}

export default function ExplorerSidebar() {
    const page = usePage()
    const { state, setters, methods, getters, geoserverAPI} = useContext(page === "explorer" ? ExplorerContext : DataPrepContext);

    const [open, setOpen] = useState(true)
    const [activeLayerType, setActiveLayerType] = useState("data");
    const [showShareModal, setShowShareModal] = useState(false);
    const [filteredLayers, setFilteredLayers] = useState(state.layers);
    const [filterText, setFilterText] = useState("");
    const history = useHistory()

    // EVENTS
    const handleExportToDataPrepSession = () => {
        // this hard codes dataPrep localStorage change so it is available when we switch to a dataPrep session
        localStorage.setItem("dataPrep", JSON.stringify({ layers: state.layers }))
        history.push("/data-preparation")
    }

    const handleFilterChange = e => {
        setFilterText(e.target.value);
    }

    // RENDERERS
    const renderLayerType = (layerType, layers) => {
        layerType = layerType.split(",") // if comma separated layer types are indicated
        return layers.map(layer => {
            if (layerType.includes(layer.layerType)) {
                return (
                    <SidebarLayer
                        key={layer.id}
                        page={page}
                        layer={layer}
                        viewer={state.viewer}

                        toggleLayer={setters.toggleLayer}
                        setActiveBaseLayer={methods.setActiveBaseLayer}
                        setActiveTerrainLayer={methods.setActiveTerrainLayer}
                        deleteLayer={setters.deleteLayer}
                        deleteTerrainLayer={setters.deleteTerrainLayer}

                        selectedLayer={state.selectedLayer}
                        setSelectedLayer={setters.setSelectedLayer}
                        geoserverAPI={geoserverAPI}

                        toggleDateFilter={setters.toggleDateFilter}
                    />
                )
            }

            return null // layer type does not match, do no render
        })
    }

    const renderLayerHierarchy = (filteredLayers) => {
        const servers = {};
        filteredLayers.forEach(layer => {
            if(["data", "feature", "imagery"].includes(layer.layerType)) {
                if(layer.type === "dzyne_server") {
                    const serverAddr = `${layer.ip}:${layer.port}`
                    servers[serverAddr] = servers[serverAddr] ?? [];
                    servers[serverAddr].push(layer);
                } else if (layer.type === "url") {
                    // TODO: implement logic for extracting server from URL;
                }
            }
        })

        return Object.entries(servers).map(([serverName, layers]) => (
            <ServerLayerGroup {...{
                serverName, 
                layers, 
                page, 
                viewer: state.viewer,
                toggleLayer: setters.toggleLayer,
                deleteLayer: setters.deleteLayer,
                selectedLayer: state.selectedLayer,
                setSelectedLayer: setters.setSelectedLayer,
                geoserverAPI,
                toggleDateFilter: setters.toggleDateFilter
            }} />
        ))
    

    }

    // ON LOAD
    useEffect(() => {
        let re;
        try {
            re = new RegExp(filterText, "gi")
        } catch(err) {
            re = /.+/g
        }
        const matchedLayers = state.layers.filter(layer => {
            if(layer.layerType === "data") return true;
            return layer.name.match(re);
            // return !!re.match(layer.name)
        })
        setFilteredLayers(matchedLayers)
    }, [filterText, state.layers])


    return (
        <>
            <div
                className={`explorer-div explorer-sidebar-${open ? "open" : "closed"}`}
                style={{ gridArea: "sidebar", maxWidth: "100%", overflowX: "none" }}
            >
                {showShareModal && <ShareModal viewer={state.viewer} setShowShareModal={setShowShareModal} />}

                <Grid
                    rows={page === "explorer" ? 2 : 3}
                    rowSpacing={page === "explorer" ? "5fr 7fr" : "auto auto 5fr"}
                    style={{ height: "91.5vh", overflow: "hidden" }}
                >

                    {
                        page === "data-preparation"
                        &&
                        <DataPrepSessions />
                    }

                    <section className="sidebar-section">
                        <h3 className="text-lg">New Layer</h3>
                        <NewLayerForm activeLayerType={activeLayerType} setActiveLayerType={setActiveLayerType} />
                    </section>


                    <section className="sidebar-section">
                        <div className='flex pb-2'>

                            <h3 className="text-lg"> Active Layers </h3>
                            {
                                page === "explorer"
                                &&
                                <>
                                    <IconButton icon={ShareIcon} alt={"Share"} onClick={() => setShowShareModal(prev => !prev)} />
                                    <IconButton icon={Export} alt={"Export to Data Prep Session"} onClick={handleExportToDataPrepSession} />
                                </>
                            }

                        </div>
                        <input type="text" placeholder="layer search..." onChange={handleFilterChange}/>


                        <Drawer
                            title={<h4 className="inline">Terrain Layers<sub>({getters.getNumberOfLayerType("terrain")})</sub></h4>}
                            disabled={!getters.getNumberOfLayerType("terrain")}
                            defaultOpen={true}
                            containerStyle={DRAWER_CONTAINER_STYLE}
                        >
                            {renderLayerType("terrain", filteredLayers)}
                        </Drawer>

                        <Drawer
                            title={<h4 className="inline">Base Layers<sub>({getters.getNumberOfLayerType("base")})</sub></h4>}
                            disabled={!getters.getNumberOfLayerType("base")}
                            defaultOpen={true}
                            containerStyle={DRAWER_CONTAINER_STYLE}
                        >
                            {renderLayerType("base", filteredLayers)}
                        </Drawer>

                        <Drawer
                            title={<h4 className="inline">WMS Layers<sub>({getters.getNumberOfLayerType("feature,imagery,data")})</sub></h4>}
                            disabled={!getters.getNumberOfLayerType("feature,imagery,data")}
                            defaultOpen={true}
                            containerStyle={DRAWER_CONTAINER_STYLE}
                        >
                            {/* {renderLayerType("data,feature,imagery", filteredLayers)} */}
                            {renderLayerHierarchy(filteredLayers)}
                        </Drawer>

                        {/* <Drawer
                            title={<h4 className="inline">Data Layers<sub>({getters.getNumberOfLayerType("data")})</sub></h4>}
                            disabled={!getters.getNumberOfLayerType("data")}
                            defaultOpen={true}
                            containerStyle={DRAWER_CONTAINER_STYLE}
                        >
                            {renderLayerType("data", filteredLayers)}
                        </Drawer> */}

                    </section>
                </Grid>


            </div>
        </>
    )
}
