import React, { useContext, useState } from 'react'

// ================================== STATE ==================================
import { ExplorerContext, DataPrepContext } from '../../state/explorer/explorerProvider'

// ================================== HOOKS ==================================
import { usePage } from '../../js/hooks';

// ================================== CHILDREN ==================================
import FormCompletion from '../dzyne_components/ui/forms/FormCompletion/FormCompletion'

// ================================== HELPERS ==================================
import generateSharingLink from '../../js/generateSharingLink';
import { captureCurrentView } from '../../js/mapHelpers';

export default function ShareLayersForm({ setShowShareModal, setLink }) {

    // STATE
    const page = usePage()
    const { state, linkShortenerAPI } = useContext(page === "explorer" ? ExplorerContext : DataPrepContext);
    const [linkType, setLinkType] = useState("short")

    // EVENTS
    const handleCancel = () => {
        setShowShareModal(false)
    }

    const handleSubmit = async e => {
        e.preventDefault()

        const fitToCurrentZoom = document.querySelector("#share-session-form-current-zoom").checked
        const prefix = document.querySelector("#share-selected-form-prepend").value
        const includeFilters = document.querySelector("#share-selected-form-filters").checked
        const includeStyling = document.querySelector("#share-selected-form-styling").checked
        const includeViewLock = document.querySelector("#share-selected-form-save-view").checked
        const customSlug = document.querySelector("#share-selected-form-custom-slug")?.value

        let zoom;
        if (fitToCurrentZoom) {
            zoom = captureCurrentView(state.viewer)
            zoom = {
                ...zoom,
                type: "corners"
            }
        }

        const selectedIDs = [];
        [...document.querySelector("#shared-selected-form-layers").options].forEach(opt => {
            opt.selected && selectedIDs.push(opt.value)
        })

        const sharedLayers = [];

        let currentLayer;
        for (let layer of state.layers) {
            if (selectedIDs.includes(layer.id)) {
                currentLayer = JSON.parse(JSON.stringify(layer))

                currentLayer.name = prefix + currentLayer.name
                if (!includeFilters) delete currentLayer.mapSettings.CQL_FILTER
                if (!includeStyling) delete currentLayer.mapSettings.styles
                if (!includeViewLock) delete currentLayer.lockedView

                sharedLayers.push(currentLayer);
            }
        }

        const link = generateSharingLink(sharedLayers, zoom)

        if (customSlug) {
            let response = await linkShortenerAPI.createShortLink(link, customSlug)
            if (response.error) response = await linkShortenerAPI.updateShortLink(link, customSlug) // if creation fails, slug may already exist, so try updating it.

            if (response.error) { // if still an error
                console.log("Could not create short link")
            } else {
                setLink(response.shortUrl)
            }
        } else {
            setLink(link)
        }
        // setLink(link)
    }

    const handleLinkTypeChange = e => {
        e.target.dataset.linktype === "short"
            ? setLinkType("short")
            : setLinkType("long")
    }

    // RENDERERS
    const renderLayerOptions = layers => {
        return layers.map(layer => {
            return (
                <option key={layer.id} value={layer.id}>{layer.name}</option>
            )
        })
    }

    return (
        <form className="pt-2" onSubmit={handleSubmit}>
            <label htmlFor="share-selected-form-prepend">Layer Prefix (optional)</label><br />
            <input id="share-selected-form-prepend" type="text" placeholder="prefix..." />
            <sub className="block mt-1">adds a prefix to all layer names</sub><br />

            <hr />

            <label htmlFor="shared-selected-form-layers">Layer Selection</label><br />
            <select id="shared-selected-form-layers" multiple required>
                {renderLayerOptions(state.layers)}
            </select>

            <hr />

            <input id="share-session-form-current-zoom" type="checkbox" defaultChecked={true} />
            <label htmlFor="share-session-form-current-zoom">Fit to Current Zoom</label>

            <hr />

            <input id="share-selected-form-filters" type="checkbox" defaultChecked={true} />
            <label htmlFor="share-selected-form-filters">Include Filters</label>

            <hr />

            <input id="share-selected-form-styling" type="checkbox" defaultChecked={true} />
            <label htmlFor="share-selected-form-styling">Include Styling</label>

            <hr />

            <input id="share-selected-form-save-view" type="checkbox" defaultChecked={true} />
            <label htmlFor="share-selected-form-view-lock">Include View Locks</label>

            <hr />

            <input id="share-session-form-url-type-short" name="url-type" type="radio" data-linktype="short" defaultChecked={true} onChange={handleLinkTypeChange} />
            <label htmlFor="share-session-form-url-type" className="pr-2">Short URL</label>

            <input id="share-session-form-url-type-long" name="url-type" type="radio" data-linktype="long" defaultChecked={false} onChange={handleLinkTypeChange} />
            <label htmlFor="share-session-form-url-type">Long URL</label>

            {
                linkType === "short"
                &&
                <>
                    <br />
                    <input type="text" id="share-selected-form-custom-slug" placeholder="custom url slug..." required />
                </>
            }


            <FormCompletion submitButton cancelButton submitName={"Generate Link"} onCancel={handleCancel} />
        </form>
    )
}
