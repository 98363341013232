import { useState, useEffect } from 'react';
import createAnnotationRegistry from './Annotation3D'

// =========================== CHILDREN ===========================
import IconButton from "../../../ui/buttons/Iconbutton/IconButton"
import Grid from '../../../layout/Grid/Grid'

// =========================== MEDIA ===========================
const Point = require('../../../assets/media/point.svg')
const Multipoint = require('../../../assets/media/multipoint.svg')
const Polyline = require('../../../assets/media/polyline.svg')
const Polygon = require('../../../assets/media/polygon.svg')
const Circle = require('../../../assets/media/circle.svg')
const Rectangle = require('../../../assets/media/rectangle.svg')

const Save = require('../../../assets/media/save.svg');
const Cancel = require('../../../assets/media/remove_circle_outline.svg');
const Delete = require('../../../assets/media/trash.svg');
const Edit = require('../../../assets/media/edit.svg');
const Undo = require('../../../assets/media/undo.svg');
const Redo = require('../../../assets/media/redo.svg');
const Export = require('../../../assets/media/export.svg')


/* 
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::::::::::::::::::::: CONFIGURATION ::::::::::::::::::::::::::::::::
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
*/


const TYPE_MAP = {
    point: { icon: Point.default },
    multipoint: { icon: Multipoint.default },
    polyline: { icon: Polyline.default },
    polygon: { icon: Polygon.default },
    circle: { icon: Circle.default },
    rectangle: { icon: Rectangle.default },
}

const ACTION_MAP = {
    save: {
        title: "Save annotation",
        icon: Save.default
    },
    cancel: {
        title: "Revert to last save",
        icon: Cancel.default
    },
    edit: {
        title: "Edit properties",
        icon: Edit.default
    },
    delete: {
        title: "Delete annotation",
        icon: Delete.default
    },
    undo: {
        title: "Undo last annotation",
        icon: Undo.default
    },
    redo: {
        title: "Redo last annotation",
        icon: Redo.default
    },
    export: {
        title: "Export geometry",
        icon: Export.default
    }

}


/* 
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
:::::::::::::::::::::::::::::::: ANNOTATION MANAGER COMPONENT ::::::::::::::::::::::::::::::::
::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
*/

export default function AnnotationManager3D({
    viewer = null,
    annotationRegistryID = null,
    restoreFromRegistry = false,
    autoSelectIndex=null,
    removeAnnotationsOnUnmount = false,
    initializationAnnotations = null,
    availableTypes = ["point", "multipoint", "polyline", "polygon", "circle", "rectangle"],
    availableActions = ["save", "cancel", "edit", "delete", "undo", "redo", "export"],
    maxAnnotations = 0,
    defaultMetadata = {},
    exportFormat="wkt",
    events = {},
    orientation = "horizontal",
    iconStyle = { height: "1rem", width: "30px" },
    style = {},
}) {

    // STATE
    const [Annotation3D, setAnnotation3D] = useState({registry: createAnnotationRegistry(annotationRegistryID)})
    const [annotationIDs, setAnnotationIDs] = useState([])
    const [selectedAnnotation, setSelectedAnnotation] = useState(null)
    const [initialHistoryIndex, setInitialHistoryIndex] = useState(null)
    const [maxAnnotationsReached, setMaxAnnotationsReached] = useState(false)

    // EVENTS
    const handleNewAnnotation = type => () => {
        const annotation = new Annotation3D.registry({
            viewer,
            type,
            metadata: defaultMetadata,
            createCallback: events.create ?? function(payload){},
        })

        // apply events to each annotation object
        for (let [eventName, callback] of Object.entries(events)) {
            annotation.on(eventName, callback)
        }


        viewer.canvas.style.cursor = "cell"

        setSelectedAnnotation(annotation);
        setInitialHistoryIndex(annotation._history.length - 1)
        setAnnotationIDs(annotationIDs => {
            annotationIDs = [...annotationIDs]
            annotationIDs.push(annotation._id);
            return annotationIDs
        })

    }

    const saveAnnotation = () => {
        viewer.canvas.style.cursor = "auto"
        selectedAnnotation._saveInstance()
        Annotation3D.registry.deselect()
        setSelectedAnnotation(null)
        setInitialHistoryIndex(null)
    }

    const cancelChanges = () => {
        selectedAnnotation?.revert(initialHistoryIndex)
    }

    const deleteAnnotation = () => {
        viewer.canvas.style.cursor = "auto"
        const annotationID = selectedAnnotation?._id
        Annotation3D.registry.delete(annotationID)

        setAnnotationIDs(annotationIDs => annotationIDs.filter(id => id !== annotationID))
        setSelectedAnnotation(null)
        setInitialHistoryIndex(null)
    }

    const undoAnnotation = () => {
        selectedAnnotation?.undo()
    }

    const redoAnnotation = () => {
        selectedAnnotation?.redo()
    }

    const exportGeometry = () => {
        selectedAnnotation?.exportGeometry({output: exportFormat})
    }

    const handleActionClick = action => () => {
        switch (action) {
            case "save":
                return saveAnnotation()
            case "cancel":
                return cancelChanges()
            case "delete":
                return deleteAnnotation()
            case "edit":
                return
            case "undo":
                return undoAnnotation()
            case "redo":
                return redoAnnotation()
            case "export":
                return exportGeometry()
        }
    }

    // RENDERERS
    const renderAnnotationTypes = availableTypes => {
        return availableTypes.map(type => {

            return (
                <IconButton
                    onClick={handleNewAnnotation(type)}
                    icon={TYPE_MAP[type].icon}
                    containerStyle={iconStyle}
                    alt={type}
                    iconStyle={iconStyle}
                />
            )
        })
    }

    const renderActions = () => {
        return availableActions.map(action => {
            return (
                <IconButton
                    key={action}
                    id={action}
                    icon={ACTION_MAP[action].icon}
                    alt={ACTION_MAP[action].title}
                    onClick={handleActionClick(action)}
                    iconStyle={iconStyle}
                />
            )
        })
    }


    // ON LOAD
    // Create or Load Annotation Registry
    useEffect(() => {
        const registry = Annotation3D?.registry || createAnnotationRegistry(annotationRegistryID);     
        restoreFromRegistry && registry.restore()
        const annotations = registry.getAnnotations();

        if (!!annotations) {
            for(let annotation of annotations){
                for (let [eventName, callback] of Object.entries(events)) {
                    annotation.on(eventName, callback)
                }
            }
        } else if (!!initializationAnnotations) { // if there are no existing annotations and we are provided with points to initialize from
            let annotation
            for (let definition of initializationAnnotations){
                annotation = new registry({viewer, initializationPoints: definition.points, metadata: defaultMetadata, ...definition, })
                
                for (let [eventName, callback] of Object.entries(events)) {
                    annotation.on(eventName, callback)
                }
            }
            registry.deselect()
        }

        if(autoSelectIndex !== null){
            // if autoSelectIndex is set to some number, check to see if that index exists and if it does, select that annotation and setSelectedAnnotation in this component
            const annotation = annotations?.[autoSelectIndex];
            if(annotation) {                
                setSelectedAnnotation(registry.selectByID(annotation._id))
            }
        }
        setAnnotation3D({registry})

        // load annotation ids from the registry
        setAnnotationIDs(registry._annotations?.map(annotation => annotation._id) || [])

        // handle component unmount
        return () => {
            // deselect any currently selected annotations
            registry.deselect()

            if(removeAnnotationsOnUnmount){
                // if specified, delete annotations before unmounting
                registry?._annotations?.forEach(annotation => {
                    registry.delete(annotation._id)
                })
            }
        }
    }, [])

    // setup keyboard shortcuts
    useEffect(() => {
        const handleKeyboardShortcuts = e => {
            switch (e.key) {
                case "z":
                    e.ctrlKey && document.querySelector("#undo")?.click()
                    break;
                case "y":
                    e.ctrlKey && document.querySelector("#redo")?.click()
                    break;
            }
        }

        window.addEventListener("keypress", handleKeyboardShortcuts)
        return () => {
            window.removeEventListener("keypress", handleKeyboardShortcuts)
        }
    }, [])

    // handle selecting annotations by click
    useEffect(() => {

        if (!!selectedAnnotation) {
            viewer.canvas.removeEventListener("pointerup", viewer.canvas._clickToSelectEvent)
            viewer.canvas.style.cursor = "cell"
        } else {
            // We define the event handle function here and assign it to some property (_clickToSelectEvent) on the canvas. 
            // We do this so we have identity reference on this useEffect is called again, and we can remove the event listener
            viewer.canvas._clickToSelectEvent = event => {
                
                const position = { x: event.offsetX, y: event.offsetY }
                // check to see if there is an entity where the user clicked
                const entity = viewer.scene.pick(position)
                if (entity?.id?._id) {
                    const annotation = Annotation3D.registry.selectByID(entity?.id?._id)
                    setSelectedAnnotation(annotation)

                }
            }

            viewer.canvas.addEventListener("pointerup", viewer.canvas._clickToSelectEvent)
            viewer.canvas.style.cursor = "default"
        }

        return () => {
            viewer.canvas.removeEventListener("pointerup", viewer.canvas._clickToSelectEvent)
            delete viewer.canvas._clickToSelectEvent
        }

    }, [selectedAnnotation])

    useEffect(() => {
        if (maxAnnotations > 0) {
            setMaxAnnotationsReached(annotationIDs.length >= maxAnnotations)
        }        
    }, [annotationIDs])



    return (
        <div className="annotation-manager" style={style}>
            
            {
                !!selectedAnnotation
                &&
                (
                    <Grid cols={orientation === "horizontal" ? 7 : 1} style={{ width: "fit-content" }}>
                        {renderActions()}
                    </Grid>
                )
            }
            {
                !selectedAnnotation && !maxAnnotationsReached
                &&
                (
                    <Grid cols={orientation === "horizontal" ? availableTypes.length : 1} style={{ width: "fit-content" }}>
                        {renderAnnotationTypes(availableTypes)}
                    </Grid>
                )
            }

            
        </div>
    )
}

