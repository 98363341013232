export const extractBboxFromSrc = (url) => {
    const params = url.split("&")
    for(let param of params){
        if(/^bbox=/i.test(param)){
            const [lng1, lat1, lng2, lat2] = param.match(/\-?\d+\.\d+/g).map(num => parseFloat(num))
            return {lng1, lat1, lng2, lat2, deltaLat: Math.abs(lat1 - lat2), deltaLng: Math.abs(lng1 - lng2)}
        }
    }
}

export const formatTileRequest = (src, index, tileGrid) => {
    const {bbox} = tileGrid
    const [col, row] = index

    const lng1 = bbox.lng1 + (col * tileGrid.lngWidth),
          lat1 = bbox.lat2 - ((row + 1) * tileGrid.latHeight),
          lng2 = bbox.lng1 + ((col + 1) * tileGrid.lngWidth),
          lat2 = bbox.lat2 - (row * tileGrid.latHeight)

    src = src.split("&").map(param => {
        switch(true){
            case /^bbox=/i.test(param):
                return `bbox=${lng1},${lat1},${lng2},${lat2}`
            case /^width=/i.test(param):
                return `width=${tileGrid.tileWidth}`
            case /^height=/i.test(param):
                return `height=${tileGrid.tileHeight}`
            default:
                return param
        }
    }).join("&")

    return src
}