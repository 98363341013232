import React, { useContext, useEffect, useState } from 'react'

// ============================ STATE ============================
import { DataPrepContext } from '../../../state/explorer/explorerProvider'

// ============================ UTILS ============================
import { extractWKTString, minMaxCoords2WKTBounds, wktRect2Bbox } from '../../dzyne_components/map/utils';

// ============================ DZYNE COMPONENTS ============================
import TabGroup from '../../dzyne_components/ui/buttons/TabGroup/TabGroup';

// ============================ ICONS ============================
import Export from "../../dzyne_components/assets/media/export.svg";

// ============================ CONFIG ============================
import config from '../../../config.json';
import DetectorGroup from './DetectorGroup';
import HintModal from '../../dzyne_components/ui/modals/HintModal/HintModal';
import Center from '../../dzyne_components/layout/Center/Center';

const LABELING_UI_PROTOCOL = window._env_?.labelingUIProtocol ?? config.labelingUIProtocol ?? config.defaultProtocol ?? "http";
const LABELING_UI_HOST = window._env_?.labelingUIHost ?? config.labelingUIHost ?? window.location.hostname;
const LABELING_UI_PORT = window._env_?.labelingUIPort ?? config.labelingUIPort ?? 3010;
const LABELING_UI_SLUG = window._env_?.labelingUISlug ?? config.labelingUISlug ?? "/label"

const TRAINING_UI_BASE = `${LABELING_UI_PROTOCOL}://${LABELING_UI_HOST}:${LABELING_UI_PORT}${LABELING_UI_SLUG}`;

export default function SubmitSessionJob({ session, setShowSubmitForm }) {
    const { state, getters, dataPrepAPI } = useContext(DataPrepContext);
    const [detectors, setDetectors] = useState([]);
    const [labelSessions, setLabelSessions] = useState([]);
    const [jobType, setJobType] = useState("detection");
    const [jobName, setJobName] = useState("")
    const [selectedDetectors, setSelectedDetectors] = useState([])
    const [selectedBounds, setSelectedBounds] = useState(null)
    const [selectedLabelSession, setSelectedLabelSession] = useState(null);
    const [submissionStatus, setSubmissionStatus] = useState(null)

    // EVENTS
    const toggleDetector = detector => e => {
        setSelectedDetectors(sd => {
            return sd.includes(detector) ? sd.filter(d => d !== detector) : [...sd, detector];
        })
    }

    const handleNameChange = (e) => {
        setJobName(e.target.value)
    }

    const handleBoundsChange = e => {
        setSelectedBounds(e.target.value)

    }

    // HELPERS
    const getBounds = async () => {
        const imageData = (await Promise.all(session.images.map(image => dataPrepAPI.getImage(image)))).filter(i => !!i)
        const rawCoords = imageData.map(image => image.bbox)

        let lats = Array.from({ length: rawCoords.length })
        let lngs = Array.from({ length: rawCoords.length })
        rawCoords.forEach((c, i) => {
            lngs[i] = [c[0], c[2]]
            lats[i] = [c[1], c[3]]
        })
        lats = lats.flat();
        lngs = lngs.flat();

        const [latMin, latMax] = [Math.min(...lats), Math.max(...lats)]
        const [lngMin, lngMax] = [Math.min(...lngs), Math.max(...lngs)]
        const bounds = minMaxCoords2WKTBounds({ latMin, latMax, lngMin, lngMax })

        return new Promise(resolve => resolve(bounds));
    }

    const handleDetectionClick = async e => {
        e.stopPropagation()
        if (session.images.length < 1) {
            alert("You do not any any images selected for this job. Please ensure that you have imagery selected in the \"Image Preview\" tab. Selected images will be marked with an orange checkbox.")
            return;
        }
        setSubmissionStatus({ message: "Processing...", color: "var(--primaryAccent-2)" })
        // const bounds = await getBounds();
        const bounds = document.querySelector("#bounds-select").value
        let requestBody;
        for (let detectorID of selectedDetectors) {
            const detector = detectors.find(d => d.id === detectorID);
            if (!detector) continue;
            requestBody = {
                start_time: session.start_time,
                end_time: session.end_time,
                bounds,
                images: session.images.map(image => typeof image === "string" ? image : image?.image_catid),
                detector_name: detector?.name,
                detector_id: detector?.id,
                name: `${detector?.name} - ${jobName} - ts ${Date.now()}`,
                description: `${detector?.name} - ${session.description}`,
                session_id: session.id
            }

            const response = await dataPrepAPI.submitDetectionTask(requestBody)
            dataPrepAPI.getTasksBySessionID(session.id)
            if (response) {
                setShowSubmitForm(false)
            } else {
                setSubmissionStatus({ message: "An error occurred while processing this request", color: "red" })
            }
        }
    }

    const handleChangeDetectionClick = async e => {
        e.stopPropagation();
        setSubmissionStatus({ message: "Processing...", color: "var(--primaryAccent-2)" })
        // const bounds = document.querySelector("#bounds-select").value
        const bounds = selectedBounds

        // if (!bounds) bounds = await getBounds();

        const bbox = wktRect2Bbox(bounds)
        if (bbox) {
            const body = {
                name: `${jobName}`,
                description: `${session.description}`,
                bbox
            }
            const area = await dataPrepAPI.createAnalysisArea(body)
            if (area) {
                const analyzeSuccess = await dataPrepAPI.executeAreaAnalysis(area.id)
                analyzeSuccess
                    ? setShowSubmitForm(false)
                    : setSubmissionStatus({ message: "Change detection task created, but analysis could not run", color: "red" })
            } else {
                setSubmissionStatus({ message: "There was a problem creating this change detection task", color: "red" })
            }
        }
    }

    const handleLabelSessionClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const token = window[window._tokenAccessor];
        const selectedImageLayers = []
        let layer;
        for (let id of session.images) {
            layer = getters.getLayerByCatID(id)
            if (!!layer) {
                selectedImageLayers.push(layer)
            }
        }
        window.open(`${TRAINING_UI_BASE}?layers=${JSON.stringify(session.images)}&token=${token}`, "__blank");
    }


    const handleSelectedLabelSessionChange = sessionID => {
        setSelectedLabelSession(labelSessions.find(ls => ls.id == sessionID))
    }

    const handleTrainClick = async e => {
        e.stopPropagation();
        e.preventDefault();

        const images = selectedLabelSession
            .session_data
            .labelPairs
            .map(pair => ((!!pair.srcCatID && !!pair.labelCatID) ? { source: pair.srcCatID, label: pair.labelCatID } : null))
            .filter(pair => !!pair)

        const payload = {
            name: jobName,
            start_time: new Date().toISOString(),
            end_time: new Date().toISOString(),
            detector_id: selectedDetectors[0],
            output_detector_name: jobName,
            images,
            session_id: session.id,
        }
        const success = await dataPrepAPI.submitTrainingJob(payload)
        success
            ? setShowSubmitForm(false)
            : setSubmissionStatus({ message: "", color: "red" })

    }

    // RENDERERS
    const renderDetectors = (detectors, parent_id = null, level = null) => {
        const groups = {}
        detectors.forEach(d => { groups[d.type] = detectors.filter(dt => dt.type === d.type) })

        return Object.entries(groups).map(([type, detectors]) => (
            <DetectorGroup key={type} type={type} detectors={detectors} selectedDetectors={selectedDetectors} toggleDetector={toggleDetector} />
        ))
    }

    const renderSessionRegions = (regions) => {
        if (!regions) return <></>
        let options = [<option key={"region-select"} value={null}>Select Bounds</option>]

        // need logic here that could handle multiple anchors from different layers
        if (!!session.session_data?.anchorImage) {
            options.push(<option key={session.session_data.anchorImage.catid} value={session.session_data.anchorImage.bounds}>Anchor Image</option>)
        }

        session.session_data?.layers?.forEach(layer => {
            if (layer.mapSettings?.CQL_FILTER) {
                const wkt = extractWKTString(layer.mapSettings.CQL_FILTER)
                !!wkt && options.push(<option key={layer.id} value={wkt} >AOI: {layer.name}</option>)
            }
        })

        regions?.forEach(region => {
            options.push(<option key={region.id} value={region.geometry}>Region: {region.name}</option>)
        })

        return options
    }

    const renderLabelSessionOptions = (labelSessions) => {
        let sessions = labelSessions.map(ls => {
            return (
                <option key={ls.id} value={ls.id}>{ls.name}</option>
            )
        })


        sessions.unshift(<option key={-1} value={null}>Select a Label Session</option>)
        return sessions

    }

    // ON LOAD
    useEffect(() => {
        const exec = async () => {
            setDetectors(await dataPrepAPI.getAvailableDetectors())
            setLabelSessions(await dataPrepAPI.getLabelSessions())
        }
        exec();
    }, [])



    return (
        <form onSubmit={e => e.preventDefault()}>
            <h3 className='text-xl font-bold m-0 capitalize'>
                {jobType === "detection" && "Run Detection Job"}
                {jobType === "labeling" && "Create Labeling Session"}
                {jobType === "training" && "Run Training Job"}
            </h3>
            <h5 className='m-0'>{session.name}</h5>
            <TabGroup
                className="my-2"
                tabs={["detection", "labeling", "training"]}
                onClick={(tab) => { setJobType(tab) }}

            />
            {/* ============================================= DETECTOR JOB FORM ============================================= */}
            {
                jobType === "detection"
                &&
                <>
                    <input type="text" placeholder="Job Name" value={jobName} onChange={handleNameChange} required />
                    <hr />
                    <div>
                        <h3 className="text-xl font-bold">Detectors</h3>
                        <div className='overflow-y-scroll' style={{ maxHeight: "30vh" }}>
                            {renderDetectors(detectors)}
                        </div>
                        <hr />
                        <h3 className="text-xl font-bold">Bound Selection</h3>
                        <hr />
                        <select id="bounds-select" required onChange={handleBoundsChange}>
                            {renderSessionRegions(session.session_data?.regions)}
                        </select>
                        <h3 className="text-xl font-bold">Job Type</h3>
                        <button
                            className='mr-4 p-2 text-white text-xl rounded bg-neutral2 hover:shadow-lg hover:shadow-slate-900'
                            style={(!selectedDetectors.length || !session.images.length) ? { opacity: 0.5, cursor: "not-allowed" } : {}}
                            onClick={handleDetectionClick}
                            disabled={!selectedDetectors.length || !session.images.length}
                        >
                            Detections
                        </button>
                        {/* <button
                    className='p-2 mr-4 text-white text-xl rounded bg-neutral2 disabled'
                    style={!selectedBounds || !selectedDetectors.length ? { opacity: 0.5, cursor: "not-allowed" } : {}}
                    title={!selectedBounds || !selectedDetectors.length ? "Requirements not satisfied: You must have a name, at least 1 detector, and a bound selected" : ""}
                    onClick={handleChangeDetectionClick}
                    disabled={!selectedBounds || !selectedDetectors.length}
                >
                    Change Detection
                </button> */}
                        <br />
                    </div>
                </>
            }

            {/* ============================================= LABELING JOB FORM ============================================= */}
            {
                jobType === "labeling"
                &&

                <button
                    className='p-2 mr-4 text-white text-xl rounded bg-neutral2 disabled:opacity-50 disabled:cursor-not-allowed'
                    onClick={handleLabelSessionClick}
                    disabled={!session.images.length}
                    style={!session.images.length ? { opacity: 0.5, cursor: "not-allowed" } : {}}
                >
                    <img src={Export} className='inline-block' /> To Labeling Session
                </button>
            }

            {/* ============================================= TRAINING JOB FORM ============================================= */}
            {
                jobType === "training"
                &&
                <>
                    <h3 className="text-xl font-bold">Model Name</h3>
                    <input type="text" placeholder="New Model Name" value={jobName} onChange={handleNameChange} required />

                    <hr className='my-1' />
                    <h3 className="text-xl font-bold">Base Detector</h3>
                    {renderDetectors(detectors)}

                    <hr className='my-1' />
                    <h3 className="text-xl font-bold">Label Sessions</h3>
                    <select className='cursor-pointer' value={selectedLabelSession?.id} onChange={e => handleSelectedLabelSessionChange(e.target.value)}>
                        {renderLabelSessionOptions(labelSessions)}
                    </select>

                    <hr className='my-1' />

                    <button
                        className='mr-4 p-2 text-white text-xl rounded bg-neutral2 hover:shadow-lg hover:shadow-slate-900'
                        style={(!selectedDetectors.length || !session.images.length) ? { opacity: 0.5, cursor: "not-allowed" } : {}}
                        onClick={handleTrainClick}
                        disabled={!selectedDetectors.length || selectedLabelSession === null}
                    >
                        Train Model
                    </button>
                </>

            }
            <br />
            {submissionStatus && <h3 style={{ color: submissionStatus.color }}>{submissionStatus.message}</h3>}
            {session.images.length === 0
                ? <em className='text-red-500 font-bold text-xl'>Warning: You have no images selected for this session</em>
                : (
                    <em className='text-green-500 font-bold text-xl'>
                        {session.images.length} image(s) selected for this job
                        <HintModal
                        wrapperStyle={{display: "inline-block", cursor: "pointer"}}
                            content={session.images.map(image => <div>{image}</div>)}
                            hoverTimer={250}
                        >
                            <super className='inline-block ml-1 w-6 h-6 text-center rounded-full text-gray-400 border-2 border-gray-400'><Center>?</Center></super>
                        </HintModal>
                    </em>
                )
            }

        </form>
    )
}
