import React from 'react';

import './FormCompletion.css'

const FormCompletion = ({
    submitButton = true,
    cancelButton = false,
    submitName = "Submit",
    onCancel = null,
    cancelName = "Cancel",
    className="",
}) => {

    return (
        <div className={`form-completion ${className}`}>
            {
                submitButton
                &&
                <input type="submit" value={submitName} />
            }

            {
                cancelButton
                &&
                <button onClick={onCancel}>{cancelName}</button>
            }
        </div>
    )
}

export default FormCompletion;