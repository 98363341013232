import React, { useState, useEffect } from 'react'

// ============================= DZYNE COMPONENTS ============================= 
import Modal from '../dzyne_components/ui/modals/Modal/Modal'
import Center from '../dzyne_components/layout/Center/Center';

// =========================== ICONS ===========================
import PlayIcon from '../../assets/icons/play_circle_outline_black.svg'
import Export from "../dzyne_components/assets/media/export.svg";

// ============================= CONFIG ============================= 
import config from "../../config.json";

const USER_GUIDE_LINK = window._env_?.userGuideLink ?? config.userGuideLink ?? "";
const TUTORIALS = window._env_?.tutorials ?? config.tutorials ?? [];

// ============================= CHILDREN ============================= 
const TutorialCard = ({ tutorial }) => {
    const handleTutorialClick = () => {
        window.open(tutorial.route, "_blank")
    }
    return (
        <div className='relative grid grid-cols-12 mb-2 p-2 text-lg shadow-md rounded-md bg-gray-200'>

            <div className='col-span-11 pl-1 font-bold underline'>{tutorial.name}</div>
            <div className='col-span-1'>{tutorial.time}</div>

            <div className='col-span-12 p-2 mt-1 rounded-md bg-gray-50 text-md'>{tutorial.description}</div>
            <div className='absolute top-0 left-0 w-full h-full cursor-pointer opacity-0 hover:opacity-70' onClick={handleTutorialClick}>
                <Center>
                    <img src={PlayIcon} style={{ height: "5rem", width: "5rem" }} />
                </Center>
            </div>
        </div>
    )
}


export default function TutorialContainer({
    setShowTutorialMenu
}) {

    const [tutorialSearch, setTutorialSearch] = useState("")
    const [filteredTutorials, setFilteredTutorials] = useState([]);

    // RENDERERS
    const renderTutorials = (tutorials) => {
        return tutorials.map(tutorial => (
            <TutorialCard key={tutorial.route} tutorial={tutorial} />
        ))
    }

    // EFFECTS
    useEffect(() => {
        if (tutorialSearch === "") {
            setFilteredTutorials(TUTORIALS);
        } else {
            let re;
            try {
                re = new RegExp(tutorialSearch, "ig");
            } catch (err) {
                re = new RegExp("", "ig");
            }
            let filtered = TUTORIALS.filter(tutorial => (
                tutorial.name.match(re) || tutorial.description.match(re) || tutorial.tags.join(" ").match(re)
            ))
            console.log(filtered.length)
            setFilteredTutorials(filtered);
        }


    }, [tutorialSearch])
    return (
        <Modal
            closeButton
            onClose={() => { setShowTutorialMenu(false) }}
            bodyStyle={{ width: "33vw" }}
        >
            <div className='grid grid-rows-2'>
                <a className="text-black underline font-bold" href={USER_GUIDE_LINK} target="_blank">
                    PDF User Guide
                    <img src={Export} className='inline-block ml-2' />
                </a>

                <input type="text" className='w-full mb-2 p-1 shadow-sm text-2xl rounded-md' autoFocus placeholder='Search Tutorials...' onChange={e => { setTutorialSearch(e.target.value) }} />
            </div>
            <div className='overflow-y-auto' style={{ maxHeight: "66vh" }}>
                {renderTutorials(filteredTutorials)}
            </div>
        </Modal>
    )
}