import React, { useContext, useEffect, useState } from 'react'

// ======================== MODULES ========================
import { AreaChart, Bar, BarChart, CartesianGrid, Pie, PieChart, ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts'

// ======================== STATE ========================
import { ExplorerContext, DataPrepContext } from '../../state/explorer/explorerProvider'

// ======================== HOOKS ========================

import { usePage } from '../../js/hooks'

// ======================== HELPERS ========================
import { countOccurrences, binContinuousData, createXYData, createPolygonEntity } from '../../js/analyticsHelpers'


// ======================== CHILDREN ========================
import Center from '../dzyne_components/layout/Center/Center'
import { normalizeColor } from '../dzyne_components/map/3d/AnnotationManager3D/helpers'

const Cesium = require('cesium')

// ======================== INLINE COMPONENTS ========================
const CategoricalHistogramContainer = ({ formattedData, property, clickHandler }) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart data={formattedData}>
                <XAxis dataKey={"property"} stroke={"var(--neutral10)"} />
                <YAxis stroke={"var(--neutral10)"} />
                <Tooltip />
                <Bar dataKey={"count"} fill={"var(--primaryAccent0)"} onClick={clickHandler}/>
            </BarChart>
        </ResponsiveContainer>
    )
}

const PieChartContainer = ({ formattedData, property, clickHandler }) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart>
                <Pie data={formattedData} nameKey="property" dataKey="count" fill="var(--primaryAccent0)" onClick={clickHandler}/>
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>
    )
}

const BinnedHistogramContainer = ({ formattedData, property, clickHandler }) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart data={formattedData}>
                <XAxis dataKey="binRange" stroke={"var(--neutral10)"} />
                <YAxis stroke={"var(--neutral10)"} />
                <Tooltip />
                <Bar dataKey="count" fill="var(--primaryAccent0)" onClick={clickHandler}/>
            </BarChart>
        </ResponsiveContainer>
    )
}

const ScatterPlotContainer = ({ formattedData, property, clickHandler }) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <ScatterChart>
                <XAxis dataKey="x" name={property[0]} stroke={"var(--neutral10)"} />
                <YAxis dataKey="y" name={property[1]} stroke={"var(--neutral10)"} />
                <Tooltip />
                <Scatter data={formattedData} fill="var(--primaryAccent0)" onClick={clickHandler} />
            </ScatterChart>
        </ResponsiveContainer>
    )
}

export default function AnalyticsChart({ property, chartType, numBins }) {
    // STATE
    const page = usePage()
    const { state } = useContext(page === "explorer" ? ExplorerContext : DataPrepContext);
    const [formattedData, setFormattedData] = useState([]);
    const [renderedEntities, setRenderedEntities] = useState([])

    // HELPERS
    const clearRenderedEntities = () => {
        for(let entity of renderedEntities){
            state.viewer.entities.remove(entity)
        }
    }

    // EVENTS
    // on bar click
    const handleCategoricalClick = ({name, property, payload}) => {
        // filter state.selectedLayerAnalytics based on property match
        clearRenderedEntities();
        const entities = [];
        state.selectedLayerAnalytics.forEach(data => {
            if(data.properties?.[name] == property){ // handle conversion of strings to numbers       
                entities.push(
                    createPolygonEntity(state.viewer, data.geometry?.coordinates?.[0])
                )
            }
        })

        setRenderedEntities(entities)
    }

    const handleBinnedClick = ({name, binRange}) => {
        // filter state.selectedLayerAnalytics based on being within value range
        clearRenderedEntities();

        binRange = binRange.split(" - ").map(n => parseFloat(n))

        const entities = [];
        let property;
        state.selectedLayerAnalytics.forEach(data => {
            property = data.properties?.[name]
            if(property >= binRange[0] && property <= binRange[1]){
                entities.push(
                    createPolygonEntity(state.viewer, data.geometry?.coordinates?.[0])
                )
            }
        })

        setRenderedEntities(entities)
    }

    const handleScatterClick = (payload) => {
        clearRenderedEntities()

        const {x, y, xName, yName} = payload.payload

        let propX, 
            propY

            for(let data of state.selectedLayerAnalytics){
                propX = data.properties?.[xName]
                propY = data.properties?.[yName]
                if(propX === x && propY === y){
                    setRenderedEntities([
                        createPolygonEntity(state.viewer, data.geometry?.coordinates?.[0], {material: new Cesium.Color(...normalizeColor(0, 255, 255, 0.9))})
                    ])
                    break;
                }
            }
    }
    const generateCQL = (payload) => {
        console.log({payload, featureProperties: state.selectedLayerAnalytics})
        // const CQL = `${payload.name} = '${payload.property}'`
        // console.log(CQL)
    } 

    // RENDERERS
    const renderChart = chartType => {
        switch (chartType) {
            case "Categorical Histogram":
                return <CategoricalHistogramContainer {...{ formattedData, property, clickHandler: handleCategoricalClick }} />
            case "Binned Histogram":
                return <BinnedHistogramContainer {...{ formattedData, property, clickHandler: handleBinnedClick }} />
            case "Pie Chart":
                return <PieChartContainer {...{ formattedData, property, clickHandler: handleCategoricalClick }} />
            case "Scatter Plot":
                return <ScatterPlotContainer {...{ formattedData, property, clickHandler: handleScatterClick }} />
            default:
                return <Center>Select a chart type</Center>
        }
    }

    // ON LOAD
    useEffect(() => {
        if (state.selectedLayerAnalytics) {
            const featureProperties = state.selectedLayerAnalytics.map(feature => feature.properties)
            let data;
            switch (chartType) {
                case "Categorical Histogram":
                    data = countOccurrences(featureProperties, property)
                    break;
                case "Binned Histogram":
                    data = binContinuousData(featureProperties, property, numBins)
                    break;
                case "Pie Chart":
                    data = countOccurrences(featureProperties, property);
                    break;
                case "Scatter Plot":
                    data = createXYData(featureProperties, property)
                    break;
                default:
                    data = []
            }

            setFormattedData(data)
        }
    }, [state.selectedLayerAnalytics, property, chartType, numBins])

    useEffect(() => {
        return clearRenderedEntities
    }, [renderedEntities])

    return (
        <div id="analytics-chard" className="ribbon-subsection">
            {formattedData.length && renderChart(chartType)}
            {formattedData.error && <Center>{formattedData.error}</Center>}
        </div>
    )
}
