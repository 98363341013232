import React from 'react';

import "./NavArrow.css"

const NavArrow = ({
    children,

    onClick = e => { },

    scale = "1.5rem",
    spacing = 4,
    textColor = "#000000",
    backgroundColor = "#CFCFCF",
    className="",

    bodyStyle,
}) => {

    return (
        <span className={`nav-arrow-container ${className}`} style={{ marginRight: spacing }} onClick={onClick}>

            {/* <span className="nav-arrow-tail" style={{
                borderTop: `calc(${scale} / 2) solid ${backgroundColor}`,
                borderRight: `calc(${scale} / 2) solid ${backgroundColor}`,
                borderBottom: `calc(${scale} / 2) solid ${backgroundColor}`,
                borderLeft: `calc(${scale} / 2) solid transparent`,
            }}></span> */}

            <span className="nav-arrow arrow-body nav-arrow-part" style={{
                margin: `0 0 0 calc(${scale} / 4)`,
                height: scale,
                lineHeight: scale,
                backgroundColor: backgroundColor,
                color: textColor,
                ...bodyStyle
            }}>{children}</span>

            {/* <span className="nav-arrow-head" style={{
                margin: `0 ${scale} 0 0`,
                borderLeft: `calc(${scale} / 2) solid ${backgroundColor}`,
                borderTop: `calc(${scale} / 2) solid transparent`,
                borderBottom: `calc(${scale} / 2) solid transparent`,
            }}></span> */}

        </span>
    )

}

export default NavArrow;



// border-top: calc(var(--arrow-scale) / 2) solid grey;
//     border-right: calc(var(--arrow-scale) / 2) solid grey;
//     border-bottom: calc(var(--arrow-scale) / 2) solid grey;
//     border-left: calc(var(--arrow-scale) / 2) solid transparent;