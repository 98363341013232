import React from 'react'

import './InlineButton.css'

export default function InlineButton({
    children,
    onClick = e => { },
    disabled = false,
    id = "",
    className = "",
    hint = "",
    textColor = "black",
    backgroundColor = "white",
    style={},
    args={},
}) {

    className = "inline-button " + className;
    // className = className ? "inline-button " + className : "inline-button";

    return (
        <button id={id} className={className} disabled={disabled} onClick={onClick} title={hint} style={{
            color: textColor, backgroundColor, ...style
        }}
        {...args}
        >
            {children}
        </button>
    )
}
