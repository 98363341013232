const generateSharingLink = (layers, zoom) => {
    
    const queryString = JSON.stringify(layers);

    // const encrypted = cryptr.encrypt(queryString);
    let link = `${window.location.origin + window.location.pathname}?shared=${queryString}`;
    if(zoom) link += `&zoom=${JSON.stringify(zoom)}`

    return link;

}

export default generateSharingLink;