import './App.css';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

// ======================= STATE =======================
import { DataPrepProvider, ExplorerProvider } from './state/explorer/explorerProvider'

// ======================= CHILDREN =======================
import ColorPalette from './components/dzyne_components/styling/ColorPalette/ColorPalette';
import NavBar from './components/navigation/NavBar';

// use the same container for exploration and data prep
import ExplorerContainer from './components/explorer/ExplorerContainer';
import DataPrepContainer from './components/explorer/ExplorerContainer';

import Login from './components/user/Login';
import Logout from './components/user/Logout';
import DashboardContainer from './components/dashboard/DashboardContainer';
import { ChangeDetectionProvider } from './state/changeDetection/changeDetectionProvider';

import './components/dzyne_components/styling/tailwind3compat.css';
import { useContext } from 'react';
import { UserContext } from './state/users/userProvider';
import { useGetGeoserverToken, usePage } from './js/hooks';
import GeoserverTokenManager from './components/general/GeoserverTokenManager';

function App() {
    const {state: userState} = useContext(UserContext);

    return (
        <BrowserRouter>
            <div className="App bg-neutral0">
                <GeoserverTokenManager />
                <ColorPalette
                    // display
                    colors={{

                        neutral: {
                            base: "#2C3539",
                            gradientStep: 5
                        },

                        primaryAccent: {
                            base: "#21b0de",
                            gradientStep: 5
                        },
                        secondaryAccent: {
                            base: "#de4f21",
                            gradientStep: 5
                        },
                        tertiaryAccent: {
                            base: "#5de858",
                            gradientStep: 5
                        }
                    }}
                />
                <NavBar />

                <Switch>

                    {/* BASE */}
                    <Route exact path="/">
                        <Redirect to="/login" />
                    </Route>

                    {/* USER LOGIN/LOGOUT */}
                    <Route exact path="/login">
                        <Login />
                    </Route>

                    <Route exact path="/logout">
                        <Logout />
                    </Route>
                    {
                        userState.user !== null // TODO: In actual implementation will need to actually check for token existence here
                        &&
                        <>
                            <Route exact path="/dashboard">
                                <ChangeDetectionProvider>
                                    <DashboardContainer />
                                </ChangeDetectionProvider>
                            </Route>

                            <Route exact path="/explorer">
                                <ExplorerProvider>
                                    <ExplorerContainer />
                                </ExplorerProvider>
                            </Route>

                            <Route exact path="/data-preparation">
                                <DataPrepProvider>
                                    <DataPrepContainer />
                                </DataPrepProvider>
                            </Route>
                        </>
                    }

                </Switch>

            </div>
        </BrowserRouter>
    );
}

export default App;
