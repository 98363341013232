import ReactDOM from 'react-dom'
import { useState, useRef, useEffect } from 'react'
import useWindowResize from '../../../hooks/useWindowResize';

import "./HintModal.css"

export default function HintModal({
    children,
    mainRootID,
    content = "",

    hoverTimer = 500,
    
    animated = false,
    animationTime = 100,

    positioning = "bottom-left",

    closeButton = false,
    manualClose = false,
    onClose = e => { },
    closeButtonStyle = {},

    wrapperClassName = "",
    bodyClassName = "",

    wrapperStyle = {},
    bodyStyle={},
}) {

    if(animated){
        bodyStyle.animationName = "fade-in";
        bodyStyle.animationDuration = animationTime + "ms";
        bodyStyle.animationFillMode = "forwards"
        bodyStyle.animationTimingFunction = "linear"
    }
  
    // STATE
    const [timeoutInterval, setTimeoutInterval] = useState(null)
    const [loc, setLoc] = useState({ x: 0, y: 0 })
    const [contentDisplayed, setContentDisplayed] = useState(false)
    const [modalID, setModalID] = useState(Math.floor(Math.random() * 1e12))
    const [domModalContainer, setDomModalContainer] = useState(null)
    const windowSize = useWindowResize()    
    
    // HELPERS
    const hoverTimeout = e => () => {
        setContentDisplayed(true)
    }

    const adjustPosition = (x, y, width, height) => {
       
        if((x + width) > windowSize.width) x = x - ((x + width) - windowSize.width) - 16 // overshoot right
        if(x < 0) x = 16 // overshoot left
        if(y + height > windowSize.height) y = y - ((y + height) - windowSize.height) - 16 // overshoot bottom
        if(y < 0) y = 16 // overshoot top

        return [x, y]
    }
    
    // EVENTS
    const handleMouseMove = e => {

        if (!timeoutInterval) {
            setTimeoutInterval(() => setTimeout(hoverTimeout(e), hoverTimer))
        }

        if (!contentDisplayed) {
            setLoc({ x: e.clientX, y: e.clientY })
        }

    }

    const handleMouseLeave = e => {
        timeoutInterval && clearTimeout(timeoutInterval)
        !manualClose && setContentDisplayed(false)
        setTimeoutInterval(null)
    }

    // ON LOAD
    // appearance settings
    useEffect(() => {

        if (contentDisplayed) {
            
            const wrapper = document.querySelector(`#_dzyne-component-hint-modal-container-${modalID}`)
            const { width, height } = wrapper.querySelector(".hint-modal-content-container").getBoundingClientRect()

            let newX, newY
            switch (positioning) {
                case "bottom-left":
                    newX = loc.x;
                    newY = loc.y - height;
                    break;
                case "top-right":
                    newX = loc.x - width;
                    newY = loc.y;
                    break;
                case "bottom-right":
                    newX = loc.x - width;
                    newY = loc.y - height;
                    break;
                case "center":
                    newX = loc.x - (width / 2);
                    newY = loc.y - (height / 2);
                    break;
                default:
                    newX = loc.x;
                    newY = loc.y;
            }

            [newX, newY] = adjustPosition(newX, newY, width, height)
            setLoc({ x: newX, y: newY })
        }

    }, [contentDisplayed, windowSize])

    // setup portal
    useEffect(() => {

        if(!domModalContainer){

            const modalContainer =  document.createElement("div")
            modalContainer.setAttribute("id", `_dzyne-component-hint-modal-container-${modalID}`)
            
            if(mainRootID){
                try{
                    document.querySelector(`#${mainRootID}`).insertAdjacentElement("afterend", modalContainer)
                } catch(err){
                    throw new Error("If the main root access point for react is anything other than #root, you must specify the id of the main root access point in the mainRootID property for this modal component")
                }
            } else {
                try{
                    document.querySelector("#root").insertAdjacentElement("afterend", modalContainer)
                } catch(err){
                    throw new Error("Could not find a default react mounting point with id='root'. If the main mounting point cannot be accessed via document.querySelector('#root'), then you must specify the main access point in the mainRootID property passed to this object.")
                }
                
            }

            setDomModalContainer(modalContainer)
        }

        return () => {
            document.querySelector(`_dzyne-component-hint-modal-container-${modalID}`)?.remove()
        }

    }, [domModalContainer])

    if(!domModalContainer) return <></>

    return (
        <div className={"hint-modal-wrapper " + wrapperClassName} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} style={wrapperStyle}>
            {children}
            {
                contentDisplayed
                &&
                ReactDOM.createPortal(<div
                    className={`hint-modal-content-container ${bodyClassName}`}
                    style={{
                        position: "fixed",
                        left: loc.x,
                        top: loc.y,
                        ...bodyStyle
                    }}
                >
                    {
                        closeButton
                        &&
                        <div className="hint-modal-close-button" style={{ ...closeButtonStyle }} onClick={e => {
                            setContentDisplayed(false)
                            e.stopPropagation()
                            onClose(e)
                        }}>
                            <svg height="12" width="12">
                                <line x1="0" y1="0" x2="12" y2="12" style={{ stroke: "#000", strokeWidth: 3 }} />
                                <line x1="12" y1="0" x2="0" y2="12" style={{ stroke: "#000", strokeWidth: 3 }} />
                            </svg>
                        </div>
                    }
                    {content}
                </div>,
                domModalContainer
                )
            }


        </div>        
    )
}
