
import CantusFirmus from 'cantus-firmus';

import state from './state'
import setters from './setters'
import methods, {changeDetectionAPI} from './methods'


const changeDetection = new CantusFirmus(state, {nestedSetters: true})

changeDetection.addCustomSetters(setters)
changeDetection.addMethods(methods)
changeDetection.addNamespacedMethods({changeDetectionAPI})



export const ChangeDetectionContext = changeDetection.context;
export const ChangeDetectionProvider = changeDetection.createProvider();

