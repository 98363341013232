import React, { useContext, useEffect, useState } from 'react'
import { captureCurrentView } from '../../../js/mapHelpers';
import * as mgrs from 'mgrs';

// ========================== STATE ==========================
import { DataPrepContext, ExplorerContext } from '../../../state/explorer/explorerProvider'

// ========================== DZYNE COMPONENTS ==========================
import TabGroup from '../../dzyne_components/ui/buttons/TabGroup/TabGroup';
import FormElementWrapper from '../../dzyne_components/ui/forms/FormElementWrapper/FormElementWrapper';
import { usePage } from '../../../js/hooks';

const Cesium = require("cesium");

const DefaultLocation = ({
    className = ""
}) => {

    // STATE
    const page = usePage();
    const { state, setters } = useContext(page === "explorer" ? ExplorerContext : DataPrepContext);
    const [tab, setTab] = useState("point")
    const [coordinateType, setCoordinateType] = useState("MGRS")

    //the state of the zoom location forms, doesn't directly control map 
    const [zoomPoint, setZoomPoint] = useState(state.globalSettings.defaultLocation)
    const [zoomCorners, setZoomCorners] = useState({ 'north': 0, 'south': 0, 'east': 0, 'west': 0 })

    // EVENTS
    const handleSubmit = e => {
        e.preventDefault();

        switch (tab) {
            case "point":
                const loc = {}
                for (let key in zoomPoint) {
                    loc[key] = Number(zoomPoint[key])
                }
                if (!("lat" in loc && "lng" in loc && "alt" in loc)) {
                    return;
                }
                if (loc.lat > 90) loc.lat = 90
                if (loc.lat < -90) loc.lat = -90
                if (loc.lng > 180) loc.lng = 180
                if (loc.lng < -180) loc.lng = -180
                if (loc.alt <= 0) loc.alt = 100
                state.viewer.camera.flyTo({
                    destination: Cesium.Cartesian3.fromDegrees(loc.lng, loc.lat, loc.alt),
                    duration: 0.5
                })
                break;
            case "corners":
                state.viewer.camera.flyTo({
                    destination: zoomCorners,
                    duration: 0.5
                })
                break;
        }
    }

    const handleMgrsChange = e => {
        try {
            const mgrsStr = e.target.value;
            const point = mgrs.toPoint(mgrsStr);
            setZoomPoint(p => ({ ...p, lng: point[0], lat: point[1] }))
        } catch(err) {
        }

    }

    const handleCapture = e => {
        e.preventDefault();
        e.stopPropagation();
        setZoomCorners(captureCurrentView(state.viewer))
    }

    // ON LOAD
    useEffect(() => {
        setZoomPoint(state.globalSettings.defaultLocation)
    }, [state.globalSettings.defaultLocation])

    return (
        <div className={`ribbon-subsection ${className}`}>
            <div className='flex justify-between'>
                <h3 className="text-lg">Location Finder</h3>
                {
                    tab &&
                    <TabGroup
                        tabs={["point", "corners"]}
                        forceTab={tab}
                        onClick={t => setTab(t)}
                    />
                }
            </div>

            <form className="location-finder" onSubmit={handleSubmit}>
                {
                    tab === "point"
                    &&
                    (
                        <>
                            <TabGroup
                                tabs={["MGRS", "Lat/Lng"]}
                                forceTab={coordinateType}
                                onClick={t => setCoordinateType(t)}
                            />
                            {
                                coordinateType === "MGRS"
                                &&
                                <FormElementWrapper labels={["MGRS", "Altitude (meters)"]}>
                                    <input className='w-48' type="text" onChange={handleMgrsChange} />
                                    <input className='w-48' type="number" step="0" min="1" max="1e7" value={zoomPoint.alt} onChange={e => setZoomPoint(p => ({ ...p, alt: e.target.value }))} />
                                    <input className="btn text-neutral-1" type="submit" value="Zoom To Location" />
                                </FormElementWrapper>
                            }
                            {
                                coordinateType === "Lat/Lng"
                                &&
                                <FormElementWrapper labels={["Latitude", "Longitude", "Altitude (meters)"]}>
                                    <input className='w-48' type="number" step="0.000000000001" min="-90" max="90" value={zoomPoint.lat} onChange={e => setZoomPoint(p => ({ ...p, lat: e.target.value }))} />
                                    <input className='w-48' type="number" step="0.000000000001" min="-180" max="180" value={zoomPoint.lng} onChange={e => setZoomPoint(p => ({ ...p, lng: e.target.value }))} />
                                    <input className='w-48' type="number" step="0" min="1" max="1e7" value={zoomPoint.alt} onChange={e => setZoomPoint(p => ({ ...p, alt: e.target.value }))} />
                                    <input className="btn text-neutral-1" type="submit" value="Zoom To Location" />
                                </FormElementWrapper>
                            }

                        </>
                    )
                }
                {
                    tab === "corners"
                    &&
                    <>
                        <label htmlFor="default-north">North</label><br />
                        <input type="number" step="0.0000000000000001" min="-90" max="90" value={zoomCorners.north} onChange={e => setZoomCorners(c => ({ ...c, north: e.target.value }))} />

                        <div className='grid grid-cols-2'>
                            <div>
                                <label htmlFor="default-west">West</label> <br />
                                <input type="number" step="0.0000000000000001" min="-90" max="90" value={zoomCorners.west} onChange={e => setZoomCorners(c => ({ ...c, west: e.target.value }))} />
                            </div>
                            <div>
                                <label htmlFor="default-east">East</label><br />
                                <input type="number" step="0.0000000000000001" min="-90" max="90" value={zoomCorners.east} onChange={e => setZoomCorners(c => ({ ...c, east: e.target.value }))} />
                            </div>
                        </div>

                        <label htmlFor="default-south">South</label><br />
                        <input type="number" step="0.0000000000000001" min="-90" max="90" value={zoomCorners.south} onChange={e => setZoomCorners(c => ({ ...c, south: e.target.value }))} />
                        <div className="flex justify-center">
                            <input className="btn text-neutral-1" type="submit" value="Zoom To Location" />
                            <button className="btn text-neutral-1 bg-white" onClick={handleCapture}>Capture Current Zoom</button>
                        </div>
                    </>
                }
            </form>
        </div>
    )
}

export default DefaultLocation