
const state = {

    user: null,
    token: null

    // user: {
    //     first_name: "Quinn", 
    //     last_name: "Dizon", 
    //     Organization: "Dzyne", 
    //     username: "qdizon", 
    //     id: 1
    // }

}

export default state;
