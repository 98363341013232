import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom'

import './Modal.css'

export const Modal = ({
    children,
    mainRootID,

    closeButton = false,
    onClose = e => { },
    closeButtonStyle = {},

    backgroundStyle = {},
    backgroundClassName="",

    bodyStyle = {},
    bodyClassName="",
    contentStyle = {},
    contentClassName="",
}) => {

    // STATE
    const [modalID, setModalID] = useState(Math.floor(Math.random() * 1e6))
    const [domModalContainer, setDomModalContainer] = useState(null)

    // ON LOAD
    // setup container for portal
    useEffect(() => {
        
        if(!domModalContainer){

            const modalContainer =  document.createElement("div")
            modalContainer.setAttribute("id", `_dzyne-component-modal-container-${modalID}`)
            
            if(mainRootID){
                try{
                    document.querySelector(`#${mainRootID}`).insertAdjacentElement("afterend", modalContainer)
                } catch(err){
                    throw new Error("If the main root access point for react is anything other than #root, you must specify the id of the main root access point in the mainRootID property for this modal component")
                }
            } else {
                try{
                    document.querySelector("#root").insertAdjacentElement("afterend", modalContainer)
                } catch(err){
                    throw new Error("Could not find a default react mounting point with id='root'. If the main mounting point cannot be accessed via document.querySelector('#root'), then you must specify the main access point in the mainRootID property passed to this object.")
                }
                
            }

            setDomModalContainer(modalContainer)
        }

        return () => {
            document.querySelector(`_dzyne-component-modal-container-${modalID}`)?.remove()
        }

    }, [domModalContainer])
    
    // setup keyboard shortcuts
    useEffect(() => {
        const handleEscapeKey = e => {
            if (e.key === "Escape") onClose(e)
        }
        window.addEventListener("keydown", handleEscapeKey)

        return () => {
            window.removeEventListener("keydown", handleEscapeKey)
        }
    })

    // RETURNS

    if(!domModalContainer) return <></>

    return ReactDOM.createPortal(
        <div className={`modal-background ${backgroundClassName}`} style={{ ...backgroundStyle }}>
            <div className={`modal-body-wrapper ${bodyClassName}`} style={{ ...bodyStyle }}>
                {
                    closeButton
                    &&
                    <div className="modal-close-button" style={{ ...closeButtonStyle }} onClick={onClose}>
                        <svg height="24" width="24">
                            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm0 10.293l5.293-5.293.707.707-5.293 5.293 5.293 5.293-.707.707-5.293-5.293-5.293 5.293-.707-.707 5.293-5.293-5.293-5.293.707-.707 5.293 5.293z" /></svg>
                        </svg>
                    </div>
                }
                <div className={`modal-content-wrapper ${contentClassName}`} style={{ ...contentStyle }}>
                    {children}
                </div>
            </div>
        </div>,
        domModalContainer
    )

}

export default Modal;