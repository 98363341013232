import React from 'react'

// ============================== MODULES ==============================
import { DateTime } from 'luxon'

// ============================== DZYNE COMPONENTS ==============================
import InlineButton from '../dzyne_components/ui/buttons/InlineButton/InlineButton'

// ============================== HELPERS ==============================
import { zoomToBbox } from '../../js/mapHelpers'


export default function ThumbnailInterface({ viewer, feature }) {

    // EVENTS
    const handleZoomClick = () => {
        zoomToBbox(viewer, feature?.properties?.bbox);
    }

    // RENDERERS
    const renderDate = timestamp => {
        if(!timestamp) return "Unknown"
        return DateTime.fromISO(timestamp).toFormat("yyyy-MM-dd @ hh:mm:ss")
    }

    return (
        <div className="thumbnail-interface">
            <p className="thumbnail-interface-text">catid: <strong>{feature?.properties?.catid ?? "Unknown"}</strong></p>
            <p className="thumbnail-interface-text">Date: <strong>{renderDate(feature?.properties?.timestamp)}</strong></p>
            <p className="thumbnail-interface-text">Sensor: <strong>{feature?.properties?.sensor ?? "Unknown"}, Bands {feature?.properties?.num_bands ?? "Unknown"}</strong></p>
            <InlineButton onClick={handleZoomClick}>Zoom to image</InlineButton>
            <InlineButton >Load Detections</InlineButton>
        </div>
    )
}
