import * as d3 from 'd3'
import { DateTime } from "luxon";
import { normalizeColor } from '../components/dzyne_components/map/3d/AnnotationManager3D/helpers';

const Cesium = require("cesium")

// CONFIG
const RESERVED_PROPERTIES = {
    id: true,
    catid: true,
    bbox: true,
    filename: true,
}


export const extractProperties = features => {

    let properties = {}
    features.forEach(feature => {
        for (let property in feature.properties) {
            if (!(property in RESERVED_PROPERTIES)) properties[property] = true
        }
    })

    return Object.keys(properties)
}

export const retrieveDataFromProperty = (features, property) => {
    const data = [];
    features.forEach(feature => {
        data.push(feature.properties?.[property] ?? null)
    })

    return data;
}




/*  
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::: DATA FORMATTERS :::::::::::::::::::::::
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
*/

export const countOccurrences = (featureProperties, property) => {
    const occurrences = {}
    const data = [];

    let currentValue
    featureProperties.forEach(fp => {
        currentValue = fp[property] || "null";
        if (occurrences[currentValue]) {
            occurrences[currentValue] += 1
        } else {
            occurrences[currentValue] = 1
        }
    })

    Object.entries(occurrences).forEach(([prop, numCounted]) => {
        data.push({ property: prop, count: numCounted, name: property })
    })

    if(data.length > 20e3) return {error: "Dataset too large"}

    return data;

}

export const binContinuousData = (featureProperties, property, numBins = 5) => {
    const isDate = !!property.match(/(date)|(timestamp)/i);
    const isTime = !!property.match("time")

    const rawData = featureProperties.map(fp => {
        if (isDate) return new Date(fp[property]).getTime()
        if (isTime) return new Date("1970-01-01T" + fp[property]).getTime() // give time a fake date so it can be converted
        return fp[property]
    })

    const bins = d3.bin().thresholds(numBins)(rawData)
    const formattedData = []
    for (let bin of bins) {
        if (isDate) {
            formattedData.push({
                binRange: `${DateTime.fromMillis(bin.x0).toLocaleString(DateTime.DATE_SHORT)} - ${DateTime.fromMillis(bin.x1).toLocaleString(DateTime.DATE_SHORT)}`,
                count: bin.length,
                name: property
            })
            continue;
        }
        if (isTime) {
            formattedData.push({
                binRange: `${DateTime.fromMillis(bin.x0).toLocaleString(DateTime.TIME_SIMPLE)} - ${DateTime.fromMillis(bin.x1).toLocaleString(DateTime.TIME_SIMPLE)}`,
                count: bin.length,
                name: property
            })
            continue;
        }

        formattedData.push(
            {
                binRange: `${bin.x0} - ${bin.x1}`,
                count: bin.length,
                name: property
            }
        )
    }
    return formattedData;

}

export const createXYData = (featureProperties, properties) => {

    if (featureProperties.length > 20e3) return { error: "Dataset too large" }

    const data = [];
    const [xProp, yProp] = properties;
    featureProperties.forEach(fp => {
        data.push({
            x: fp[xProp],
            y: fp[yProp],
            xName: xProp,
            yName: yProp
        })
    })

    return data;
}


/*  
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
::::::::::::::::::::::: POLYGON RENDERERS :::::::::::::::::::::::
:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
*/

export const createPolygonEntity = (viewer, geometry, entityOptions = {}) => {
    if(geometry){
        return viewer.entities.add({
            polygon: {
                hierarchy: Cesium.Cartesian3.fromDegreesArray(geometry.flat()),
                fill: true,
                outline: true,
                material: new Cesium.Color(...normalizeColor(0, 255, 255, 0.1)),
                outlineWidth: 4,
                ...entityOptions
            }
        })
    }
}