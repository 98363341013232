import React, { useLayoutEffect, useRef } from 'react'

export default function ScrollContent(props) {

	const self = useRef()
	useLayoutEffect(() => {
		setTimeout(() => {
			self.current.style.minHeight = null
			self.current.style.minWidth = null
		}, 0)
	}, [])

	return (
		<div ref={self} style={{ position: "relative", overflow: "auto", minHeight: props.initialHeight, minWidth: props.initialWidth, width: "100%", flex: "1 1 auto" }}>
			<div style={{ position: "absolute" }}>
				{props.children}
			</div>
		</div >
	)
}