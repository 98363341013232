
const setters = {

    clearUser(){
        this.setState({user: null, token: null})
    }

}

export default setters;
