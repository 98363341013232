import React from 'react'

// ======================== ASSETS ========================
import Satellite from '../../assets/icons/satellite.svg'

// ======================== STYLE ========================
import './general.css'

export default function SatelliteSpinner({
    size = "1rem",
    animation="spin"
}) {
    return (
        <div
            className={`satellite-spinner ${animation}`} 
            style={{
            height: size,
            width: size,
            backgroundImage: `url(${Satellite})`
        }}>

        </div>
    )
}
