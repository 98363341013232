import React, { useContext, useEffect, useState } from "react";

// ========================= MODULES =========================
import { Link, useLocation, Redirect } from "react-router-dom";

// ========================= STATE =========================
import { UserContext } from "../../state/users/userProvider";


// ========================= ASSETS =========================
import CasiLogo from "../../assets/images/casi_logo.png";

// ========================= CONFIG =========================
import config from '../../config.json';
import TutorialContainer from "../general/TutorialContainer";

const LABELING_UI_PROTOCOL = window._env_?.labelingUIProtocol ?? config.labelingUIProtocol ?? config.defaultProtocol ?? "http";
const LABELING_UI_HOST = window._env_?.labelingUIHost ?? config.labelingUIHost ?? window.location.hostname;
const LABELING_UI_PORT = window._env_?.labelingUIPort ?? config.labelingUIPort ?? 3010;
const LABELING_UI_SLUG = window._env_?.labelingUISlug ?? config.labelingUISlug ?? "/label"

const DAGSTER_URL =
    process.env?.REACT_APP_DAGSTER_URL ||
    window._env_?.dagsterURL ||
    config.dagsterURL ||
    "https://dagster.tmi.dzyne.tech"

const SOFTWARE_VERSION = window._env_?.softwareVersion ?? config.softwareVersion ?? "X.X.X"
const LAST_UPDATE = window._env_?.lastUpdate ?? config.lastUpdate ?? "--"
const LABELING_UI_BASE = `${LABELING_UI_PROTOCOL}://${LABELING_UI_HOST}:${LABELING_UI_PORT}${LABELING_UI_SLUG}`;

export default function NavBar() {
    const { state: userState, API: userAPI } = useContext(UserContext);
    console.log({userState})
    const [showTutorialMenu, setShowTutorialMenu] = useState(false)
    const location = useLocation(); // react-router-dom page location

    useEffect(() => {
        const exec = async () => {
            const renewSuccess = await userAPI.renew();
            if(!renewSuccess) {
                setTimeout(exec, 3000);
            }
        }
        exec();
    }, [])

    if (location.pathname !== "/login" && userState.user === null) {
        return <Redirect to="/login" />
    }

    return (
        <nav className="flex nav-height mb-2 w-full shadow-lg bg-gradient-to-br from-neutral0 to-neutral2">
            {
                userState.user
                    ? <NavLink selected={location.pathname === "/logout"} to="/logout">Logout</NavLink>
                    : <NavLink selected={location.pathname === "/login"} to="/login">Login</NavLink>
            }

            {/* <NavLink selected={location.pathname === "/dashboard"} to="/dashboard">Dashboard</NavLink> */}
            {userState.user && (
                <>
                    <NavLink
                        selected={location.pathname === "/explorer"}
                        to="/explorer"
                    >
                        Explorer
                    </NavLink>
                    <NavLink
                        selected={location.pathname === "/data-preparation"}
                        to="/data-preparation"
                    >
                        Data Prep
                    </NavLink>
                    <NavLink selected={false} to={LABELING_UI_BASE + `?token=${localStorage.CASI_TOKEN}`} external>
                        Labeling 
                    </NavLink>
                    <NavLink selected={false} to={DAGSTER_URL} external>
                        Job Status
                    </NavLink>
                    <NavLink selected={false} to={() => { setShowTutorialMenu(true) }} external>
                        Tutorials
                    </NavLink>
                </>
            )}

            <div className="ml-auto mr-2 text-right">
                <div className="text-white">Version <em className="font-bold">{SOFTWARE_VERSION}</em></div>
                <div className="text-white">Last Update <em className="font-bold">{LAST_UPDATE}</em></div>
            </div>
            {showTutorialMenu && <TutorialContainer {...{ setShowTutorialMenu }} />}
        </nav>
    );
}

function NavLink(props) {
    let classes =
        "mx-4 my-auto p-4 h-12 bg-gradient-to-tl rounded border leading-none hover:shadow-md cursor-pointer";
    if (props.selected) {
        classes +=
            " from-secondary-1 to-secondary-5 border-secondary3 shadow-xl font-bold underline";
    } else {
        classes += " from-primary-3 to-primary-5 border-primary-1 ";
    }

    if (typeof props.to === "function") {
        return <div className={props.className ?? "" + " " + classes} onClick={props.to}>{props.children}</div>
    }

    if (!!props.external) {
        return <a href={props.to} className={props.className ?? "" + " " + classes} target="_blank">{props.children}</a>
    }

    return (
        <Link to={props.to} className={props.className ?? "" + " " + classes}>
            {props.children}
        </Link>
    );
}
