import React, { useContext, useEffect, useState } from 'react'

// ===================== STATE =====================
import { ExplorerContext, DataPrepContext } from '../../state/explorer/explorerProvider';
// ===================== HOOKS =====================
import { usePage } from '../../js/hooks';

// ===================== CHILDREN =====================
import Grid from '../dzyne_components/layout/Grid/Grid';
import InlineButton from '../dzyne_components/ui/buttons/InlineButton/InlineButton';
import TabGroup from '../dzyne_components/ui/buttons/TabGroup/TabGroup';

// ===================== HELPERS =====================
import hex2HSL from '../../js/hex2HSL';
import { captureCurrentView, paramStringParser, stringifyParams } from '../../js/mapHelpers';
import state from '../../state/explorer/state';

// ===================== STYLING =====================
const POSITIONING_STYLE = {
    margin: "0 1px",
    borderRadius: 0,
    backgroundColor: "var(--primaryAccent7)"
}

const INPUT_STYLE = {
    fontSize: "0.9rem"
}

// ===================== INLINE COMPONENTS =====================
const Settings = ({
    state,
    setters,
    methods,
    handleLayerNameChange
}) => {

    // STATE
    const [ip, setIP] = useState("")
    const [port, setPort] = useState(null)
    const [location, setLocation] = useState("")
    const [params, setParams] = useState("")
    const [url, setURL] = useState("")

    // EVENTS
    const handleApplyLayerSettings = () => {
        // 1. get the current layer id
        const layerID = state.selectedLayer.id

        // determine which fields need to be updated based on layer type
        if (state.selectedLayer.type === "dzyne_server") {
            let mapSettings = paramStringParser(params);

            const newParams = {
                ip,
                port,
                layerLocation: location,
                mapSettings: mapSettings ?? {}
            }
            for (let [param, value] of Object.entries(newParams)) {
                setters.adjustLayerParameter(layerID, param, value);
            }
        }

        if (state.selectedLayer.type === "url") {
            setters.adjustLayerParameter(layerID, "url", url);
        }

        // once fields have been updated on the layer definition, redraw the layer on the map
        setters.redrawRenderedLayer(layerID);
    }

    // ON LOAD
    useEffect(() => {
        if (state.selectedLayer.type === "dzyne_server") {
            setIP(state.selectedLayer.ip)
            setPort(state.selectedLayer.port)
            setLocation(state.selectedLayer.layerLocation)
            setParams(stringifyParams(state.selectedLayer.mapSettings))
        }

        if (state.selectedLayer.type === "url") {
            setURL(state.selectedLayer.url)
        }

        // uncomment below for auto view centering
        // if (state.selectedLayer?.lockedView) {
        //     state.viewer.camera.flyTo({
        //         destination: state.selectedLayer.lockedView,
        //         duration: 0.5
        //     })
        // }

    }, [state.selectedLayer])


    return (
        <div className="ribbon-subsection">
            <h3 className="text-lg mb-1">
                Layer Settings <button className="btn py-1 m-0 mt-1 mr-1 float-right text-xs bg-white text-neutral0" onClick={handleApplyLayerSettings}>Apply</button>
            </h3>

            <Grid cols={2} colSpacing={"2fr 10fr"}>

                <label>Name </label>
                <input type="text" value={state.selectedLayer.name} onChange={handleLayerNameChange} style={INPUT_STYLE} />

                {
                    state.selectedLayer.type === "dzyne_server"
                    &&
                    <>
                        <label>IP </label>
                        <input type="text" value={ip} required onChange={e => setIP(e.target.value)} style={INPUT_STYLE} />

                        <label>Port</label>
                        <input type="text" value={port} required onChange={e => setPort(e.target.value)} style={INPUT_STYLE} />

                        <label>Layer</label>
                        <input type="text" value={location} required onChange={e => setLocation(e.target.value)} style={INPUT_STYLE} />

                        <label>Params</label>
                        <input type="text" value={params} required onChange={e => setParams(e.target.value)} style={INPUT_STYLE} />
                    </>
                }

                {
                    state.selectedLayer.type === "url"
                    &&
                    <>
                        <label>URL</label>
                        <input type="text" value={url} required onChange={e => setURL(e.target.value)} style={INPUT_STYLE} />
                    </>
                }


            </Grid>
        </div>
    )
}

const Categorization = ({ state, handleLevelChange, handleLayerTypeChange }) => {
    return (
        <div className="ribbon-subsection">
            <h3 className="text-lg">Placement</h3>

            <label>Change Level</label>

            <br />

            <InlineButton style={POSITIONING_STYLE} onClick={handleLevelChange("up")}>Up</InlineButton>
            <InlineButton style={POSITIONING_STYLE} onClick={handleLevelChange("down")}>Down</InlineButton>
            <InlineButton style={POSITIONING_STYLE} onClick={handleLevelChange("top")}>Top</InlineButton>
            <InlineButton style={POSITIONING_STYLE} onClick={handleLevelChange("bottom")}>Bottom</InlineButton>

            <div>
                <label>Change Category</label>
                <TabGroup
                    tabs={["base", "feature", "imagery"]}
                    defaultTab={state.selectedLayer.layerType}
                    onClick={handleLayerTypeChange}
                    containerStyle={{
                        width: "fit-content",
                        border: "none",
                        boxShadow: "2px 2px 5px var(--primaryAccent-9)",
                        borderRadius: "0"
                    }}
                    tabStyle={{ color: "var(--neutral8)" }}
                    selectedStyle={{ color: "var(--neutral-3)", backgroundColor: "var(--primaryAccent2)" }}
                />
            </div>
        </div>
    )
}

const Styling = ({
    state,
    getters,
    methods,
    geoserverAPI,
    handleRenderingChange,
    handleStyleChange,
    handleViewLockToggle,
    handleViewLockUpdate,
    handleZoomToViewClick
}) => {

    // STATE
    const [availableStyles, setAvailableStyles] = useState([])

    // RENDERERS
    const renderStyleOptions = availableStyles => {
        return availableStyles.map(style => {
            return <option key={style} value={style}>{style}</option>
        })
    }


    // ON LOAD
    // Loading Styles
    useEffect(() => {
        const getStyleInfo = async () => {
            const info = await geoserverAPI.getLayerInfo(state.selectedLayer)
            // get all available styles
            let styles = info?.layer?.styles?.style?.map(s => s.name) || [];
            // get the default style and make sure it's at the front of the array
            const defaultStyle = info?.layer?.defaultStyle?.name
            defaultStyle && styles.unshift(defaultStyle);
            styles = [...new Set(styles)];

            setAvailableStyles(styles)
            // await geoserverAPI.getStyleDocument(defaultStyle, state.selectedLayer);
        }

        getStyleInfo();
    }, [state.selectedLayer])

    return (
        <div className={"ribbon-subsection"}>
            <h3 className="text-lg">Appearance</h3>

            <Grid cols={2} colSpacing={"4fr 8fr"}>
                <label>Opacity</label>
                <input type="range" onChange={handleRenderingChange("alpha")} min="0" max="1" step="0.01"
                    value={getters.getRenderedSelectedLayer().alpha} />
            </Grid>

            <Grid cols={2} colSpacing={"4fr 8fr"} >
                <label>Contrast</label>
                <input type="range" onChange={handleRenderingChange("contrast")} min="0" max="1" step="0.01"
                    value={getters.getRenderedSelectedLayer().contrast} />
            </Grid>

            <Grid cols={2} colSpacing={"4fr 8fr"}>
                <label>Saturation</label>
                <input type="range" onChange={handleRenderingChange("saturation")} min="0" max="1" step="0.01"
                    value={getters.getRenderedSelectedLayer().saturation} />
            </Grid>

            <Grid cols={2} colSpacing={"4fr 8fr"}>
                <label>Hue</label>
                <input type="range" onChange={handleRenderingChange("hue")} min="0" max="1" step="0.01"
                    value={getters.getRenderedSelectedLayer().hue} />
            </Grid>


            <Grid cols={2} colSpacing={"4fr 8fr"}>
                <label htmlFor="layer-style" className="ribbon-section-title">Styling </label>
                <select value={state.selectedLayer?.mapSettings?.styles || ""} onChange={e => { handleStyleChange(e.target.value) }}>
                    {renderStyleOptions(availableStyles)}
                </select>
            </Grid>

            <div>
                <input id="layer-view-lock" type="checkbox" checked={!!state.selectedLayer.lockedView} onClick={handleViewLockToggle} />
                <label htmlFor="layer-view-lock"> Lock to View </label>
                <InlineButton className='text-sm' onClick={handleViewLockUpdate}>Update</InlineButton>
                <InlineButton className='text-sm' onClick={handleZoomToViewClick}>Zoom to</InlineButton>
            </div>

        </div>

    )
}



export default function LayerSettings({ ribbonContentHeight, contentRef }) {

    // STATE
    const page = usePage()
    const { state, setters, methods, getters, geoserverAPI } = useContext(page === "explorer" ? ExplorerContext : DataPrepContext);

    // EVENTS
    const handleRenderingChange = parameter => e => {
        setters.adjustRenderedLayerParameter(state.selectedLayer.id, parameter, parseFloat(e.target.value))
        setters.applyMapSettingToLayer(state.selectedLayer.id, parameter, parseFloat(e.target.value), { recheckFeatures: false })
    }

    const handleLayerNameChange = e => {
        setters.adjustLayerParameter(state.selectedLayer.id, "name", e.target.value)
    }

    const handleLevelChange = direction => e => {
        setters.adjustRenderedLayerLevel(state.selectedLayer.id, direction)
    }

    const handleLayerTypeChange = tab => {
        setters.adjustLayerParameter(state.selectedLayer.id, "layerType", tab)
    }

    const handleViewLockToggle = e => {
        if (e.target.checked) {
            const currentView = captureCurrentView(getters.getViewer())
            setters.adjustLayerParameter(state.selectedLayer.id, "lockedView", { ...currentView, type: "corners" })
        } else {
            setters.adjustLayerParameter(state.selectedLayer.id, "lockedView", null)
        }
    }

    const handleViewLockUpdate = () => {
        const currentView = captureCurrentView(getters.getViewer())
        // const { north, south, east, west } = currentView;
        // setters.adjustLayerParameter(state.selectedLayer.id, "lockedView", { north, south, east, west })
        setters.adjustLayerParameter(state.selectedLayer.id, "lockedView", { ...currentView, type: "corners" })
    }

    const handleZoomToViewClick = () => {
        const zoom = state.selectedLayer.lockedView

        zoom && methods.zoomToDefaultLocation({viewer: getters.getViewer(), zoom})

        // view && state.viewer.camera.flyTo({ destination: view, duration: 0.5 })
    }




    const handleStyleChange = async value => {
        // apply the change to the layer (not rendered layer)
        await setters.applyMapSettingToLayer(state.selectedLayer.id, "styles", value, { recheckFeatures: false })
        // redraw the layer (which looks at the layer definition ^ and recreates with any changes)
        setters.redrawRenderedLayer(state.selectedLayer.id)
    }

    return (
        <Grid className="rendered-ribbon-content" cols={3} style={{ gridGap: "0.25rem", height: ribbonContentHeight }} >

            <Settings {...{ state, setters, methods, handleLayerNameChange, handleLevelChange, handleLayerTypeChange }} />
            <Categorization {...{ state, handleLevelChange, handleLayerTypeChange }} />
            <Styling {...{
                state,
                getters,
                methods,
                handleRenderingChange,
                handleStyleChange,
                handleViewLockUpdate,
                handleViewLockToggle,
                handleZoomToViewClick,
                geoserverAPI
            }} />

        </Grid>
    )
}
