import { useEffect, useState } from 'react';
import './cesium.css'

const Cesium = require('cesium');

const createImageryProvider = (tileSet, type) => {

    switch(type){
        case 'external':
            return new Cesium.UrlTemplateImageryProvider({url: tileSet})
        case 'cesium server':
            return new Cesium.TileMapServiceImageryProvider({url: Cesium.buildModuleUrl(tileSet)})
    }
}

const use3DMap = (containerID, initOptions = {}, viewerOptions = {}, callback = viewer => {}) => {
    // STATE
    const [map, setMap] = useState(null)
    
    // CONFIGURATION VIA INIT_OPTIONS
    // establish base url where Cesium will look for all required files
    window.CESIUM_BASE_URL = initOptions.cesiumBaseUrl || window.location.origin

    let tileSet, tileType;
    if(initOptions.defaultTiles){
        tileType = 'external'
        tileSet = initOptions.defaultTiles;
    } else {
        tileType = 'cesium server'
        tileSet = initOptions.serverTiles;

        if(!tileSet) tileSet = "Assets/Textures/NaturalEarthII"
    }
    
    const imageryProvider = createImageryProvider(tileSet, tileType);
    const calculatedViewerOptions = {}
    if(!!initOptions.display){
        switch(initOptions.display){
            case "clean":
                calculatedViewerOptions.timeline = false;
                calculatedViewerOptions.animation = false;
                calculatedViewerOptions.homeButton = false;
                calculatedViewerOptions.navigationHelpButton = false;
                calculatedViewerOptions.sceneModePicker = false;
                break;
            case "scene":
                calculatedViewerOptions.timeline = false;
                calculatedViewerOptions.animation = false;
                calculatedViewerOptions.navigationHelpButton = false;
                break;
            
        }
    }


    useEffect(() => {
        
        const viewer = new Cesium.Viewer(containerID, {
            imageryProvider,
            baseLayerPicker: false, // for offline use
            geocoder: false, // for offline use
            ...calculatedViewerOptions,
            ...viewerOptions
        });


        setMap(viewer)

    }, [])

    useEffect(() => {
        !!map && callback(map)
    }, [map])

    return map
}

export default use3DMap;