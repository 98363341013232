import React, { useEffect } from 'react'

import "./SelectionTable.css"

const CheckboxChecked = require("../../../assets/media/check_box-24px.svg")
const CheckboxUnchecked = require("../../../assets/media/check_box_outline.svg")


// =================================== HELPERS ===================================
const toCssName = name => {
    let cssName = ""

    for(let char of name){
        if (char === char.toUpperCase()){
            cssName += "-";
            cssName += char.toLowerCase()
        } else {
            cssName += char
        }
    }

    return cssName;
}

const processStyleObject = style => {
    let styleOutput = ""
    let cssName;
    for(let [name, val] of Object.entries(style)){
        cssName = toCssName(name);
        styleOutput += `${cssName}: ${val};\n`
    }
    return styleOutput
}


const SelectionTable = ({
    data,
    columns = [],

    nestedDelimiter = ",",
    onRowClick = (data, event) => { },

    headerColor = "#ffffff00",
    primaryColor = "#dedede",
    secondaryColor = "#e6e6e6",
    hoverColor = "#f7f7f7",

    id=null,

    containerStyle = {},
    tableStyle = {},
    thStyle = {},
    evenRowStyle = {},
    oddRowStyle = {},
    hoverRowStyle = {},
    tdStyle = {},

    containerClassName="",
    tableClassName="",
    theadClassName="",
    tbodyClassName="",
    trClassName="",
    tdClassName="",


    // onComponentDidMount = e => { }
}) => {


    const keys = columns.map(c => {
        return c.key.split(nestedDelimiter).length > 1
            ? c.key.split(nestedDelimiter)
            : c.key
    })

    // preprocess data on type
    const renderData = (data, key, type) => {
        if (type === "boolean") {
            return !!data[key] ? <img src={CheckboxChecked.default} alt={"checked"} /> : <img src={CheckboxUnchecked.default} alt={"unchecked"} />
       
        } else {
            if (typeof data[key] === "object"){
                return <pre>{JSON.stringify(data[key], null, 2)}</pre>
            } else if (typeof data[key] === "boolean") {
                return data[key].toString() 
            } else {
                return data[key]
            }
        }
    }

    const parseRowData = (data) => {
        let dataCopy;
        return data.map((d, i) => {
            return (
                <tr key={i} onClick={e => {onRowClick(d, e)}} className={trClassName}>
                    {keys.map((key, i) => {
                        dataCopy = d;
                        // if key is an array, it is a nested value. dig through data object until we find the nested value we want
                        if (Array.isArray(key)) {
                            for (let i = 0; i < key.length - 1; i++) {
                                dataCopy = dataCopy[key[i]]
                            }
                            key = key.slice(-1)[0]
                        }

                        return (
                            <td key={`${key}-${i}`} style={tdStyle} className={tdClassName}>
                                {renderData(dataCopy, key, columns[i].type)}
                            </td>
                        )

                    })}
                </tr>
            )
        })
    }

    // useEffect(() => {
    //     onComponentDidMount()
    // }, [])

    return (

        <>
            <style>
                {
                    `
                    .selection-table th{
                        background-color: ${headerColor} !important;    
                        ${processStyleObject(thStyle)}
                    }
                    .selection-table tbody tr:nth-child(even){
                        background-color: ${primaryColor};    
                        ${processStyleObject(evenRowStyle)}
                    }
                    .selection-table tbody tr:nth-child(odd){
                        background-color: ${secondaryColor};    
                        ${processStyleObject(oddRowStyle)}
                    }
                    
                    .selection-table tbody tr:hover{
                        background-color: ${hoverColor};
                        ${processStyleObject(hoverRowStyle)}
                    }
                    `
                }
            </style>
            <div id={id} className={`selection-table-container ${containerClassName}`} style={{ ...containerStyle }}>

                <table className={`selection-table ${tableClassName}`} style={{ ...tableStyle }}>
                    {
                        data
                        &&
                        <>
                            <thead className={theadClassName}>
                                <tr>
                                    {columns.map(col => <th key={col.name}>{col.name}</th>)}
                                </tr>
                            </thead>
                            <tbody className={tbodyClassName}>
                                {parseRowData(data)}
                            </tbody>
                        </>
                    }
                </table>
            </div>
        </>

    )
}

export default SelectionTable
