import { DateTime } from 'luxon';
import fCodes from '../../../state/changeDetection/fCodes.json'
import { v4 as uuidv4 } from 'uuid';
import _ from "lodash";

let ddPERCENT_COLORS = [
    { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
    { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
    { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } },
];

export const percent2Color = (pct) => {
    for (var i = 1; i < ddPERCENT_COLORS.length - 1; i++) {
        if (pct < ddPERCENT_COLORS[i].pct) break;
    }

    let lower = ddPERCENT_COLORS[i - 1];
    let upper = ddPERCENT_COLORS[i];
    let range = upper.pct - lower.pct;
    let rangePct = (pct - lower.pct) / range;
    let pctLower = 1 - rangePct;
    let pctUpper = rangePct;


    let color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
    };
    return `rgb(${color.r},${color.g},${color.b})`
};

export const formatDataForChart = scenes => {
    let counts = Array.from({length: 10}).fill(0)
    
    let change;
    for (let scene of scenes){          
        change = Math.floor(scene.score * 10);  
        // console.log(change, scene.details.avg_percent_change)
        counts[change] += 1
    }
    // debugger
    return counts.map((c, i) => {
        return {count: c, range: `${i * 10}-${i*10 + 10}%`, color: percent2Color(i / 10)}
    })
     
}

export const formatSelectedHistogramData = scene => {
    debugger
    return Object.entries(scene.details.class_change_percents).map(([code, prct]) => {
        return {
            percentChange: prct,
            label: fCodes.find(fc => fc.code === code)?.feature_type ?? "unlabled",
            color: percent2Color(prct)
        }
    }).sort((a,b) => a.label > b.label ? 1 : -1)
}

export const formatSelectedTimeseriesData = scene => {
    let dt;
    return scene.images.map(image => {
        dt = `${DateTime.fromISO(image.timestamp).toLocaleString(DateTime.DATE_MED)} ${DateTime.fromISO(image.timestamp).toFormat("hh:mm:ss")}`
        return {
            ...image.analysis,
            // average: _.mean(Object.values(image.analysis)),
            average: image.analysis.aggregate_score,
            catid: image.catid,
            timestamp: DateTime.fromISO(image.timestamp).ts,
            formattedDatetime: dt,
        }
    })
}

export const WKT2Coordinates = (wkt, options={order: "latlng"}) => {
    return wkt.match(/(-?\d+\.?\d+\s?){2}/g).map(c => {
        c = c.split(" ").map(x => parseFloat(x))
        options.order === "latlng" && c.reverse()
        return c

    })
}

// :::::::::::::::::::::::::::::::::::::::::::
// :::::::::: LINK CREATION HELPERS ::::::::::
// :::::::::::::::::::::::::::::::::::::::::::

export const createLinkLayer = ({image, index, ip, port, isActive}) => {
    
    let imageLayer, detectionLayers = [];
    for(let [i, info] of Object.entries(image.detection_layers)){
        detectionLayers.push({
            layerType: "feature",
            type: "dzyne_server",
            name:`${info.detector_name ?? "detector"}-${index}`,
            ip,
            port,
            layerLocation: info.wms.layer,
            mapSettings: {viewparams: `image_catid:${info.wms?.viewparams?.image_catid};run_id:${info.wms?.viewparams?.run_id}`},
            aoi: {type: "none"},
            id: uuidv4(),
            isActive: index !== 0 ? isActive : false
        }) 

    } 
        
    imageLayer = {
        layerType: "feature", 
        type: "dzyne_server",
        name: index === 0 ? "anchor" : `image-${index}`,
        ip,
        port,
        layerLocation: `images:${image.catid}`,
        mapSettings: {},
        aoi: {type: "none"},
        id: uuidv4(),
        isActive
    }
    
    return [detectionLayers, imageLayer]
}

export const calcSceneZoomFromBbox = (bbox, alt=1000) => {
    const lng = (bbox[0] + bbox[2]) / 2
    const lat = (bbox[1] + bbox[3]) / 2
    return {type: "point", lat, lng, alt}
}

export const calcSceneZoomFromWKT = (wkt, alt=1000) => {
    const coords = WKT2Coordinates(wkt)
    
    const latMin = Math.min(...coords.map(c => c[0]));
    const latMax = Math.max(...coords.map(c => c[0]));
    const lngMin = Math.min(...coords.map(c => c[1]));
    const lngMax = Math.max(...coords.map(c => c[1]));

    const lat = (latMax + latMin) / 2
    const lng = (lngMax + lngMin) / 2
    return {type: "point", lat, lng, alt}
    
}