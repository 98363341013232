import React, { useContext, useState, useEffect } from 'react'

// ========================= STATE =========================
import { ExplorerContext, DataPrepContext } from '../../state/explorer/explorerProvider';

// ========================= HOOKS =========================
import useWindowResize from '../dzyne_components/hooks/useWindowResize';
import { usePage } from '../../js/hooks';

// ========================= CHILDREN =========================
import LayerSettings from './LayerSettings';
import Center from '../dzyne_components/layout/Center/Center';
import TabGroup from '../dzyne_components/ui/buttons/TabGroup/TabGroup';
import LayerFilter from './LayerFilter';
import LayerImagePreview from './LayerImagePreview';
import LayerAnalytics from './LayerAnalytics';
import GlobalMapSettings from './GlobalMapSettings';
import IconButton from '../dzyne_components/ui/buttons/Iconbutton/IconButton';
import { formatNumberString } from '../../js/generalHelpers';

export default function ExplorerRibbon() {

    const page = usePage();
    const { state, setters, getters, geoserverAPI } = useContext(page === "explorer" ? ExplorerContext : DataPrepContext);

    const [availableTabs, setAvailableTabs] = useState([])
    const [tab, setTab] = useState("settings")
    const [forceTab, setForceTab] = useState(null)
    const [contentHeight, setContentHeight] = useState("50%")

    const size = useWindowResize()
    const [ribbonContentHeight, setRibbonContentHeight] = useState("5vh");


    // EVENTS
    const handleTabChange = t => {
        setTab(t)
        setForceTab(null)
    }

    const handleLayerNameClick = () => {
        geoserverAPI.countFeatures(state.selectedLayer);
    }

    // ON LOAD
    useEffect(() => {

        setForceTab("settings")
        setTab("settings")

    }, [state.selectedLayer])

    // Tab initialization/Update
    useEffect(() => {

        !!state.selectedLayerAnalytics?.length
            ? setAvailableTabs(["settings", "filtering", "analytics", "image preview"])
            : setAvailableTabs(["settings", "filtering", "analytics", "image preview"])

    }, [state.selectedLayerAnalytics])

    useEffect(() => {
        const container = document.querySelector(".rendered-ribbon-content")
        if (!!container) {
            const bounds = container.getBoundingClientRect();
            container.style.height = Math.floor(((window.innerHeight - bounds.top) / window.innerHeight) * 100) + "vh"
        }
    }, [size, tab])

    if (!state.selectedLayer) {
        // return <Center><h2 style={{ color: "var(--neutral8)" }}>No Layer Selected</h2></Center>
        return (
            <GlobalMapSettings />
        )
    }

    if (!getters.getRenderedSelectedLayer()) {
        return (
            <Center>
                <div>
                    <h2 style={{ color: "var(--neutral8)" }}>Unable to fetch layer information</h2>
                    <em style={{ color: "var(--neutral8)" }}>If this is a base layer, it must be rendered to manipulate</em>
                    <br />
                    <em style={{ color: "var(--neutral8)" }}>Terrain layers have no edit features</em>
                </div>
            </Center>
        )
    }



    return (
        <div id="explorer-ribbon" className="explorer-grid-area pt-1" style={{ gridArea: "ribbon" }}>

            <button className='btn text-xs bg-white' onClick={() => setters.setSelectedLayer(null)}>Global Settings</button>
            <h3 className="text-lg inline cursor-pointer text-neutral10 px-1" title={"Refresh count"} onClick={handleLayerNameClick}>
                {state.selectedLayer.name} <sub title="Number of Images Found">({formatNumberString(state.selectedLayerAnalytics?.length ?? state.numFeatures) ?? "?"})</sub>
            </h3>
            <TabGroup
                tabs={availableTabs}
                defaultTab={tab}
                forceTab={forceTab}
                containerStyle={{
                    width: "fit-content",
                    display: "inline-block",
                    border: "none",
                    boxShadow: "2px 2px 5px var(--primaryAccent-9)",
                    borderRadius: "0"
                }}
                onClick={handleTabChange}
                tabStyle={{ color: "var(--neutral8)" }}
                selectedStyle={{ color: "var(--neutral-3)", backgroundColor: "var(--primaryAccent2)" }}
            />

            <div className="ribbon-section" height={contentHeight}>


                {
                    tab === "settings"
                    &&
                    <LayerSettings ribbonContentHeight={ribbonContentHeight} />
                }

                {
                    tab === "filtering"
                    &&
                    <LayerFilter ribbonContentHeight={ribbonContentHeight} />
                }

                {
                    tab === "analytics"
                    &&
                    <LayerAnalytics ribbonContentHeight={ribbonContentHeight} />
                }

                {
                    tab === "image preview"
                    &&
                    <LayerImagePreview ribbonContentHeight={ribbonContentHeight} />
                }
            </div>
        </div>
    )
}
