import React, { useEffect, useState } from 'react'

// ============================ CHILDREN ============================
import IconButton from '../dzyne_components/ui/buttons/Iconbutton/IconButton'


// ============================ ASSETS ============================
import TrashIcon from '../dzyne_components/assets/media/trash.svg';
import FilterIcon from '../../assets/icons/filter_alt.svg';
import ClockIcon from '../../assets/icons/clock.svg';
import FindLayer from '../../assets/icons/travel_explore.svg';
const Cesium = require("cesium");

export default function SidebarLayer({
    page,
    layer,
    viewer,
    toggleLayer,
    setActiveBaseLayer,
    setActiveTerrainLayer,
    deleteLayer,
    deleteTerrainLayer,
    selectedLayer,
    setSelectedLayer,
    geoserverAPI,
    toggleDateFilter
}) {


    // STATE
    const [layerZoomRect, setLayerZoomRect] = useState(null);

    // EVENTS
    const handleCheckboxChange = e => {
        switch(layer.layerType){
            case "base":
                setActiveBaseLayer(layer.id)
                break;
            case "terrain":
                setActiveTerrainLayer(layer.id)
                return;
            default:
                toggleLayer(layer.id)
        }
    }

    const handleLayerClick = e => {
        switch(layer.layerType){
            case "terrain":
                return;
            default:
                if(!!selectedLayer && selectedLayer.id === layer?.id) {
                    setSelectedLayer(null)
                } else {
                    setSelectedLayer(layer)                
                }
        }
    }

    const handleDeleteClick = () => {
        if(layer.layerType === "terrain"){
            deleteTerrainLayer(layer.id)
        } else {
            deleteLayer(layer.id)
        }
    }

    const handleFindLayerClick = () => {
        viewer?.camera?.flyTo({
            destination: layerZoomRect,
            duration: 0.75
        }) 
    }

    const handleDateFilterIconClick = () => {
        toggleDateFilter(layer)
    }


    // EFFECTS
    // Determine get layer coverage if applicable
    useEffect(() => {
        const exec = async () => {
            if(layer?.type === "dzyne_server"){
                const layerCoverage = await geoserverAPI.getLayerCoverage(layer)
                if(!!layerCoverage) {
                    const {minx, miny, maxx, maxy}  = layerCoverage.coverage?.latLonBoundingBox ?? {};
                    if (!!minx || !!miny || !!maxx || !!maxy){
                        const rect = Cesium.Rectangle.fromDegrees(minx, miny, maxx, maxy);
                        setLayerZoomRect(rect);
                    }
                }
            }
        }
        exec();
    }, [layer])

    return (
        <div className="flex items-center" >

            <IconButton icon={TrashIcon} alt="delete" onClick={handleDeleteClick} />
            <input id={layer.id} className="layer-checkbox" type="checkbox" checked={layer.isActive} onChange={handleCheckboxChange} />
            <h4 
                className={`sidebar-layer-title selected-${selectedLayer?.id === layer.id} `} 
                onClick={handleLayerClick}
                style={layer.layerType === "terrain" ? {cursor: "not-allowed"} : {}}
                title={layer.layerType === "terrain" ? "Terrain layers cannot be selected or configured" : "Select to configure"}
            >
                {layer.name}
            </h4>
            {
                layer?.mapSettings?.CQL_FILTER 
                && 
                <img 
                    className="transform scale-75"
                    title="Layer has filters applied" 
                    src={FilterIcon} 
                    alt="layer filtered" 
                />
            }
            {
                (page === "data-preparation" && layer.layerType === "feature")
                &&
                <img
                    title="Layer has date range filter applied"
                    src={ClockIcon}
                    alt="date filtered"
                    style={{ opacity: layer._applyDateRange ? "1" : "0.3", cursor: "pointer", transform: "scale(.75)" }}
                    onClick={handleDateFilterIconClick}
                />
            }
            {
                !!layerZoomRect
                &&
                <img 
                    src={FindLayer}        
                    alt="find layer"
                    style={{cursor: "pointer", transform: "scale(0.75)"}}
                    onClick={handleFindLayerClick}
                />
            }

        </div>
    )
}
