import React, { useContext, useEffect, useState } from 'react'

// ================================ STATE ================================
import { ExplorerContext, DataPrepContext } from '../../state/explorer/explorerProvider'

// ================================ HOOKS ================================
import { usePage } from '../../js/hooks';


// ================================ CHILDREN ================================
import Center from '../dzyne_components/layout/Center/Center';
import IconButton from '../dzyne_components/ui/buttons/Iconbutton/IconButton'
import Modal from '../dzyne_components/ui/modals/Modal/Modal'
import HintModal from '../dzyne_components/ui/modals/HintModal/HintModal'
import ImageAnnotator from './dataPrep/ImageAnnotator'

// ================================ ASSETS ================================
import Edit from '../dzyne_components/assets/media/edit.svg'
import CheckPrimary from '../../assets/icons/check_primary.svg'
import CheckBlack from '../dzyne_components/assets/media/check_black.svg'
import Anchor from '../../assets/icons/anchor_black.svg'
import copyToClipboard from '../../js/copyToClipboard';
import ThumbnailInterface from './ThumbnailInterface';
import useWindowResize from '../dzyne_components/hooks/useWindowResize';
import QuestionMarker from '../../assets/icons/question_marker.svg'
import { formatDate } from '../../js/sessionHelpers';
import { zoomToBbox } from '../../js/mapHelpers';
import { downloadJSON, imageLayerIsAdded } from '../../js/generalHelpers';
import { normalizeColor } from '../dzyne_components/map/3d/AnnotationManager3D/helpers';
import { geojsonPolygon2WKT } from '../dzyne_components/map/utils';

const Cesium = require('cesium');


// ================================ CONSTANTS ================================
const ANNOTATION_CLASSES = [
    { annotationTypes: ["polygon", "rectangle"], name: 'Forest, Deciduous', metadata: { code: 'EC015' }, color: '#00E800' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Forest, Evergreen', metadata: { code: 'EC015' }, color: '#008200' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Shrub/Scrub', metadata: { code: 'EB070' }, color: '#99F999' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Grassland', metadata: { code: 'EB010' }, color: '#FFFF99' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Barren/Minimal Vegetation', metadata: { code: 'DA010' }, color: '#D1D1D1' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Urban/Built-Up', metadata: { code: 'AL020' }, color: '#FF0000' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Agriculture, General', metadata: { code: 'EA010' }, color: '#D1AF8C' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Agriculture, Paddy', metadata: { code: 'BH135' }, color: '#9E4F28' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Wetland, Permanent/Herbaceous', metadata: { code: 'ED010' }, color: '#C65BED' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Wetland, Mangrove', metadata: { code: 'ED020' }, color: '#FFBFC6' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Water', metadata: { code: 'BH082' }, color: '#00A0FF' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Permanent or Nearly Permanent Ice and/or Snow', metadata: { code: 'BJ100' }, color: '#00FFFF' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Reefs', metadata: { code: 'BD120' }, color: '#BCFFE8' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Sand dunes', metadata: { code: 'DB170' }, color: '#CC9B35' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Sabkha', metadata: { code: 'BH160' }, color: '#FC03CB' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Ocean < 10m depth', metadata: { code: 'BA040' }, color: '#40E0D0' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Ocean > 10m depth', metadata: { code: 'BA040' }, color: '#00A0FF' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Lakes', metadata: { code: 'BH082' }, color: '#0060FF' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Rivers', metadata: { code: 'BH140' }, color: '#0000FF' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Beaches', metadata: { code: 'DA010' }, color: '#FFA500' },
    { annotationTypes: ["polygon", "rectangle"], name: 'Non-herbaceous Alluvial Deposits/Fans', metadata: { code: 'DA010' }, color: '#A52A2A' },
    { annotationTypes: ["polygon", "rectangle"], name: 'NULL', color: '#000000' }
]

export default function LayerImagePreview() {

    // STATE
    const page = usePage()
    const { state, setters, getters, geoserverAPI, dataPrepAPI } = useContext(page === "explorer" ? ExplorerContext : DataPrepContext);
    const [annotationImage, setAnnotationImage] = useState(null)
    const [paginatedFeatures, setPaginatedFeatures] = useState([])
    const [imagePage, setImagePage] = useState(0)
    const size = useWindowResize({ scroll: false })
    // EVENTS
    const handleImageClick = (feature, layer) => () => {

        const layerName = `${layer.name}:${feature.properties.catid}`
        const existingLayer = state.layers.find(l => l.name === layerName)

        // if layer already exists, just move it to the top, else add as a new layer
        if (existingLayer) {
            setters.adjustRenderedLayerLevel(existingLayer.id, "top")
        } else {
            const newLayer = {
                name: layerName,
                type: layer.type,
                ip: layer.ip,
                port: layer.port,
                layerType: "imagery",
                layerLocation: `images:${feature.properties.catid}`,
                mapSettings: {},
            }

            setters.appendLayer(newLayer)
        }
    }

    const copyImageCatID = feature => e => {
        copyToClipboard(feature.properties?.catid)
    }

    const handleImageLoad = feature => async e => {
        e.target.style.opacity = 1;
        // if (feature.hasValidThumbnail === true){
        //     e.target.style.opacity = 1;
        //     return
        // }
        // // check if the just loaded thumbnail is valid
        // const isValidThumbnail = await dataPrepAPI.isValidImageSrc(feature.thumbnailURL);
        // // const isValidThumbnail = await geoserverAPI.isValidImageSrc(feature.thumbnailURL);
        // // mark the image in question as valid or not. if it's not valid, it will be removed from the dom
        // setters.markImageValidity(feature, !!isValidThumbnail)
    }

    const handleImageError = feature => async () => {
        // if there was an error, just mark the image as invalid
        setters.markImageValidity(feature, false)
    }

    const handleSelectAllClick = bool => () => {
        for (let feature of state.selectedLayerAnalytics) {
            bool
                ? setters.toggleImage(feature.properties.catid, { force: "on" })
                : setters.toggleImage(feature.properties.catid, { force: "off" })
        }
    }

    const handleImagePagination = direction => () => {
        setImagePage(imagePage => {
            imagePage = direction > 0 ? imagePage + 1 : imagePage - 1
            if (imagePage < 0) return 0;
            if (imagePage > paginatedFeatures.length - 1) return paginatedFeatures.length - 1
            return imagePage;
        })
    }

    const handleImagePaginationInput = e => {
        let value = parseInt(e.target.value);
        if (value < 1) value = 1;
        if (value > paginatedFeatures.length) value = paginatedFeatures.length;
        setImagePage(value - 1)
    }

    const toggleAnchorImage = feature => e => {
        e.stopPropagation();
        const existingEntities = state.viewer.entities._entities._array;
        console.log(feature)
        // if we've already added the entity to the viewer, remove it and then do nothing
        for (let entity of existingEntities) {
            if (/^anchor\-/.test(entity._id)) {
                state.viewer.entities.remove(entity)
                if (entity._id === `anchor-${feature.id}`) {
                    setters.setSessionAnchorImage(null)
                    return
                };
                break;
            }
        }
        const points = feature?.geometry?.coordinates[0]?.flat()?.slice(0, -2)
        if (!!points) {
            const bounds = geojsonPolygon2WKT(feature?.geometry?.coordinates[0])
            setters.setSessionAnchorImage(feature.properties.catid, bounds)
            state.viewer.entities.add({
                id: `anchor-${feature.id}`,
                polygon: {
                    hierarchy: Cesium.Cartesian3.fromDegreesArray(points),
                    fill: null,
                    outline: true,
                    material: new Cesium.Color(...normalizeColor(0, 1, 0, 0.8)),
                    outlineWidth: 6,
                    // outlineColor: Cesium.Color({red: 33/255, green: 175/255, blue: 222/255, alpha: 1})
                    outlineColor: new Cesium.Color(...normalizeColor(33, 175, 222, 1))
                }
            })
        }
    }

    const toggleImageInSession = imageID => e => {
        e.stopPropagation();
        setters.toggleImage(imageID)
    }

    const handleAnnotateClick = feature => e => {
        e.stopPropagation();
        const dimensions = feature.properties?.dimensions.match(/\d+/g)
        setAnnotationImage({ ...feature, dimensions })
    }



    // RENDERERS
    const renderAnalyticsThumbnails = (analyticFeatures) => {
        // filter based on image validity: If an image loads and is found to be invalid, then we remove it from the dom by marking it as invalid. It then never gets past this filter and doesn't appear
        return analyticFeatures
            ?.sort((a, b) => new Date(a.properties.timestamp).getTime() - new Date(b.properties.timestamp).getTime())
            // ?.filter(feature => !!feature.hasValidThumbnail)
            ?.map((feature, i) => {
                return (

                    <div
                        key={feature.id}
                        className={`overflow-hidden group thumbnail-mask ${imageLayerIsAdded(state.layers, feature.properties?.catid) ? "rendered" : ""}`}
                        onClick={handleImageClick(feature, state.selectedLayer)}
                    >
                        <img
                            src={feature.thumbnailURL}
                            alt={feature.properties?.catid}
                            className="thumbnail-preview w-32 h-32 object-cover transform group-hover:scale-125 duration-300"
                            title={feature.properties?.catid}
                            width={128}
                            height={128}
                            data-dimensions={feature.properties?.dimensions.match(/\d+/g)}
                            data-rawURL={feature.rawURL}
                            onLoad={handleImageLoad(feature)}
                            onError={handleImageError(feature)}
                            style={{ opacity: 0 }}
                        />

                        <div className="thumbnail-button-group">
                            {
                                (page === "data-preparation" && !!Object.keys(state.selectedSession ?? {}).length)
                                &&
                                <>

                                    <IconButton
                                        className="bg-neutral8 opacity-75 backdrop-blur-sm"
                                        icon={Anchor}
                                        alt={"Reference Image Status"}
                                        onClick={toggleAnchorImage(feature)}
                                        containerStyle={{ backgroundColor: state.selectedSession?.session_data?.anchorImage?.catid === feature.properties.catid ? "var(--primaryAccent0)" : "" }}
                                    />

                                    <IconButton
                                        className="bg-neutral8 opacity-75 backdrop-blur-sm"
                                        icon={CheckBlack}
                                        alt={state.selectedSession?.images?.includes(feature.properties.catid) ? "Remove image from selection" : "Select image"}
                                        onClick={toggleImageInSession(feature.properties.catid)}
                                        containerStyle={{
                                            backgroundColor: state.selectedSession?.images?.includes(feature.properties.catid) ? "var(--secondaryAccent0)" : ""
                                        }}
                                    />

                                    <IconButton
                                        className="background-blur text-neutral0 bg-neutral8"
                                        icon={Edit}
                                        alt={"Annotate"}
                                        onClick={handleAnnotateClick(feature)}
                                    />
                                </>
                            }
                            <HintModal
                                wrapperClassName="thumbnail-question-container"
                                content={<ThumbnailInterface viewer={state.viewer} feature={feature} />}
                                positioning="top-right"
                                hoverTimer={100}
                                closeButton
                            >
                                <IconButton
                                    className="bg-neutral8 opacity-75 backdrop-blur-sm text-neutral0"
                                    icon={QuestionMarker}
                                    alt={"More Options"}
                                    // onClick={copyImageCatID(feature)}
                                    onClick={() => zoomToBbox(state.viewer, feature?.properties?.bbox)}
                                />
                            </HintModal>
                        </div>

                        <div className={`absolute inset-x-0 text-gray-900 text-center font-bold bg-gray-500 bg-opacity-50 backdrop-blur-sm  ${imageLayerIsAdded(state.layers, feature.properties?.catid) ? "bottom-2 leading-tight" : "bottom-0"}`}>
                            {formatDate(feature?.properties?.timestamp)}
                            <br />
                            {formatDate(feature?.properties?.timestamp, { format: "hh:mm" })}
                        </div>
                        {imageLayerIsAdded(state.layers, feature.properties?.catid) && (
                            <div className='absolute block bottom-0 w-full h-2 bg-primary0'></div>
                        )}
                    </div >
                )
            })
    }

    // ON LOAD
    useEffect(() => {

        const imageContainer = document.querySelector("#image-display-container")
        const containerWidth = imageContainer?.getBoundingClientRect()?.width;

        let numFeatures = 5 // default value if container width not given
        if (containerWidth) {
            numFeatures = Math.floor(containerWidth / 136) || 5
        }


        if (state.selectedLayerAnalytics) {
            const images = state.selectedLayerAnalytics//.filter(feat => !!feat.hasValidThumbnail)
            const chunked = []
            for (let i = 0; i < images.length; i += numFeatures) {
                chunked.push(images.slice(i, i + numFeatures))
            }
            setPaginatedFeatures(chunked)
        }

    }, [state.selectedLayerAnalytics, size])


    if (!state.selectedLayerAnalytics?.length) {
        return (
            <div className="layer-image-preview ribbon-section">
                <Center>
                    <div style={{ textAlign: "center" }}>
                        <h3>
                            No Image Content Found
                        </h3>
                        <br />
                        <p><em>Click on the map to select a region to query for imagery data</em></p>
                    </div>
                </Center>
            </div>
        )
    }

    return (
        <>
            {
                annotationImage
                &&
                <ImageAnnotator
                    {...{ annotationImage, setAnnotationImage, annotationClasses: ANNOTATION_CLASSES }}
                />
            }
            {page === "data-preparation" && <button onClick={handleSelectAllClick(true)}>Select All</button>}
            {page === "data-preparation" && <button onClick={handleSelectAllClick(false)}>Remove All</button>}

            <button onClick={handleImagePagination(-1)} style={{ margin: "0 0.5rem" }}>{"<"}</button>
            Page <input type="number" min="1" max={paginatedFeatures.length} value={imagePage + 1} onChange={handleImagePaginationInput} /> of {paginatedFeatures.length}
            <button onClick={handleImagePagination(1)} style={{ margin: "0 0.5rem" }}>{">"}</button>

            <div id="image-display-container" className="layer-image-preview ribbon-section">
                {
                    (!!paginatedFeatures.length) && renderAnalyticsThumbnails(paginatedFeatures[imagePage])
                }
            </div>
        </>
    )
}