import React, { useState, useContext } from 'react'

import { DataPrepContext, ExplorerContext } from '../../state/explorer/explorerProvider'

// ========================== DZYNE COMPONENTS ==========================
import TabGroup from '../dzyne_components/ui/buttons/TabGroup/TabGroup';
import { usePage } from '../../js/hooks';

// ========================== CHILDREN ==========================
import DetectionSelection from './global_panels/DetectionSelection';
import DefaultLocation from './global_panels/DefaultLocation';
import Mensuration from './global_panels/Mensuration';
import RegionAnnotator from './global_panels/RegionAnnotator';


export default function GlobalMapSettings() {
    const page = usePage();
    const { state, setters } = useContext(page === "explorer" ? ExplorerContext : DataPrepContext);
    const [drawingTab, setDrawingTab] = useState(page === "data-preparation" ? "region" : "measurements")

    return (
        <div id="explorer-ribbon" className="ribbon-section grid grid-cols-12 gap-2 overflow-hidden">
            {(page === "data-preparation" && !!state.selectedSession) && <DetectionSelection className={"col-span-4"} />}
            <DefaultLocation className={`col-span-${page==="data-preparation" && !!state.selectedSession ? 4 : 6}`} />
            <div className={`ribbon-subsection col-span-${page==="data-preparation" && !!state.selectedSession ? 4 : 6}`}>
                <TabGroup
                    tabs={page === "data-preparation" ? ["region", "measurements"] : ["measurements"]}
                    forceTab={drawingTab}
                    onClick={t => setDrawingTab(t)}
                />                
                {drawingTab === "region" && <RegionAnnotator />}
                {drawingTab === "measurements" && <Mensuration />}
            </div>
        </div>
    )
}


