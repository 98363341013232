import { select } from 'd3-selection';
import { v4 as uuidv4 } from 'uuid';
import { compareSessions } from '../../js/sessionHelpers';
const Cesium = require("cesium");

const setters = {
    toggleShowRibbon(){
        this.setState(prevState => {
            return ({showRibbon: !prevState.showRibbon})
        })
    },

    // OVERRIDES
    setSelectedLayer(layer) {
        const layerDidChange = layer !== this.state.selectedLayer
        this.setState({ selectedLayer: layer }, () => {
            // when a layer is selected, we immediately check if we should poll for the viewer geometry
            layer && this.methods.pollViewGeometry(layer.id)
            layer && this.geoserverAPI.countFeatures(layer)
            if (layer && layerDidChange) this.geoserverAPI.getLayerAnalytics(layer)
        })
    },

    // LAYER DEFINITIONS

    async setLayers(layers) {
        return await this.setState({ layers }, state => {
            if (!!state.selectedSession) this.methods.applyDataPrepDateRange();
        }, state => {
            if (window.location.pathname === "/explorer") window._layers = state.layers;
        })
    },

    appendLayer(layer) {
        const layerID = uuidv4()
        return new Promise(resolve => {

            this.setState(prevState => {
                let layers = [...prevState.layers]

                layer.id = layerID;
                layer.isActive = layer.isActive ?? layer.layerType !== "base" // set feature & imagery layers to true, but not base layers by default

                // push to the beginning because we want it to be at the top of the list to indicate it is the top most layer on the map
                layers.unshift(layer)

                return { layers }
            }, state => {
                this.setters.syncSessionData?.()
                if (window.location.pathname === "/explorer") window._layers = state.layers;
                resolve({ success: 1, layer: state.layers.find(l => l.id === layerID) })
            })

        })
    },

    addLayersFromSharingLink(sharedLayers) {
        return new Promise(resolve => {
            this.setState(prevState => {
                let layers = JSON.parse(JSON.stringify(prevState.layers));

                const existingIDs = layers.map(l => l.id);

                sharedLayers.forEach(sl => {
                    if (existingIDs.includes(sl.id)) {
                        layers = layers.map(l => l.id === sl.id ? sl : l)
                    } else {
                        layers.push(sl)
                    }
                })


                return { layers };
            }, (state) => {
                if (window.location.pathname === "/explorer") window._layers = state.layers;
                resolve(state.layers)
            })
        })
    },

    toggleLayer(layerID, force = null) {

        this.setState(prevState => {
            const renderedLayers = { ...prevState.renderedLayers }
            let layers = [...prevState.layers]

            // adjust the isActive flag applied to the layer in question
            layers = layers.map(layer => {
                if (layer.id === layerID) {
                    if (force === "active") layer.isActive = true
                    if (force === "inactive") layer.isActive = false
                    if (!force) layer.isActive = !layer.isActive
                    // layer.isActive = !!force
                    //     ? (force === "active") // if force == "active" sets isActive to true, else false
                    //     : !layer.isActive
                }
                return layer
            })

            // add or remove the layer from the map
            const layer = layers.find(l => l.id === layerID);
            if (layer && renderedLayers[layerID]) {
                layer.isActive
                    ? this.state.viewer.imageryLayers.add(renderedLayers[layerID])
                    : this.state.viewer.imageryLayers.remove(renderedLayers[layerID], false)
            }

            return { layers }

        }, state => {
            if (window.location.pathname === "/explorer") window._layers = state.layers;
            this.setters.syncSessionData?.()
        })

    },

    toggleTerrainLayer(layerID, { force = null, viewer = null }) {
        this.setState(prevState => {
            viewer = viewer ?? prevState.viewer;
            let layers = [...prevState.layers];
            let currentLayer;

            layers = layers.map(layer => {
                if (layer.layerType === "terrain") {
                    if (layer.id === layerID) {
                        currentLayer = layer;
                        layer.isActive = force ?? !layer.isActive
                    } else {
                        layer.isActive = false;
                    }
                }
                return layer
            })


            if (currentLayer.isActive) {
                switch (currentLayer.terrainType) {
                    case "cesium":
                        viewer.terrainProvider = new Cesium.CesiumTerrainProvider({ url: currentLayer.url })
                        break;
                    case "google":
                        break;
                    case "vr":
                        break;
                }
            } else {
                // if terrain has been turned off, then set the terrain provider to a default (null) value
                viewer.terrainProvider = new Cesium.EllipsoidTerrainProvider()
            }

            return { layers, viewer }
        }, state => {
            if (window.location.pathname === "/explorer") window._layers = state.layers;
            this.setters.syncSessionData?.()
        })
    },

    deleteLayer(layerID) {

        this.setState(prevState => {

            // remove the layer from the layers record
            const layers = [...prevState.layers].filter(l => l.id !== layerID)

            // remove the layer on the map and delete it from the renderedLayers record
            let renderedLayers = { ...prevState.renderedLayers };

            prevState.viewer.imageryLayers.remove(renderedLayers[layerID], true)
            delete renderedLayers[layerID]

            return {
                layers,
                renderedLayers,
                selectedLayer: prevState.selectedLayer?.id === layerID
                    ? null
                    : prevState.selectedLayer
            }

        }, state => {
            if (window.location.pathname === "/explorer") window._layers = state.layers;
            this.setters.syncSessionData?.()
        })

    },

    deleteTerrainLayer(layerID) {
        this.setState(prevState => {

            let layers = [...prevState.layers];
            // get the current layer before removing it from the layers
            const currentLayer = layers.find(l => l.id === layerID)
            layers = layers.filter(l => l.id !== layerID);

            const viewer = prevState.viewer;

            if (currentLayer?.isActive) {
                // if the current layer was the active terrain layer, set the viewers terrain layer to the default
                viewer.terrainProvider = new Cesium.EllipsoidTerrainProvider(); // this is the default value
            }
            return { layers, viewer }

        }, state => {
            if (window.location.pathname === "/explorer") window._layers = state.layers;
            this.setters.syncSessionData?.()
        })
    },

    async adjustLayerParameter(layerID, parameter, value) {

        return await this.setState(prevState => {

            let layers = [...prevState.layers]

            layers = layers.map(layer => {
                if (layer.id === layerID) {
                    layer[parameter] = value
                }
                return layer
            })

            return { layers }

        }, state => {
            if (window.location.pathname === "/explorer") window._layers = state.layers;
            this.setters.syncSessionData?.()
        })


    },

    async applyMapSettingToLayer(layerID, parameter, value, options = { remove: false, isSelectedLayer: true, recheckFeatures: true }) {

        return await this.setState(prevState => {
            let layers = [...prevState.layers]
            layers = layers.map(l => {
                if (l.id === layerID) {
                    if (options.remove) {
                        delete l.mapSettings?.[parameter]
                    } else {
                        if (!!l.mapSettings) l.mapSettings[parameter] = value
                    }
                }
                return l
            })

            let selectedLayer = null
            if (prevState.selectedLayer) {
                selectedLayer = layers.find(l => l.id === prevState.selectedLayer.id)
            }

            return options.isSelectedLayer ? { layers, selectedLayer } : { layers }

        }, state => {
            if (window.location.pathname === "/explorer") window._layers = state.layers;
            (state.selectedLayer && options.recheckFeatures) && this.geoserverAPI.countFeatures(state.selectedLayer)
            this.setters.syncSessionData?.()
        })
    },


    // RENDERED LAYERS

    async clearMap() {

        return await this.setState(prevState => {
            for (let [layerID, renderedLayer] of Object.entries(prevState.renderedLayers)) {
                prevState.viewer.imageryLayers.remove(renderedLayer, true)
                delete prevState.renderedLayers[layerID]
            }
            return { renderedLayers: prevState.renderedLayers, layers: [], selectedLayer: null }
        })
    },

    appendRenderedLayer(layerID, renderingObject) {
        this.setState(prevState => {

            let renderedLayers = { ...prevState.renderedLayers };
            const currentLayer = prevState.layers.find(l => l.id === layerID)
            // if(currentLayer.layerType === "base") return prevState

            currentLayer.layerLocation && this.geoserverAPI.getLayerInfo(currentLayer)

            // handle all other layer cases
            // if the layer has not already rendered
            if (!renderedLayers[layerID] && prevState.viewer /* && currentLayer.layerType !== "base" */) {
                // render the layer to the map viewer and retrieve it's reference
                let imageryLayer = prevState.viewer.imageryLayers.addImageryProvider(renderingObject)

                // imageryLayer = {...imageryLayer, ...currentLayer.mapSettings}
                renderedLayers[layerID] = imageryLayer


                if (!currentLayer?.isActive) {
                    // if the state initializes from local storage, and the current layer is not active (should not be rendered) remove it from the map
                    prevState.viewer.imageryLayers.remove(imageryLayer, false)
                }

            }

            return { renderedLayers }

        }, state => {
            // apply any relevant map settings to the rendered layer (e.g. alpha, brightness, etc.)
            const currentLayer = state.layers.find(l => l.id === layerID);
            for (let [param, value] of Object.entries(currentLayer.mapSettings ?? {})) {
                this.setters.adjustRenderedLayerParameter(layerID, param, value)
            }

        })
    },

    appendRenderedTerrain(layerID, renderingObject) {
        this.setState(prevState => {
            let layers = [...prevState.layers]

            layers = layers.map(layer => {
                if (layer.layerType === "terrain") {
                    layer.isActive = layer.id === layerID
                }
                return layer
            })


            prevState.viewer.terrainProvider = renderingObject;
            return { layers, viewer: prevState.viewer }

        })
    },

    registerRenderedLayer(layerID, layer) { // a more direct way of appending a rendered layer (assuming you've created the layer externally)
        this.setState(prevState => {
            let renderedLayers = { ...prevState.renderedLayers };
            renderedLayers[layerID] = layer;

            return { renderedLayers }
        })
    },

    unregisterRenderedLayer(layerID) {
        this.setState(prevState => {
            let renderedLayers = { ...prevState.renderedLayers };
            delete renderedLayers[layerID]
            return { renderedLayers }
        })
    },

    redrawRenderedLayer(layerID) {

        this.setState(prevState => {
            let renderedLayers = { ...prevState.renderedLayers };
            const currentLayer = prevState.layers.find(l => l.id === layerID);

            // when a layer redraw occurs, we need to refetch the data associated with that layer because the number of features may have changed
            this.geoserverAPI.getLayerAnalytics(currentLayer)

            // remove & destroy the current layer
            prevState.viewer.imageryLayers.remove(renderedLayers[layerID], true)

            // create a new layer object 
            const newLayer = this.methods.drawLayerOnMap(currentLayer.id)

            // add the layer object to the map
            const imageryLayer = currentLayer.layerType === "base"
                ? prevState.viewer.imageryLayers.addImageryProvider(newLayer, 0)
                : prevState.viewer.imageryLayers.addImageryProvider(newLayer)

            renderedLayers[layerID] = imageryLayer;

            return { renderedLayers }

        })
    },

    adjustRenderedLayerParameter(layerID, parameter, value) {
        this.setState(prevState => {
            const renderedLayers = { ...prevState.renderedLayers }
            renderedLayers[layerID][parameter] = value;

            return { renderedLayers }

        })
    },

    adjustRenderedLayerLevel(layerID, level) {

        this.setState(prevState => {

            let layers = [...prevState.layers]

            // retrieve the base layer
            const baseLayer = this.state.baseLayer || prevState.viewer.imageryLayers._layers[0]

            const layerIndex = layers.findIndex(l => l.id === layerID)

            switch (level) {
                case "up":
                    if (layerIndex > 0) {
                        [layers[layerIndex - 1], layers[layerIndex]] = [layers[layerIndex], layers[layerIndex - 1]]
                        try {
                            this.state.viewer.imageryLayers.raise(this.state.renderedLayers[layerID])
                        } catch {
                            break
                        }
                    }
                    break;
                case "down":
                    if (layerIndex < layers.length - 1) {
                        [layers[layerIndex + 1], layers[layerIndex]] = [layers[layerIndex], layers[layerIndex + 1]]
                        try {
                            this.state.viewer.imageryLayers.lower(this.state.renderedLayers[layerID])
                        } catch {
                            break
                        }
                    }
                    break;
                case "top":
                    if (layerIndex > 0) {
                        let capturedLayer = layers.splice(layerIndex, 1)[0]
                        layers.unshift(capturedLayer)
                        try {
                            this.state.viewer.imageryLayers.raiseToTop(this.state.renderedLayers[layerID])
                        } catch {
                            break
                        }
                    }
                    break;
                case "bottom":
                    if (layerIndex < layers.length - 1) {
                        let capturedLayer = layers.splice(layerIndex, 1)[0]
                        layers.push(capturedLayer)
                        try{
                            this.state.viewer.imageryLayers.lowerToBottom(this.state.renderedLayers[layerID])
                            this.state.viewer.imageryLayers.lowerToBottom(this.state.baseLayer)
                        } catch {
                            break
                        }
                    }
                    break;
            }

            // always send the base layer to the bottom
            this.state.viewer.imageryLayers.lowerToBottom(baseLayer)

            return { layers }
        })

    },


    updateLayerInfoBox(layerID, data) {
        this.setState(prevState => {

            const layerInfoBox = prevState.layerInfoBox ? { ...prevState.layerInfoBox } : { features: [] }

            for (let feature of data.features) {
                feature.layerID = layerID
            }

            layerInfoBox.features = [...layerInfoBox.features, ...data.features]
            
            return { layerInfoBox }
        })
    },

    markImageValidity(feature, isValid) {
        this.setState(prevState => {
            let selectedLayerAnalytics = [...prevState.selectedLayerAnalytics];
            selectedLayerAnalytics.forEach(f => {
                if (f.id === feature.id) f.hasValidThumbnail = isValid;
                return f
            })

            return { selectedLayerAnalytics };
        })
    },


    /* 
    ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    ::::::::::::::::::::::::::::::: DATA PREPARATION :::::::::::::::::::::::::::::::
    ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
    */

    explorerToDataPrepSession(explorerState) {
        this.setState({ ...explorerState })
    },
}

export default setters;


export const dataPrepSetters = {

    syncSessionData() {
        this.setState(prevState => {
            let selectedSession = prevState.selectedSession;

            if (selectedSession && !!Object.keys(selectedSession).length) {
                selectedSession = JSON.parse(JSON.stringify(selectedSession)) // copy the selected session
                selectedSession.session_data.layers = prevState.layers // assign the updated layer definitions

                // adjust the corresponding dpSession in the list of dpSessions
                const dpSessions = prevState.dpSessions.map(session => {
                    if (session.id === selectedSession.id) {
                        selectedSession.changesDetected = true;
                        return selectedSession
                    }
                    return session

                })

                return { selectedSession, dpSessions }
            } else {
                return {}
            }
        })
    },

    updateDataPrepSession(session) {
        this.setState(prevState => {
            let { selectedSession, dpSessions } = prevState;

            selectedSession = selectedSession?.id === session.id
                ? session
                : selectedSession

            dpSessions = dpSessions.map(s => {
                if (s.id === session.id) return session;
                return s;
            })

            return { selectedSession, dpSessions }
        }, () => {
            this.methods.applyDataPrepDateRange();            
            for (let layer of session.session_data.layers) {
                if (["feature", "imagery", "data"].includes(layer.layerType) && layer.isActive) {
                    this.setters.redrawRenderedLayer(layer.id)
                }
            }
        })
    },

    removeDataPrepSession(sessionID) {
        this.setState(prevState => {
            const dpSessions = [...prevState.dpSessions].filter(session => session.id !== sessionID)
            let selectedLayer = prevState.selectedLayer
            let selectedSession = prevState.selectedSession
            // reset selected layer and session to null if the removed session was selected (also then assuming that any selected layer was part of that session)
            if (selectedSession?.id === sessionID) {
                selectedLayer = null
                selectedSession = null
                this.setters.clearMap()
            }
            return { dpSessions, selectedLayer, selectedSession }
        })
    },

    appendDataPrepSession(session, options = { select: false }) {
        this.setState(prevState => {
            const dpSessions = [...prevState.dpSessions, session];
            let selectedSession = prevState.selectedSession
            if (options.select) {
                selectedSession = session
            }
            return { dpSessions, selectedSession }
        })
    },

    toggleImage(imageID, options = {}) {

        this.setState(prevState => {

            const selectedSession = { ...prevState.selectedSession };

            if (options.force === "on") {
                if (!selectedSession.images?.includes(imageID)) {
                    selectedSession.images?.push(imageID)
                }
                return { selectedSession }
            }

            if (options.force === "off") {
                selectedSession.images = selectedSession.images.filter(id => id !== imageID)
                return { selectedSession }
            }

            if (selectedSession.images?.includes(imageID)) {
                selectedSession.images = selectedSession.images.filter(id => id !== imageID)
            } else {
                selectedSession.images?.push(imageID)
            }


            return { selectedSession }
        }, () => {
            this.setters.syncSessionData()
        })
    },

    setSessionAnchorImage(imageID, bounds) {
        this.setState(prevState => {
            const selectedSession = { ...prevState.selectedSession };
            selectedSession.session_data = selectedSession.session_data ?? {};

            if (!imageID) {
                delete selectedSession.session_data["anchorImage"]
            } else {
                selectedSession.session_data.anchorImage = { catid: imageID, bounds }
            }

            return { selectedSession }
        }, () => this.setters.syncSessionData())
    },

    appendOrUpdateRegion(annotation, name) {
        this.setState(prevState => {
            const selectedSession = prevState.selectedSession;
            if (!selectedSession) return {}
            const data = selectedSession.session_data
            const regions = data.regions ?? []
            let region = regions.find(region => region.id === annotation._id)
            if (!region) {
                region = {}
                regions.push(region)
            }

            region.name = name
            region.id = annotation._id
            region.type = annotation.type
            region.points = annotation.current
            region.geometry = annotation.exportGeometry({output: "wkt"}).geometry

            data.regions = regions;
            return { selectedSession }
        }, () => this.setters.syncSessionData())
    },

    removeRegionFromSession(annotationID) {
        this.setState(prevState => {
            const selectedSession = prevState.selectedSession;
            if(!selectedSession) return {}
            const data = selectedSession.session_data
            if ("regions" in data){
                data.regions = data.regions.filter(region => region.id !== annotationID)
            }
            return {selectedSession}
        }, () => this.setters.syncSessionData())
    },

    appendAnnotationLayer(newLayer) {
        this.setState(prevState => {
            return { annotationLayers: [...prevState.annotationLayers, newLayer] }
        })
    },

    removeAnnotationLayer(annotationLayerID) {
        this.setState(prevState => {
            const annotationLayers = [...prevState.annotationLayers].filter(al => al.id !== annotationLayerID);
            return { annotationLayers };

        })
    },

    toggleDateFilter(layer, options = {}) {
        this.setState(prevState => {
            const layers = [...prevState.layers];
            for (let l of layers) {
                if (l.id === layer.id) {
                    l._applyDateRange = "_applyDateRange" in l
                        ? !l._applyDateRange
                        : true
                    if ("force" in options) {
                        l._applyDateRange = options.force;
                    }
                    break;
                }
            }
            return { layers }
        }, (state) => {
            // this.methods.applyDataPrepDateRange()
            this.setters.updateDataPrepSession(state.selectedSession)
        })
    }

}