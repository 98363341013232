import React, {useContext, useRef, useState, useEffect} from 'react'

// ===================== STATE =====================
import { ExplorerContext, DataPrepContext } from '../../state/explorer/explorerProvider';

// ===================== HOOKS =====================
import { usePage } from '../../js/hooks';


export default function FilterQueries() {
    
    const page = usePage()
    const {state, setters} = useContext(page === "explorer" ? ExplorerContext : DataPrepContext);

    const [filterText, setFilterText] = useState("")
    const filterRef = useRef(null)


    // EVENTS
    const handleChange = e => {
        const query = filterRef.current.value
        setFilterText(query);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        
        await setters.applyMapSettingToLayer(state.selectedLayer.id, "CQL_FILTER", filterText, {remove: !filterText}) // remove the parameter field if an empty string
        setters.redrawRenderedLayer(state.selectedLayer.id)

    }

    const handleReturnClick = e => {
        if(e.charCode === 13){
            if(!e.shiftKey){
                // setFilterText(prev => prev + "\n")
                e.preventDefault()
                e.target.parentElement.querySelector("input[type='submit']").click()
            }
        }
    }

    // ON LOAD
    useEffect(() => {
        setFilterText(state.selectedLayer?.mapSettings?.CQL_FILTER || "")
    }, [state.selectedLayer, state.selectedLayer?.mapSettings?.CQL_FILTER])

    return (
        <form className="flex flex-col h-full overflow-hidden ribbon-subsection" onSubmit={handleSubmit} onChange={handleChange}>
            <div className='flex justify-between'>
                <h3 className="text-lg">CQL Query</h3>
                <a className="text-blue-500 underline cursor-pointer mr-4" href="https://docs.geoserver.org/latest/en/user/filter/ecql_reference.html" target="_blank">help</a>
            </div>
            <textarea 
                ref={filterRef}
                id="layer-cql-query" 
                className="ribbon-section-textarea flex-auto" 
                value={filterText} 
                placeholder="Your query here..." 
                onKeyPress={handleReturnClick}
            ></textarea>
            <div>
                <input className="btn py-1 text-black float-right mr-4" type="submit" value="Apply" />
            </div>

        </form>
    )
}
