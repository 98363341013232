import { useContext, useEffect, useState, useRef, useLayoutEffect } from "react";

// =========================== STATE ===========================
import { UserContext } from "../../../state/users/userProvider";
import { DataPrepContext } from "../../../state/explorer/explorerProvider";

// =========================== DZYNE COMPONENTS ===========================
import InlineButton from "../../dzyne_components/ui/buttons/InlineButton/InlineButton";
import Modal from "../../dzyne_components/ui/modals/Modal/Modal";
import useWindowResize from "../../dzyne_components/hooks/useWindowResize";

// =========================== CHILDREN ===========================
import SessionListing from "./SessionListing";
import SessionForm from "./SessionForm";
import Center from "../../dzyne_components/layout/Center/Center";

// =========================== ICONS ===========================
import DropDown from '../../../assets/icons/arrow_drop_down.svg'

export default function DataPrepSessions() {
    // STATE
    const { state: userState } = useContext(UserContext);
    const {
        state: dpState,
        setters: dpSetters,
        dataPrepAPI,
    } = useContext(DataPrepContext);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [sessionFilter, setSessionFilter] = useState("");
    const [initialLoad, setInitialLoad] = useState(true);
    const [showSessionDropdown, setShowSessionDropdown] = useState(false);
    const size = useWindowResize({ scroll: false });
    const [listHeight, setListHeight] = useState(0);
    const [sessionListPosition, setSessionListPosition] = useState({});
    const dropdownToggleRef = useRef(null)

    // EVENTS
    const handleFilterSessionChange = (e) => {
        setSessionFilter(e.target.value);
    };

    const handleGrowSessionList = e => {
        console.log(e.currentTarget)
        e.currentTarget.style.height = "50vh";
    }

    // RENDERERS
    const renderSessions = (sessions) => {
        let re;
        try {
            re = new RegExp(sessionFilter, "ig");
        } catch (err) {
            re = new RegExp("", "ig");
        }

        sessions = sessions.filter((s) => !!s.name.match(re));
        return sessions.map((session) => {
            return (
                <SessionListing
                    session={session}
                    deleteDataPrepSession={dataPrepAPI.deleteDataPrepSession}
                    showSessionDropdown={showSessionDropdown}
                    setShowSessionDropdown={setShowSessionDropdown}
                />
            );
        });
    };

    // ON LOAD
    // fetch sessions when loaded
    useEffect(() => {
        dataPrepAPI.getDataPrepSessions(userState?.user?.user_id);
    }, []);

    // auto-select session based on query params
    useEffect(() => {
        if (initialLoad && !!dpState.dpSessions?.length) {
            const params = new URLSearchParams(window.location.search);
            const sessionName = params.get("session");
            const session = dpState.dpSessions.find(s => s.name === sessionName)
            if (!!session) {
                dpSetters.setSelectedSession(session)
                setInitialLoad(false)
            }
        }
    }, [dpState.dpSessions]);

    // handle session switching
    useEffect(() => {
        const updateSessionLayers = async () => {
            if (dpState.selectedSession) {
                await dpSetters.clearMap();
                if (dpState.selectedSession?.session_data?.layers) {
                    const sortedLayers = [
                        ...dpState.selectedSession?.session_data?.layers?.filter(
                            (l) => l.layerType !== "base"
                        ),
                        ...dpState.selectedSession?.session_data?.layers?.filter(
                            (l) => l.layerType === "base"
                        ),
                    ];
                    dpSetters.setLayers(sortedLayers);
                } else {
                    dpSetters.setLayers([]);
                }
            }
        };

        updateSessionLayers();
    }, [dpState.selectedSession?.id]);


    useLayoutEffect(() => {
        const toggleRect = dropdownToggleRef.current?.getBoundingClientRect?.();
        if (toggleRect) {
            setSessionListPosition({ left: toggleRect.right })
        }
    }, [size])

    useEffect(() => {
        if(!showSessionDropdown) setSessionFilter("");
    }, [showSessionDropdown])

    return (
        <>
            <section className="sidebar-section flex flex-col overflow-y-hidden">
                <h3 className="text-lg">
                    <button
                        className="text-xs 2xl:text-sm base btn bg-white text-neutral-1"
                        onClick={() => setShowCreateModal(true)}
                    >
                        Create New Session
                    </button>
                </h3>
                <div className="grid grid-cols-12">
                    <div ref={dropdownToggleRef} className="col-span-1 bg-white cursor-pointer text-black font-bold" onClick={() => { setShowSessionDropdown((val) => !val) }}>
                        <Center>
                            {
                                !showSessionDropdown
                                    ? <img src={DropDown} />
                                    : "X"
                            }
                        </Center>
                    </div>
                    <div className="col-span-11 relative">
                        {
                            (!!dpState.selectedSession && !showSessionDropdown)
                                ? (
                                    <SessionListing
                                        session={dpState.selectedSession}
                                        deleteDataPrepSession={dataPrepAPI.deleteDataPrepSession}
                                        showSessionDropdown={showSessionDropdown}
                                        setShowSessionDropdown={setShowCreateModal}
                                    />
                                )
                                : !showSessionDropdown
                                    ? (
                                        <div
                                            className="bg-white text-center font-bold text-black border-l-2 border-black cursor-pointer"
                                            onClick={() => { setShowSessionDropdown(true) }}
                                        >
                                            Select Session
                                        </div>
                                    )
                                    : (
                                        <div
                                            className="fixed h-[100px] w-[50vw] bg-gray-100 z-50"
                                            style={{ ...sessionListPosition}}
                                        >
                                            <input
                                                className="p-1 rounded-sm w-full text-black"
                                                type="text"
                                                placeholder="Search Sessions..."
                                                value={sessionFilter}
                                                onChange={handleFilterSessionChange}
                                                autoFocus
                                            />
                                            <div className="overflow-y-auto shadow-sm shadow-gray-100" style={{maxHeight: "50vh", boxShadow: "4px 4px 10px gray"}}>
                                                {renderSessions(dpState.dpSessions)}
                                            </div>
                                        </div>
                                    )
                        }
                    </div>
                </div>
            </section>
            {showCreateModal && (
                <Modal closeButton onClose={() => setShowCreateModal(false)}>
                    <SessionForm onClose={() => setShowCreateModal(false)} />
                </Modal>
            )}
        </>
    );
}
