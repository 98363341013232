import React, { useState, useEffect } from 'react'
import copyToClipboard from '../../js/copyToClipboard';
import ScrollContent from '../dzyne_components/map/3d/MapPopupWrapper3D/ScrollContent';

// ============================ CHILDREN ============================
import SelectionTable from '../dzyne_components/ui/tables/SelectionTable/SelectionTable';

export default function FeatureInfoBox({
    features,
    methods
}) {

    const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(0)
    const [selectedFeature, setSelectedFeature] = useState(features[0])
    const [selectedPolygon, setSelectedPolygon] = useState(null)

    // HELPERS
    const transformData = feature => {
        const properties = feature.properties
        const coordinates = feature?.geometry?.coordinates[0]?.map(point => point.toString().replace(",", " ")).join(", ")
        properties.geometry = coordinates ? `POLYGON((${coordinates}))` : null
        if (properties.bbox) {
            properties.bbox = properties?.bbox?.toString().replaceAll(",", ", ");
        }
        if (properties.data) {
            try {
                properties.data = JSON.parse(properties.data)
            } catch { }
        }

        let props = Object.entries(properties).map(([prop, val]) => {
            return val === null || val === undefined ? { prop, val: "--" } : { prop, val };
        });

        // move 'data' prop to the bottom
        const index = props.indexOf(props.find((x) => x.prop == "data"));
        props.push(props.splice(index, 1)[0]);
        return props;
    }

    const setAndRenderFeature = feature => {
        setSelectedFeature(feature)

        selectedPolygon && methods.removeEntity(selectedPolygon)
        const polygon = methods.renderPolygonFromFeatureInfo(feature);
        setSelectedPolygon(polygon)
    }

    const getSelectedPolygon = () => {
        return selectedPolygon
    }

    // EVENTS
    const handleSelectedFeatureChange = e => {
        const feature = features.find(f => f.id === e.target.value);
        console.log(feature)
        setSelectedFeature(feature)

        selectedPolygon && methods.removeEntity(selectedPolygon)

        const polygon = methods.renderPolygonFromFeatureInfo(feature)
        setSelectedPolygon(polygon)

    }

    const handleRowClick = data => {
        if(data.val === undefined || data.val === null) return;
        if(typeof data.val === "object") {
            copyToClipboard(JSON.stringify(data.val))
        } else {
            copyToClipboard(data.val || "")
        }
    }

    const handleZoomClick = () => {
        methods.fitToEntity(selectedPolygon)
    }

    const handleFeatureIndexChange = idx => e => {
        setSelectedFeatureIndex(idx);
    }

    const handleMoveIndex = direction => e => {
        if (direction === "increase") {
            features.length - 1 >= selectedFeatureIndex + 1
                &&
                setSelectedFeatureIndex(idx => idx + 1)
        }
        if (direction === "decrease") {
            0 <= selectedFeatureIndex - 1
                &&
                setSelectedFeatureIndex(idx => idx - 1)
        }
    }

    // RENDERERS
    const renderIndexSelection = (features, selectedFeatureIndex) => {
        return features.map((feature, i) => {
            i = parseInt(i)
            return (
                <div
                    key={feature.id}
                    className={`feature-info-selection-index feature-info-selection-index-selected-${i === selectedFeatureIndex} cursor-pointer`}
                    onClick={handleFeatureIndexChange(i)}
                    style={{ display: Math.abs(i - selectedFeatureIndex) <= 3 ? "inline-block" : "none" }}
                // onClick={handleFeatureIndexChange(i)}
                >
                    {i + 1}
                </div>
            )
        })
    }

    // ON LOAD
    useEffect(() => {

        setSelectedFeature(features[selectedFeatureIndex]);

        getSelectedPolygon() && methods.removeEntity(getSelectedPolygon())
        const polygon = methods.renderPolygonFromFeatureInfo(features[selectedFeatureIndex]);
        setSelectedPolygon(polygon)

    }, [features, selectedFeatureIndex])

    // remove the polygon when the info box is closed
    useEffect(() => {
        return () => {
            selectedPolygon && methods.removeEntity(selectedPolygon)
        }
    })

    // handle key press
    useEffect(() => {
        const handleKeypress = e => {
            switch (e.key) {
                case "ArrowRight":
                    document.querySelector("#increase-index")?.click()
                    break;
                case "ArrowLeft":
                    document.querySelector("#decrease-index")?.click()
            }
        }
        window.addEventListener("keydown", handleKeypress)

        return () => {
            window.removeEventListener("keydown", handleKeypress)
        }
    }, [])

    if (!selectedFeature) return <h4>No feature data available</h4>

    return (
        <>
            <div id="feature-info-box-controls" style={{ flex: "0 1 auto" }}>
                <button id="decrease-index" onClick={handleMoveIndex("decrease")}>{"<"}</button>
                {renderIndexSelection(features, selectedFeatureIndex)}
                <button id="increase-index" onClick={handleMoveIndex("increase")}>{">"}</button>
                <br />
                {!!selectedPolygon && <button onClick={handleZoomClick}>Zoom To</button>}
            </div>
            {
                selectedFeature
                &&
                <ScrollContent initialWidth="450px" initialHeight="500px" >
                    <SelectionTable
                        data={transformData(selectedFeature)}
                        columns={[
                            { name: "Property", key: "prop", type: "string" },
                            { name: "Value", key: "val", type: "string" },
                        ]}
                        onRowClick={handleRowClick}
                        containerStyle={{ width: "98%", margin: "0 auto" }}
                        tableStyle={{ width: "100%", display: "block", position: "absolute" }}
                    />
                </ScrollContent>
            }
        </>
    )
}
