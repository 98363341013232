import React, {useState, useEffect} from 'react'

export default function TabGroup({
    tabs=[],
    defaultTab=null,
    forceTab=null,
    onClick = (tab, event) => {},
    
    orientation="horizontal",

    id="",
    className = "w-fit shadow-md shadow-black",
    tabItemClassName = "inline-block p-2 cursor-pointer capitalize font-verdana",
    selectedClassName = "bg-primary2 font-bold text-neutral-3",
    unselectedClassName="",
    containerStyle={},
    selectedStyle={},
    tabStyle={}
}) {

    const [selectedTab, setSelectedTab] = useState(defaultTab || tabs[0]) 

    // EVENTS
    const handleClick = tab => e => {
        
        setSelectedTab(tab)
        
        onClick(tab, e)
    }

    useEffect(() => {
        forceTab && setSelectedTab(forceTab)
    }, [forceTab])

    // RENDERERS
    const renderTabs = tabs => {
        return tabs.map(tab => {
            return ( 
                <div 
                    key={tab}
                    className={`${selectedTab === tab} ${tabItemClassName} ${selectedTab === tab ? selectedClassName : unselectedClassName}`}
                    style={
                        Object.assign(
                            {}, 
                            orientation === "horizontal" ? {display: "inline-block"} : {display: "block"}, 
                            selectedTab === tab ? {...tabStyle, ...selectedStyle} : tabStyle
                        )
                    }
                    onClick={handleClick(tab)}
                >
                    {tab}
                </div>
            )
        })
    }
    return (
        <div id={id} className={`${className}`} style={containerStyle}>
            {renderTabs(tabs)}
        </div>
    )
}
