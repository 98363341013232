export const wkt2Array = (wkt, output="object") => {
    return wkt.match(/\-?\d+\.\d+\s\-?\d+\.\d+/gm)
        .map(p => p.split(" ").map(x => parseFloat(x)))
}

export const extractWKTString = (str) => {
    const coords = str.match(/\-?\d+\.?\d+\s\-?\d+\.?\d+,?/gi)
    if (!coords) return
    const type = str.match(/(POLYGON|LINESTRING|POINT)/gi)[0]
    if (coords){
        return `${type}((${coords.join(",")}))`
    }
    
}

export const geojsonPolygon2WKT = (coords) => {
    return `POLYGON((${coords.map(point => `${point[0]} ${point[1]}`).join(", ")}))`
}


export const minMaxCoords2WKTBounds = ({latMin, latMax, lngMin, lngMax}) => {
    return `POLYGON((${lngMin} ${latMin},${lngMin} ${latMax},${lngMax} ${latMax},${lngMax} ${latMin},${lngMin} ${latMin}))`
}

export const wktRect2Bbox = (wkt) => {
    const coords = wkt.match(/\-?\d+\.?\d+\s\-?\d+\.?\d+,?/gi)
    if (coords) {
        const parsed = coords.map(c => c.split(" ").map(v => parseFloat(v)))
        const lngs = parsed.map(c => c[0])
        const lats = parsed.map(c => c[1])

        const bbox = [
            Math.min(...lngs),
            Math.min(...lats),
            Math.max(...lngs),
            Math.max(...lats),
        ]
        
        return bbox
    }
}

