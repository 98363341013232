import { DateTime } from 'luxon'

export const formatDate = (isoDate, options={format: "yyyy-MM-dd"}) => {
    return DateTime.fromISO(isoDate).toFormat(options.format)
}

export const dateRangeToCQL = (start, end, layer) => {
    
    if(!layer.mapSettings) return null

    const currentCQL = layer?.mapSettings?.CQL_FILTER
    const dateFilter = `(timestamp >= ${start} AND timestamp <= ${end})`
    let updatedCQL
    if (currentCQL) {
        const SEARCH = /\(timestamp\s>=\s\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\sAND\stimestamp\s<=\s\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\)/gm
        if (!!currentCQL.match(SEARCH)) {
            updatedCQL = currentCQL.replace(SEARCH, dateFilter)
        } else {
            updatedCQL = currentCQL + `${currentCQL.length ? " AND " : ""}${dateFilter}`
        }
        return updatedCQL
    } else {
        return dateFilter;
    }
}

export const removeDateRangeFromCQL = (layer) => {
    const currentCQL = layer?.mapSettings?.CQL_FILTER
    let updatedCQL;

    if(currentCQL){
        const SEARCH = /((\s?AND\s)|(\s?OR\s))?\(timestamp\s>=\s\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\sAND\stimestamp\s<=\s\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\)/gmi
        if(!!currentCQL.match(SEARCH)){
            updatedCQL = currentCQL.replace(SEARCH, "")
        } else {
            updatedCQL = currentCQL
        }
    }
    return updatedCQL
}

export const compareSessions = (session1, session2) => {
    return JSON.stringify(session1) === JSON.stringify(session2)
}